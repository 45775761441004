import { ProjectSceneCreationDTO } from '@addsome/dtos/dist'
import { project as projectActions, scene as sceneActions } from '@addsome/redux-store'
import { ContentContainer, Heading, InvisibleButton, Loading } from '@addsome/ui-kit'
import { push } from 'connected-react-router'
import React, { useEffect, useState } from 'react'
import { WrappedComponentProps, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router-dom'
import { AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import closeIcon from '../../../assets/images/icons/close.png'
import newAmbianceLogo from '../../../assets/images/icons/compas.svg'
import NewAmbianceForm from '../../../components/Ambiances/New/NewAmbianceForm'
import TemplatePopup from '../../../components/Ambiances/TemplatePopup'
import Layout from '../../../components/Common/Layout'
import { IRootState } from '../../../redux'
import { createFileFromUrl, uploadMedia } from '../../../utils/media'
import styles from './NewAmbiance.module.scss'

interface IMatchParams {
  id: string
}

type IProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  WrappedComponentProps &
  RouteComponentProps<IMatchParams>

const NewAmbiance: React.FC<IProps> = ({
  intl,
  pushRouter,
  match,
  fetchProject,
  project,
  loading,
  createScene
}) => {
  const [displayLibrary, setDisplayLibrary] = useState(false)
  const [ambianceName, setAmbianceName] = useState('')
  const [ambianceDescription, setAmbianceDescription] = useState('')
  const [sceneLoading, setSceneLoading] = useState(false)

  useEffect(() => {
    fetchProject(match.params.id)
  }, [match.params.id])

  if (loading || !project) return null

  return (
    <Layout
      breadcrumbRoutes={[
        {
          breadcrumbName: intl.formatMessage({ id: 'breadcrumb.home' }),
          path: '/'
        },
        {
          breadcrumbName: project.name,
          path: `/projects/${project.id}/products`
        },
        {
          breadcrumbName: intl.formatMessage({ id: 'breadcrumb.newAmbiance' }),
          path: '/newambiance'
        }
      ]}
      title={project.name}
    >
      {!displayLibrary && (
        <>
          <div className={styles.close}>
            <InvisibleButton
              title={intl.formatMessage({ id: 'misc.close' })}
              onClick={() => pushRouter(`/projects/${project.id}/products`)}
            >
              <img src={closeIcon} className={styles.closeButton} />
            </InvisibleButton>
          </div>
          <div className={styles.content}>
            <img className={styles.newAmbianceLogo} src={newAmbianceLogo} />
            <Heading level={2} className={styles.title}>
              {intl.formatMessage({ id: 'ambiance.title' })}
            </Heading>
            <Heading level={2} className={styles.subtitle}>
              {intl.formatMessage({ id: 'ambiance.subtitle' })}
            </Heading>
            <NewAmbianceForm
              projectId={match.params.id}
              onClickValidate={(name, description) => {
                setAmbianceName(name)
                setAmbianceDescription(description)
                setDisplayLibrary(true)
              }}
            />
          </div>
        </>
      )}
      {displayLibrary && (
        <ContentContainer>
          <TemplatePopup
            projectId={project.id}
            onPictureSelected={picture => {
              if (picture) {
                createScene(project.id, {
                  projectId: project.id,
                  name: ambianceName,
                  description: ambianceDescription,
                  version: {
                    camera: null,
                    content: null,
                    products: []
                  },
                  pictureId: picture.id
                }, 
                intl.formatMessage({id: 'scenes.addedScene'}), intl.formatMessage({id: 'scenes.errorCreatingScene'}))
              }
            }}
            onTemplateSelected={async projectSceneTemplate => {
              if (projectSceneTemplate.picture) {
                setSceneLoading(true)
                const file = await createFileFromUrl(projectSceneTemplate.picture.url || '')
                const media = await uploadMedia(file)
                createScene(project.id, {
                  projectId: project.id,
                  name: ambianceName,
                  description: ambianceDescription,
                  version: {
                    camera: projectSceneTemplate.camera,
                    content: projectSceneTemplate.content,
                    products: []
                  },
                  pictureId: media.id
                },
                intl.formatMessage({id: 'scenes.addedScene'}), intl.formatMessage({id: 'scenes.errorCreatingScene'}))
              }
            }}
          />
        </ContentContainer>
      )}
      {sceneLoading && (
        <div className={styles.loading}>
          <Loading />
        </div>
      )}
    </Layout>
  )
}

const mapStateToProps = (state: IRootState) => ({
  project: state.projectState.value,
  loading: state.projectState.loading
})

const mapDispatchToProps = (dispatch: ThunkDispatch<IRootState, {}, AnyAction>) => ({
  pushRouter: (location: string) => dispatch(push(location)),
  fetchProject: (projectId: string) => dispatch(projectActions.thunks.fetchValue(projectId)),
  createScene: async (projectId: string, payload: ProjectSceneCreationDTO, addedScene?: string, errorCreatingScene?: string) => {
    const scene = await dispatch(sceneActions.createScene(projectId, payload, addedScene, errorCreatingScene))
    dispatch(push(`/projects/${projectId}/scene/${scene.id}`))
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(NewAmbiance))
