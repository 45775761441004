import { BrandProjectSceneTemplateDTO, BrandUserWithBrandsDTO, MediaDTO } from '@addsome/dtos'
import {
  brandProjectSceneTemplate,
  IPaginationRequest,
  project as projectAction
} from '@addsome/redux-store'
import { Button, Card, InvisibleButton, Size, Theme } from '@addsome/ui-kit'
import React, { useEffect } from 'react'
import { WrappedComponentProps, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import { IRootState } from '../../../redux'
import { getCloudImageUrl } from '../../../utils'
import { mediaUploadUrl } from '../../../utils/media'
import UploadMedia from '../../Common/UploadMedia'
import styles from './TemplatePopup.module.scss'

type IProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  WrappedComponentProps & {
    onPictureSelected: (picture: MediaDTO) => void
    onTemplateSelected: (template: BrandProjectSceneTemplateDTO) => void
    projectId: string
  }

const TemplatePopup: React.FC<IProps> = ({
  user,
  fetchBrandProjectSceneTemplates,
  templates,
  intl,
  onTemplateSelected,
  onPictureSelected,
  token
}) => {
  useEffect(() => {
    if (
      user &&
      (user as BrandUserWithBrandsDTO).brands.length > 0 &&
      (user as BrandUserWithBrandsDTO).brands[0].id
    ) {
      fetchBrandProjectSceneTemplates((user as BrandUserWithBrandsDTO).brands[0].id, intl.formatMessage({id:'brandProjectScene.errorRetrievingTemplates'}))
    }
  }, [fetchBrandProjectSceneTemplates, user])

  return (
    <div className={styles.container}>
      <div className={styles.uploadContainer}>
        <UploadMedia
          action={mediaUploadUrl}
          onUploaded={async picture => onPictureSelected(picture)}
          className={styles.upload}
          token={token}
          displayLoading={false}
        >
          <Button
            className={styles.addButton}
            uppercase
            icon="plus"
            shape="round"
            theme={Theme.PRIMARY}
            size={Size.MEDIUM}
          >
            {intl.formatMessage({ id: 'library.upload' })}
          </Button>
        </UploadMedia>
      </div>

      <div className={styles.grid}>
        {templates.map(projectSceneTemplate => (
          <div className={styles.item} key={projectSceneTemplate.id}>
            <InvisibleButton
              onClick={() => onTemplateSelected(projectSceneTemplate)}
              className={styles.card}
            >
              <Card
                hoverText="choisir"
                inner
                title={projectSceneTemplate.name}
                desc={projectSceneTemplate.description || ''}
                ratio={[3, 2]}
                image={
                  projectSceneTemplate.picture && projectSceneTemplate.picture.url
                    ? getCloudImageUrl(projectSceneTemplate.picture.url, 400)
                    : ''
                }
              />
            </InvisibleButton>
          </div>
        ))}
      </div>
    </div>
  )
}

const mapStateToProps = (state: IRootState) => ({
  project: state.projectState.value,
  templates: state.brandProjectSceneTemplateState.templates,
  user: state.userState.user,
  token: state.authState.token
})

const mapDispatchToProps = (dispatch: ThunkDispatch<IRootState, {}, AnyAction>) => ({
  fetchProject: (id: string) => dispatch(projectAction.thunks.fetchValue(id)),
  fetchBrandProjectSceneTemplates: (
    architectId: string,
    errorRetrievingTemplates?: string,
    request?: IPaginationRequest,
    concatenate?: boolean
  ) =>
    dispatch(
      brandProjectSceneTemplate.fetchBrandProjectSceneTemplates(architectId, errorRetrievingTemplates, request, concatenate)
    )
})

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(TemplatePopup))
