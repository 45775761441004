import { useRef, WheelEvent } from 'react'

export const useHorizontalScroll = <T extends HTMLDivElement>() => {
  const scrollContainerRef = useRef<T>(null)

  const handleWheel = (e: WheelEvent<T>) =>
    scrollContainerRef.current && scrollContainerRef.current.scrollBy(e.deltaY, 0)

  return { scrollContainerRef, handleWheel }
}
