import { BrandUserWithBrandsDTO, ProductLightExtendedDTO } from '@addsome/dtos'
import {
  IPaginationRequest,
  packshot as packshotActions,
  product as productActions,
  project as projectActions
} from '@addsome/redux-store'
import { IProductSetting, setProductsSettings } from '@addsome/redux-store/dist/store/packshot'
import { Button, ICardData, Size, Theme } from '@addsome/ui-kit'
import { push } from 'connected-react-router'
import React, { Component } from 'react'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router-dom'
import { AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import Filters from '../../../components/Common/Filters/Filters'
import Layout from '../../../components/Common/Layout'
import ProductGrid from '../../../components/Products/Grid'
import { IRootState } from '../../../redux'
import { DEFAULT_PRODUCTS_FILTERS } from '../../../utils/filters'

interface IMatchParams {
  id: string
}

type IProps = ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps> &
  RouteComponentProps<IMatchParams> &
  WrappedComponentProps

interface IState {
  filters: { [key: string]: string[] }
}

class NewVisual extends Component<IProps, IState> {
  public constructor(props: IProps) {
    super(props)

    this.state = {
      filters: {}
    }

    this.handleCardSelect = this.handleCardSelect.bind(this)
    this.handleUpdateFilters = this.handleUpdateFilters.bind(this)
    this.fetchProducts = this.fetchProducts.bind(this)
    this.handleFirstButtonClick = this.handleFirstButtonClick.bind(this)
  }

  public componentDidMount() {
    const {
      fetchProject,
      project,
      match: {
        params: { id }
      }
    } = this.props

    if (!project) {
      fetchProject(id)
    }

    setProductsSettings([])
  }

  public render() {
    const {
      intl,
      project,
      match: {
        params: { id }
      },
      user,
      isAdd
    } = this.props
    const { filters } = this.state

    const firstButton = (
      <Button
        uppercase
        style={{ width: '100%' }}
        size={Size.LARGE}
        theme={Theme.BLACK}
        onClick={this.handleFirstButtonClick}
      >
        {intl.formatMessage({
          id: isAdd ? 'visual.new.validate' : 'visual.new.batch'
        })}
      </Button>
    )

    const secondButton = (
      <Button
        uppercase
        size={Size.LARGE}
        style={{ width: '100%' }}
        theme={Theme.DEFAULT}
        onClick={this.handleSceneCreationClicked}
      >
        {intl.formatMessage({ id: 'visual.new.ambiance' })}
      </Button>
    )

    return (
      <Layout
        breadcrumbRoutes={[
          {
            breadcrumbName: intl.formatMessage({ id: 'breadcrumb.home' }),
            path: '/'
          },
          {
            breadcrumbName: project ? project.name : '',
            path: `/projects/${id}/products`
          },
          {
            breadcrumbName: intl.formatMessage({ id: 'visual.new.breadcrumbName' }),
            path: ''
          }
        ]}
      >
        <div>
          <Filters onUpdateFilters={this.handleUpdateFilters} />
          {user && (
            <ProductGrid
              title={intl.formatMessage({ id: 'visual.new.selectProducts' })}
              searchPlaceHolder={intl.formatMessage({ id: 'product.search' })}
              fetchItems={this.fetchProducts}
              filters={filters}
              firstButton={firstButton}
              secondButton={(!isAdd && secondButton) || <></>}
              onCardSelect={this.handleCardSelect}
            />
          )}
        </div>
      </Layout>
    )
  }

  private handleCardSelect(selectedProducts: ICardData[]) {
    this.selectProductsInStore(selectedProducts)
  }

  private handleUpdateFilters(filters: { [key: string]: string[] }) {
    this.setState({ filters })
  }

  private handleCancelClicked = () => {
    const {
      match: {
        params: { id }
      },
      pushRouter
    } = this.props

    pushRouter(`/projects/${id}/visuals/new/batch`)
  }

  private handleSceneCreationClicked = () => {
    const {
      match: {
        params: { id }
      },
      pushRouter,
      selectedProducts
    } = this.props

    setProductsSettings(
      selectedProducts.map(product => ({
        productId: product.id,
        variationIds: [],
        cameraIds: [],
        customCamera: null
      }))
    )

    pushRouter(`/projects/${id}/ambiance/new`)
  }

  private selectProductsInStore = (selectedCards: ICardData[]) => {
    const { setSelectedProducts, products, selectedProducts } = this.props
    const knownProducts = [...products, ...selectedProducts]

    const computedSelectedProducts = selectedCards.reduce(
      (list: ProductLightExtendedDTO[], selectedCard) => {
        const product = knownProducts.find(p => selectedCard.id === p.id)
        if (product) list.push(product)
        return list
      },
      []
    )
    setSelectedProducts(computedSelectedProducts)
  }

  private handleFirstButtonClick() {
    const {
      match: {
        params: { id }
      },
      pushRouter,
      selectedProducts,
      productsSettings,
      // Don't ask too much
      // tslint:disable-next-line: no-shadowed-variable
      setProductsSettings
    } = this.props

    setProductsSettings(
      selectedProducts.map(product => {
        const oldSetting = productsSettings.find(p => p.productId === product.id)

        return {
          productId: product.id,
          variationIds: (oldSetting && oldSetting.variationIds) || [],
          cameraIds: (oldSetting && oldSetting.cameraIds) || [],
          customCamera: (oldSetting && oldSetting.customCamera) || null
        }
      })
    )

    pushRouter(`/projects/${id}/visuals/new/batch`)
  }

  private fetchProducts(filters: { [key: string]: string[] }, renew = true, skip = 0) {
    const { fetchProducts, user } = this.props

    return user
      ? fetchProducts(
          {
            skip,
            filters: {
              ...filters,
              brandIds: (user as BrandUserWithBrandsDTO).brands.map(brand => brand.id),
              haveObjectProductModelBundle: ['true'],
              onVisibile: ['true']
            }
          },
          !renew
        )
      : Promise.resolve()
  }
}

const mapStateToProps = ({
  productState: { values },
  projectState: { value },
  userState: { user },
  packshotState: { selectedProducts, productsSettings, isAdd }
}: IRootState) => ({
  products: values,
  project: value,
  user,
  selectedProducts,
  productsSettings,
  isAdd
})

const mapDispatchToProps = (dispatch: ThunkDispatch<IRootState, {}, AnyAction>) => ({
  fetchProducts: (request: IPaginationRequest = {}, concatenate = false) =>
    dispatch(
      productActions.thunks.fetchValues(
        {
          ...request,
          filters: {
            ...DEFAULT_PRODUCTS_FILTERS,
            ...request.filters
          }
        },
        concatenate
      )
    ),
  fetchProject: (id: string) => dispatch(projectActions.thunks.fetchValue(id)),
  setSelectedProducts: (selectedProducts: ProductLightExtendedDTO[]) =>
    dispatch(packshotActions.setSelectedProducts(selectedProducts)),
  setProductsSettings: (productSettings: IProductSetting[]) =>
    dispatch(packshotActions.setProductsSettings(productSettings)),
  pushRouter: (location: string) => dispatch(push(location))
})

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(NewVisual))
