import {
  product as productActions,
  productViewer as productViewerActions
} from '@addsome/redux-store'
import { Button, Size, Theme } from '@addsome/ui-kit'
import Modal from 'antd/lib/modal'
import React, { FC, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import { IRootState } from '../../../../../redux'
import ProductHeader from '../../../../Common/ProductHeader/ProductHeader'
import Gallery from '../../../../Products/Gallery'
import Informations from '../../../../Products/Informations'
import { SheetTypes } from '../../../../Products/ProductSheet/ProductSheet'
import styles from './PlayerProduct.module.scss'

type IProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> & {
    productId?: string
    onAddProduct: (productId: string, variationIndex: number) => void
    onCancel: () => void
    displayActions: boolean
  }

const PlayerProduct: FC<IProps> = ({
  product,
  productId,
  fetchProduct,
  onAddProduct,
  onCancel,
  displayActions,
  setProductId,
  unsetProductId,
  variationIndex,
  setVariationIndex,
  setDisplayViewer
}) => {
  useEffect(() => {
    if (productId) {
      fetchProduct(productId)
      setProductId(productId)
    }
  }, [productId])

  return (
    <Modal
      centered
      className={styles.container}
      visible={!!productId}
      footer={false}
      width="100%"
      closable={false}
    >
      {product && (
        <>
          <ProductHeader
            breadCrumbRoutes={[
              {
                breadcrumbName: 'Mobilier',
                path: ''
              },
              {
                breadcrumbName: (product.brand && product.brand.name) || 'Marque',
                path: ''
              },
              {
                breadcrumbName: product.name,
                path: ''
              }
            ]}
            onClose={() => {
              // We reset the store to initial state when we leave the modal
              unsetProductId()
              setDisplayViewer(false)
              setVariationIndex(0)
              onCancel()
            }}
          />
          <div className={styles.content}>
            <Gallery productId={product.id} />
            <div className={styles.description}>
              <div className={styles.informationsContainer}>
                <Informations productId={product.id} type={SheetTypes.SHEET} />
              </div>
              {displayActions && (
                <div className={styles.actions}>
                  <Button
                    theme={Theme.DEFAULT}
                    size={Size.LARGE}
                    uppercase
                    block
                    onClick={() => {
                      // We reset the store to initial state when we leave the modal
                      unsetProductId()
                      setDisplayViewer(false)
                      setVariationIndex(0)
                      onCancel()
                    }}
                  >
                    <FormattedMessage id="player.back" />
                  </Button>
                  <Button
                    theme={Theme.BLACK}
                    size={Size.LARGE}
                    uppercase
                    block
                    onClick={() => onAddProduct(product.id, variationIndex)}
                  >
                    <FormattedMessage id="player.addProduct" />
                  </Button>
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </Modal>
  )
}

const mapStateToProps = (state: IRootState) => ({
  product: state.productState.value,
  variationIndex: state.productViewerState.variationIndex
})

const mapDispatchToProps = (dispatch: ThunkDispatch<IRootState, {}, AnyAction>) => ({
  fetchProduct: (productId: string) => dispatch(productActions.thunks.fetchValue(productId)),
  setProductId: (productId: string) => dispatch(productViewerActions.setProductId(productId)),
  unsetProductId: () => dispatch(productViewerActions.unsetProductId()),
  setVariationIndex: (variationIndex: number) =>
    dispatch(productViewerActions.setVariationIndex(variationIndex)),
  setDisplayViewer: (displayViewer: boolean) =>
    dispatch(productViewerActions.setDisplayViewer(displayViewer))
})

export default connect(mapStateToProps, mapDispatchToProps)(PlayerProduct)
