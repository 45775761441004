import { MediaDTO } from '@addsome/dtos'
import notification from 'antd/lib/notification'
import Upload, { UploadChangeParam } from 'antd/lib/upload'
import React from 'react'
import UploadButton from '../UploadButton/UploadButton'
import styles from './UploadMedia.module.scss'
import {WrappedComponentProps, injectIntl} from 'react-intl'

export type IUploadMediaProps = WrappedComponentProps & {
  action: string
  onUploaded: (response: MediaDTO) => void
  type?: 'button' | 'square'
  children?: React.ReactNode
  accept?: string
  className?: string
  token: string
  displayLoading?: boolean
}

interface IState {
  loading: boolean
}

class UploadMedia extends React.Component<IUploadMediaProps, IState> {
  public state: IState = {
    loading: false
  }

  public render() {
    return (
      <Upload
        name="file"
        action={this.props.action}
        onChange={this.handleChange}
        showUploadList={false}
        accept={this.props.accept || '*'}
        className={`${styles.uploadMedia} ${this.props.className}`}
        headers={{
          authorization: `Bearer ${this.props.token}`
        }}
      >
        <UploadButton loading={this.props.displayLoading ? this.state.loading : false}>
          {this.props.children}
        </UploadButton>
      </Upload>
    )
  }

  private handleChange = (info: UploadChangeParam) => {
    this.setState({
      loading: info.file.status === 'uploading'
    })

    if (info.file.status === 'done') {
      this.props.onUploaded(info.file.response)
    } else if (info.file.status === 'error') {
      notification.error({
        message: this.props.intl.formatMessage({id : 'global.error'}),
        description: this.props.intl.formatMessage({id: 'import.upload.media'})
      })
    }
  }
}

export default injectIntl(UploadMedia)
