import { Breadcrumb } from '@addsome/ui-kit'
import { BreadcrumbProps, Route } from 'antd/lib/breadcrumb/Breadcrumb'
import React from 'react'
import { Link } from 'react-router-dom'
import { ellipseString } from '../../utils'
import styles from './AppBreadcrumb.module.scss'

export const itemRender = (route: Route, params: any, routes: Route[]) => {
  const last = routes.indexOf(route) === routes.length - 1
  return last ? (
    <span className={`${styles.breadcrumbItem} ${styles.last}`}>
      {ellipseString(route.breadcrumbName, 12)}
    </span>
  ) : (
    <Link to={route.path}>
      {<span className={styles.breadcrumbItem}>{ellipseString(route.breadcrumbName, 12)}</span>}
    </Link>
  )
}

const AppBreadcrumb: React.FC<BreadcrumbProps> = ({ className, ...restProps }) => (
  <Breadcrumb className={className} {...restProps} itemRender={itemRender} />
)

export default AppBreadcrumb
