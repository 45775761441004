import { ProductLightDTO } from '@addsome/dtos'
import { ICardData, InfiniteGridWithSearch } from '@addsome/ui-kit'
import { push } from 'connected-react-router'
import React, { FC, ReactElement, useCallback, useMemo } from 'react'
import { WrappedComponentProps, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import { IRootState } from '../../../redux'
import { getCloudImageUrl } from '../../../utils'

interface IMatchParams {
  id: string
}

type IProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  WrappedComponentProps &
  RouteComponentProps<IMatchParams> & {
    title: string
    searchPlaceHolder: string
    fetchItems: (filters: { [key: string]: string[] }, renew: boolean, skip: number) => Promise<any>
    filters?: { [key: string]: string[] }
    firstButton: ReactElement
    secondButton: ReactElement
    onCardSelect: (selectedProducts: ICardData[]) => void
  }

const productPicture = (url: string) => {
  return getCloudImageUrl(url, 400)
}

const transformProductsToCardData = (products: ProductLightDTO[]): ICardData[] => {
  return products.map(({ name, description: desc, thumbnail, ...rest }) => {
    let image = null

    if (thumbnail && thumbnail.media.url) {
      image = productPicture(thumbnail.media.url)
    }

    return {
      ...rest,
      title: name,
      desc,
      image,
      footer: null
    }
  })
}

const ProductGrid: FC<IProps> = ({
  products,
  totalProducts,
  selectedProducts,
  intl,
  title,
  searchPlaceHolder,
  fetchItems,
  filters,
  firstButton,
  secondButton,
  onCardSelect,
  pushRouter,
  match
}) => {
  const productsForGrid: ICardData[] = useMemo(() => {
    return transformProductsToCardData(products)
  }, [products])

  const initialSelectedProducts: ICardData[] = useMemo(() => {
    return transformProductsToCardData(selectedProducts)
  }, [selectedProducts])

  const goToProduct = useCallback(
    (product: ICardData) => {
      pushRouter(`/projects/${match.params.id}/product/${product.id}`)
    },
    [pushRouter, match]
  )

  return (
    <InfiniteGridWithSearch
      doInitialFetch={true}
      title={title}
      initialSelectedCards={initialSelectedProducts}
      searchPlaceHolder={searchPlaceHolder}
      fetchItems={fetchItems}
      filters={filters}
      totalItems={totalProducts}
      cardsData={productsForGrid}
      selectedNumberText={intl.formatMessage({ id: 'product.selectedNumber' })}
      selectedNumberPluralText={intl.formatMessage({ id: 'product.selectedNumberPlural' })}
      onCardClick={goToProduct}
      onCardSelect={onCardSelect}
      firstButton={firstButton}
      secondButton={secondButton}
      selectable
      inner
      hoverText={intl.formatMessage({ id: 'global.open' })}
    />
  )
}

const mapStateToProps = ({
  productState: { values, total },
  packshotState: { selectedProducts }
}: IRootState) => ({
  products: values,
  totalProducts: total,
  selectedProducts
})

const mapDispatchToProps = (dispatch: ThunkDispatch<IRootState, {}, AnyAction>) => ({
  pushRouter: (location: string) => dispatch(push(location))
})

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(withRouter(ProductGrid)))
