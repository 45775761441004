import React from 'react'
import { WrappedComponentProps, injectIntl } from 'react-intl'

import { Button, Theme } from '@addsome/ui-kit'

import styles from './BlueButton.module.scss'

interface IState {
  labelIntlId: string
  loading?: boolean
  onClick?: () => void
  disabled?: boolean
}

type IProps = WrappedComponentProps & IState

const BlueButton: React.FC<IProps> = ({ intl, labelIntlId, loading, onClick, disabled }) => (
  <div className={styles.container}>
    <Button
      className={styles.button}
      theme={Theme.PRIMARY}
      htmlType="submit"
      loading={loading}
      onClick={onClick}
      disabled={disabled}
    >
      {intl.formatMessage({ id: labelIntlId })}
    </Button>
  </div>
)

export default injectIntl(BlueButton)
