import { ProductLightExtendedDTO, ProductRelationshipsExtendedDTO } from '@addsome/dtos'

export function convertProductForSheet(
  product: ProductRelationshipsExtendedDTO
): ProductLightExtendedDTO {
  return {
    ...product,
    objectProductId: product.objectProduct && product.objectProduct.id
  }
}
