import { Button, Size, Theme } from '@addsome/ui-kit'
import React, { FC } from 'react'
import { ApiMethod } from '../../enums'
import styles from './RecalibrateActions.module.scss'

interface IProps {
  isOpen: boolean
  onValidate: (method: ApiMethod, value?: string) => void
}

const RecalibrateActions: FC<IProps> = ({ onValidate, isOpen }) => {
  return (
    <div className={`${styles.container} ${isOpen ? styles.open : ''}`}>
      <Button
        theme={Theme.WHITE}
        size={Size.LARGE}
        uppercase
        block
        onClick={() => onValidate(ApiMethod.CancelRecalibrationFromPoints)}
      >
        Annuler
      </Button>
      <Button
        theme={Theme.PRIMARY}
        size={Size.LARGE}
        uppercase
        block
        onClick={() => onValidate(ApiMethod.ValidateRecalibrationFromPoints)}
      >
        Valider
      </Button>
    </div>
  )
}

export default RecalibrateActions
