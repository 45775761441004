import React from 'react'

/* tslint:disable: max-line-length*/

export const DollarsIcon = (
  <svg
    version="1.1"
    id="budget_icon"
    x="0px"
    y="0px"
    width="22px"
    height="22px"
    viewBox="0 0 22 22"
  >
    <g>
      <path
        d="M11.001,0.002V0C4.926,0.002,0.002,4.926,0,11.001c0.002,6.075,4.926,10.999,11.001,11C17.076,22,22,17.076,22,11.001
		S17.076,0.002,11.001,0.002z M11.001,20c-4.972-0.01-8.992-4.028-9-9c0.008-4.971,4.029-8.992,9-9c4.971,0.008,8.989,4.029,8.999,9
		C19.991,15.972,15.972,19.991,11.001,20z"
      />
      <polygon points="7.294,14.71 7.295,14.708 7.294,14.708 	" />
      <path
        d="M12.292,6h-0.291V4.002h-2V6H9.708C8.212,6.003,7.002,7.212,7,8.708v0.586C7.002,10.79,8.212,12,9.708,12h2.585
		C12.684,12,13,12.316,13,12.708v0.586C13,13.686,12.684,14,12.292,14H9.708c-0.187,0-0.366-0.074-0.5-0.208l-0.5-0.5l-1.412,1.416
		l0.499,0.499C8.301,15.714,8.985,16,9.7,16c0.002,0,0.005,0,0.008,0H10v2.002h2.002V16h0.29C13.789,16,15,14.789,15,13.294v-0.586
		c0-1.495-1.212-2.707-2.708-2.707H9.708C9.316,10,9.002,9.685,9.002,9.294L9,8.708c0.002-0.391,0.316-0.706,0.708-0.706L12.292,8
		C12.684,8.002,13,8.317,13,8.708v0.294h2V8.708C15,7.212,13.789,6,12.292,6z"
      />
    </g>
  </svg>
)

export const ObjectIcon = (
  <svg
    version="1.1"
    id="object_icon"
    x="0px"
    y="0px"
    width="22px"
    height="20px"
    viewBox="0 0 22 20"
  >
    <path
      d="M3,8V4c0-1.104,0.896-2,2-2h14c1.104,0,2,0.896,2,2v4c1.104,0,2,0.896,2,2v8c0,1.104-0.896,2-2,2
		h-2v2h-2v-2H7v2H5v-2H3c-1.104,0-2-0.896-2-2v-8C1,8.896,1.896,8,3,8z M5,8c1.104,0,2,0.896,2,2v3h10v-3c0-1.104,0.896-2,2-2V4
		H5V8z M7,15v3h10v-3H7z M19,10v8h2v-8H19z M3,10v8h2v-8H3z"
    />
  </svg>
)

export const BrandIcon = (
  <svg
    version="1.1"
    id="brand_icon"
    x="0px"
    y="0px"
    width="19.999px"
    height="19.998px"
    viewBox="0 0 19.999 19.998"
  >
    <path
      d="M10.394,19.998C9.66,20,8.92,19.714,8.361,19.158l0,0l0.001-0.001l-0.001-0.001l-8.067-8.059C0.107,10.911,0,10.653,0,10.39
	l0,0V1c0-0.263,0.107-0.521,0.294-0.708l0,0C0.479,0.107,0.737,0,1,0l0,0h9.39c0.264,0,0.521,0.105,0.706,0.292l0,0l8.068,8.071
	C19.72,8.92,20,9.66,19.999,10.39l0,0c0.002,0.712-0.267,1.431-0.792,1.984l0,0c-0.015,0.017-0.029,0.032-0.044,0.048l0,0
	l-6.733,6.736c-0.56,0.556-1.297,0.84-2.027,0.84l0,0C10.399,19.998,10.397,19.998,10.394,19.998L10.394,19.998z M9.775,17.742
	c0.17,0.173,0.393,0.257,0.619,0.257l0,0c0.227,0,0.448-0.084,0.621-0.257l0,0l6.727-6.73l0,0l0,0C17.916,10.835,18,10.614,18,10.39
	l0,0c-0.002-0.229-0.084-0.444-0.253-0.618l0,0L9.974,2H2v7.976l7.763,7.757L9.775,17.742L9.775,17.742z"
    />
  </svg>
)

export const StyleIcon = (
  <svg
    version="1.1"
    id="style_icon"
    x="0px"
    y="0px"
    width="19.999px"
    height="19.999px"
    viewBox="0 0 19.999 19.999"
  >
    <path
      d="M0.554,6.396l8.999,4.499C9.692,10.965,9.846,11,10,11c0.153,0,0.307-0.035,0.448-0.105l8.999-4.499
		c0.339-0.171,0.552-0.515,0.552-0.896c0-0.378-0.213-0.725-0.552-0.893l-8.999-4.501c-0.282-0.14-0.613-0.14-0.896,0L0.554,4.607
		C0.214,4.775,0,5.122,0,5.5C0,5.881,0.214,6.225,0.554,6.396z M10,2.118L16.764,5.5L9.999,8.882L3.235,5.5L10,2.118z"
    />
    <path
      d="M18.553,13.607l-8.554,4.275l-8.552-4.277c-0.494-0.247-1.095-0.047-1.34,0.449c-0.247,0.493-0.047,1.093,0.447,1.34
		l8.999,4.499c0.14,0.07,0.293,0.105,0.447,0.105c0.153,0,0.307-0.035,0.448-0.105l8.999-4.499c0.492-0.247,0.693-0.847,0.446-1.34
		C19.646,13.561,19.047,13.36,18.553,13.607z"
    />
    <path
      d="M18.553,9.106l-8.554,4.276L1.447,9.106c-0.494-0.247-1.095-0.047-1.34,0.448c-0.247,0.492-0.047,1.093,0.447,1.34
		l8.999,4.5c0.14,0.069,0.293,0.104,0.447,0.104c0.153,0,0.307-0.035,0.448-0.104l8.999-4.5c0.492-0.247,0.693-0.848,0.446-1.34
		C19.646,9.061,19.047,8.861,18.553,9.106z"
    />
  </svg>
)

export const MaterialIcon = (
  <svg
    version="1.1"
    id="material_icon"
    x="0px"
    y="0px"
    width="18px"
    height="18px"
    viewBox="0 0 18 18"
  >
    <path
      d="M5,5v14h14V5H5z M5,3h14c1.104,0,2,0.896,2,2v14c0,1.104-0.896,2-2,2H5c-1.104,0-2-0.896-2-2V5
		C3,3.896,3.896,3,5,3z M4.491,11.065L3.077,9.651l6.065-6.065L10.556,5L4.491,11.065z M5,20.557l-1.414-1.414L19.143,3.586
		L20.557,5L5,20.557z M4.491,16.065l-1.414-1.414L14.143,3.586L15.557,5L4.491,16.065z M10,20.557l-1.414-1.414L19.49,8.237
		l1.415,1.415L10,20.557z M15,20.557l-1.414-1.414l5.904-5.906l1.415,1.415L15,20.557z"
    />
  </svg>
)

export const RoomIcon = (
  <svg version="1.1" id="room_icon" x="0px" y="0px" width="18px" height="18px" viewBox="0 0 18 18">
    <path
      d="M16,0H2C0.895,0,0,0.895,0,2v13.999c0,1.105,0.895,2,2,2h2V18h10v-0.001h2c1.105,0,2-0.895,2-2V2C18,0.895,17.105,0,16,0z
	 M12,15.999H6.072c0.442-3.065,2.864-5.484,5.927-5.928V15.999z M16,15.999h-2v-8h-1c-4.631,0.002-8.443,3.5-8.941,8H2V2h14V15.999z
	"
    />
  </svg>
)

export const ColorIcon = (
  <svg
    version="1.1"
    id="color_icon"
    x="0px"
    y="0px"
    width="19.971px"
    height="19.969px"
    viewBox="0 0 19.971 19.969"
  >
    <path
      d="M19.086,14.813L9.816,5.542l1.864-1.864l-1.415-1.415L8.401,4.126L5.129,0.854C4.53,0.28,3.771-0.002,3.021,0
	C2.231-0.002,1.43,0.313,0.839,0.932C0.282,1.511,0,2.27,0,3.02c0,0.751,0.284,1.509,0.839,2.091L4.128,8.4l-1.864,1.863
	l1.415,1.415l1.864-1.864l9.272,9.272c0.587,0.587,1.367,0.885,2.135,0.883c0.003,0,0.005,0,0.008,0c0.768,0,1.54-0.298,2.125-0.879
	c0.572-0.57,0.889-1.34,0.889-2.141C19.971,16.147,19.655,15.379,19.086,14.813z M2.27,3.713C2.093,3.526,2.002,3.277,2,3.02
	c0.002-0.257,0.093-0.504,0.284-0.704C2.488,2.102,2.75,2,3.021,1.999C3.279,2,3.528,2.091,3.729,2.285l3.257,3.257L5.542,6.985
	L2.27,3.713z M17.672,17.672c-0.202,0.199-0.459,0.296-0.723,0.299c-0.266-0.003-0.519-0.1-0.721-0.301L6.958,8.4l1.444-1.444
	l9.271,9.271c0.191,0.191,0.298,0.451,0.301,0.723C17.973,17.221,17.864,17.481,17.672,17.672z"
    />
  </svg>
)
export const OriginIcon = (
  <svg
    version="1.1"
    id="madein_icon"
    x="0px"
    y="0px"
    width="19.999px"
    height="19px"
    viewBox="0 0 19.999 19"
  >
    <path
      d="M18,0H0v19h2v-5.002L18,14c1.105-0.002,1.999-0.896,1.999-2.001V2C19.999,0.896,19.105,0,18,0z M12,2V12H8V2H12z M2,2h4V12
	H2V2z M18,12H14V2H18V12z"
    />
  </svg>
)

export const RectPlusIcon = () => (
  <svg width="18px" height="18px" viewBox="0 0 18 18" version="1.1">
    <g id="Addsome---Studio" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="Ambiance---Configurateur-3D---Décor-(1)-(1)"
        transform="translate(-1254.000000, -775.000000)"
        fill="currentColor"
      >
        <path
          d="M1262.64637,783.538497 L1260.09637,783.538497 L1260.09637,781.838497 L1262.64637,781.838497 L1262.64637,779.288497 L1264.34637,779.288497 L1264.34637,781.838497 L1266.89637,781.838497 L1266.89637,783.538497 L1264.34637,783.538497 L1264.34637,786.088497 L1262.64637,786.088497 L1262.64637,783.538497 Z M1256.69637,788.638497 L1270.29637,788.638497 L1270.29637,776.738497 L1256.69637,776.738497 L1256.69637,788.638497 Z M1254.99637,792.038497 L1269.44637,792.038497 L1271.99637,792.038497 L1271.99637,775.038497 L1254.99637,775.038497 L1254.99637,792.038497 Z"
          id="image_plus-[#988]"
        ></path>
      </g>
    </g>
  </svg>
)

export const TrashIcon = () => (
  <svg width="16px" height="19px" viewBox="0 0 16 19">
    <g id="Addsome---Studio" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="Ambiance---Configurateur-3D---Décor-(1)-(1)"
        transform="translate(-1224.000000, -774.000000)"
      >
        <g id="Trash-2-Copy" transform="translate(1219.992734, 771.538497)">
          <rect id="Rectangle" fillRule="nonzero" x="0" y="0" width="24" height="24"></rect>
          <polyline
            id="Path"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="square"
            points="5 7.2 6.55555556 7.2 19 7.2"
          ></polyline>
          <path
            d="M17.4444444,7.2 L17.4444444,18.4 L17.4444444,18.4 C17.4444444,19.283656 16.7479981,20 15.8888889,20 L8.11111111,20 L8.11111104,20 C7.25200104,20 6.55555549,19.2836552 6.55555549,18.4 C6.55555549,18.4 6.55555549,18.4 6.55555549,18.4 L6.55555549,7.2 M8.88888882,7.20000024 L8.88888882,5.6 L8.88888882,5.60000024 C8.88888869,4.71634424 9.58533438,4.00000024 10.4444444,4.00000024 L13.5555555,4.00000024 L13.5555554,4.00000024 C14.4146654,4.0000002 15.111111,4.71634424 15.111111,5.60000024 L15.111111,7.20000024"
            id="Shape"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="square"
          ></path>
          <path
            d="M10.4444444,11.2 L10.4444444,16"
            id="Path"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="square"
          ></path>
          <path
            d="M13.5555556,11.2 L13.5555556,16"
            id="Path"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="square"
          ></path>
        </g>
      </g>
    </g>
  </svg>
)
