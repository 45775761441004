import { project as projectActions } from '@addsome/redux-store'
import { AnyAction } from '@addsome/redux-store/node_modules/redux'
import { ThunkDispatch } from '@addsome/redux-store/node_modules/redux-thunk'
import { Heading, Tabs } from '@addsome/ui-kit'
import { push } from 'connected-react-router'
import React from 'react'
import { WrappedComponentProps, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router-dom'
import Layout from '../../../../components/Common/Layout'
import Viewer from '../../../../components/Products/Viewer'
import WebForm from '../../../../components/Visuals/SettingsForms/WebForm'
import { IWebInitialValues } from '../../../../components/Visuals/SettingsForms/WebForm/WebForm'
import { IRootState } from '../../../../redux'
import styles from './Settings.module.scss'

const webInitialValues: IWebInitialValues = {
  type: 'web',
  units: 'Pixels',
  width: '1920',
  height: '1080',
  fileType: 'JPG',
  colorMode: 'Couleur RVB',
  pixelizationEffect: 'Écran (72 ppp)'
}

const printIinitialValues: IWebInitialValues = {
  type: 'print',
  units: 'Pixels',
  width: '3508',
  height: '2480',
  fileType: 'JPG',
  colorMode: 'CMJN',
  pixelizationEffect: '300 dpi'
}

interface IMatchParams {
  id: string
  productId: string
}

type IProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  WrappedComponentProps &
  RouteComponentProps<IMatchParams>

class Settings extends React.PureComponent<IProps> {
  public componentDidMount() {
    const {
      match: {
        params: { id }
      },
      project,
      products,
      pushRouter,
      fetchProject
    } = this.props

    if (!products || !products.length) {
      pushRouter('/')
      return
    }

    if (!project || project.id !== id) {
      fetchProject(id)
    }
  }

  public render() {
    const { intl, project, products } = this.props

    if (!products || !products.length) {
      return null
    }

    const projectId = project ? project.id : ''
    const productPicture = products && products[0].thumbnail ? products[0].thumbnail.media : null

    return (
      <Layout
        className={styles.layout}
        breadcrumbRoutes={[
          {
            breadcrumbName: intl.formatMessage({ id: 'breadcrumb.home' }),
            path: '/'
          },
          {
            breadcrumbName: project ? project.name : '',
            path: `/projects/${projectId}/products`
          },
          {
            breadcrumbName: intl.formatMessage({ id: 'visual.new.breadcrumbName' }),
            path: `/projects/${projectId}/visuals/new`
          },
          {
            breadcrumbName: intl.formatMessage({ id: 'packshot.settings.settings' }),
            path: `/projects/${projectId}/visuals/new/batch`
          }
        ]}
      >
        <div className={styles.content}>
          <div className={styles.viewer}>
            <Viewer picture={productPicture ? productPicture : undefined} />
          </div>
          <div className={styles.settings}>
            <Heading level={2} strong className={styles.title}>
              {intl.formatMessage({ id: 'packshot.settings.settings' })}
            </Heading>
            <Tabs
              size="small"
              className={styles.tabs}
              animated={{ inkBar: true, tabPane: false }}
              fullWidth
            >
              <Tabs.TabPane tab={intl.formatMessage({ id: 'packshot.settings.web' })} key="web">
                <WebForm projectId={projectId} initialValues={webInitialValues} />
              </Tabs.TabPane>
              <Tabs.TabPane
                tab={intl.formatMessage({ id: 'packshot.settings.printing' })}
                key="printing"
              >
                <WebForm projectId={projectId} initialValues={printIinitialValues} />
              </Tabs.TabPane>
            </Tabs>
          </div>
        </div>
      </Layout>
    )
  }
}

const mapStateToProps = (state: IRootState) => ({
  project: state.projectState.value,
  products: state.packshotState.selectedProducts
})

const mapDispatchToProps = (dispatch: ThunkDispatch<IRootState, {}, AnyAction>) => ({
  fetchProject: (id: string) => dispatch(projectActions.thunks.fetchValue(id)),
  pushRouter: (location: string) => dispatch(push(location))
})

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Settings))
