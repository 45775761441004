import { InvisibleButton } from '@addsome/ui-kit'
import React, { FC } from 'react'
import { connect } from 'react-redux'
import { IRootState } from '../../../redux'
import styles from './ProductsPreview.module.scss'
import { useHorizontalScroll } from '../../../hooks/scroll'

type IProps = ReturnType<typeof mapStateToProps> & {
  onClickProduct: (productId: string) => void
}

const ProductsPreview: FC<IProps> = ({ products, onClickProduct }) => {
  const { scrollContainerRef, handleWheel } = useHorizontalScroll()

  return (
    <div className={styles.container} ref={scrollContainerRef} onWheel={handleWheel}>
      {products.map(product => (
        <InvisibleButton key={product.id} onClick={() => onClickProduct(product.id)}>
          <div className={styles.productCard}>
            <div className={styles.productThumbnail}>
              {product.thumbnail && product.thumbnail.media && (
                <img src={product.thumbnail.media.url || ''} />
              )}
            </div>
            <span className={styles.productName}>{product.name}</span>
          </div>
        </InvisibleButton>
      ))}
    </div>
  )
}

const mapStateToProps = (state: IRootState) => ({
  products: state.productState.values
})

export default connect(mapStateToProps)(ProductsPreview)
