import { ProductLightExtendedDTO } from '@addsome/dtos/dist'
import { IPaginationRequest, packshot, product as productActions } from '@addsome/redux-store'
import { ICardData, InfiniteGridWithSearch } from '@addsome/ui-kit'
import { push } from 'connected-react-router'
import moment from 'moment'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router-dom'
import { AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import ProjectLayout from '../../components/Projects/Layout'
import EmptyCategory from '../../components/Projects/Project/Empty'
import { IRootState } from '../../redux'
import { getCloudImageUrl } from '../../utils'
import styles from './Products.module.scss'

interface IMatchParams {
  id: string
}

type IProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  WrappedComponentProps &
  RouteComponentProps<IMatchParams>

const Products: React.FC<IProps> = ({
  fetchProducts,
  products,
  totalProducts,
  project,
  match,
  intl,
  pushRouter,
  setSelectedProducts,
  emptyProducts,
  setIsAdd
}) => {
  const [loaded, setLoaded] = useState(false)
  const [empty, setEmpty] = useState(true)

  useEffect(() => {
    if (!project) return
    fetchProducts(match.params.id || project.id).then(() => setLoaded(true))
  }, [fetchProducts, project, match])

  useEffect(() => {
    if (empty && products.length) {
      setEmpty(false)
    }
    setIsAdd(false)
    setSelectedProducts([])
  }, [empty, products, setSelectedProducts, setIsAdd])

  const productPicture = (url: string) => {
    return getCloudImageUrl(url, 400)
  }

  useEffect(() => {
    return () => {
      emptyProducts()
    }
  }, [emptyProducts])

  const productsForGrid: ICardData[] = useMemo(() => {
    return products.map(({ name, description: desc, thumbnail, updatedAt, ...rest }) => {
      let image = null

      if (thumbnail && thumbnail.media.url) {
        image = productPicture(thumbnail.media.url)
      }

      return {
        ...rest,
        title: name,
        desc,
        footer: `${intl.formatMessage({ id: 'global.updatedAt' })} ${moment(updatedAt).format(
          'DD/MM à hh:mm'
        )}`,
        image
      }
    })
  }, [intl, products])

  const fetchItems = useCallback(
    (filters: { [key: string]: string[] }, renew: boolean, skip: number) => {
      if (!project) return Promise.resolve()
      return fetchProducts(project.id, { skip, filters }, !renew)
    },
    [fetchProducts, project]
  )

  const goToProduct = useCallback(
    (product: ICardData) => {
      pushRouter(`/projects/${match.params.id}/product/${product.id}`)
    },
    [pushRouter, match]
  )

  return (
    <ProjectLayout activeTabKey="products" hasContent={!empty}>
      {empty && loaded ? (
        <EmptyCategory />
      ) : (
        <InfiniteGridWithSearch
          headerClassName={styles.gridHeader}
          searchPlaceHolder={intl.formatMessage({ id: 'products.searchProduct' })}
          fetchItems={fetchItems}
          totalItems={totalProducts}
          doInitialFetch={false}
          cardsData={productsForGrid}
          onCardClick={goToProduct}
          inner
          loading={!loaded}
          hoverText={intl.formatMessage({ id: 'global.open' })}
        />
      )}
    </ProjectLayout>
  )
}

const mapStateToProps = ({
  productState: { values: products, total: totalProducts },
  projectState: { value: project }
}: IRootState) => ({
  products,
  totalProducts,
  project
})

const mapDispatchToProps = (dispatch: ThunkDispatch<IRootState, {}, AnyAction>) => ({
  fetchProducts: (projectId: string, request: IPaginationRequest = {}, concatenate = false) =>
    dispatch(
      productActions.thunks.fetchValues(
        {
          ...request,
          filters: {
            ...request.filters,
            projectIds: [projectId]
          }
        },
        concatenate
      )
    ),

  emptyProducts: () => dispatch(productActions.thunks.setValues([], 0)),
  pushRouter: (location: string) => dispatch(push(location)),
  setSelectedProducts: (selectedProducts: ProductLightExtendedDTO[]) =>
    dispatch(packshot.setSelectedProducts(selectedProducts)),

  setIsAdd: (isAdd: boolean) => dispatch(packshot.setIsAdd(isAdd))
})

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Products))
