import React from 'react'

import { Modal as UIKitModal } from '@addsome/ui-kit'

interface IState {
  visible: boolean
}

class Modal extends React.PureComponent<{}, IState> {
  public state = { visible: false }

  public componentDidMount() {
    this.setState({ visible: false })
  }

  public showModal = () => {
    this.setState({
      visible: true
    })
  }

  public hideModal = () => {
    this.setState({
      visible: false
    })
  }

  public render() {
    const { children } = this.props

    return (
      <UIKitModal visible={this.state.visible} closable={false} footer={null} width={'30rem'}>
        {children}
      </UIKitModal>
    )
  }
}

export default Modal
