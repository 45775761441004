import { Breadcrumb, InvisibleButton } from '@addsome/ui-kit'
import { Route } from 'antd/lib/breadcrumb/Breadcrumb'
import React from 'react'
import close from '../../../assets/images/icons/close.png'
import logo from '../../../assets/images/logos/addsome/logo.png'
import styles from './ProductHeader.module.scss'

interface IProps {
  breadCrumbRoutes: Route[]
  onClose: () => void
}

const ProductHeader: React.FC<IProps> = ({ breadCrumbRoutes, onClose }) => (
  <div className={styles.container}>
    <img src={logo} className={styles.logo} />
    <div className={styles.breadcrumb}>
      <Breadcrumb routes={breadCrumbRoutes} />
    </div>
    <InvisibleButton title="close" onClick={onClose}>
      <img src={close} className={styles.close} />
    </InvisibleButton>
  </div>
)

export default ProductHeader
