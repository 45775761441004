import { push } from 'connected-react-router'
import React from 'react'
import { WrappedComponentProps, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'

import { Heading, InvisibleButton } from '@addsome/ui-kit'

import closeIcon from '../../../assets/images/icons/close.png'
import newProjectLogo from '../../../assets/images/icons/compas.svg'
import Layout from '../../../components/Common/Layout'
import NewProjectForm from '../../../components/Projects/New'
import { IRootState } from '../../../redux'
import styles from './NewProject.module.scss'

type IProps = ReturnType<typeof mapDispatchToProps> & WrappedComponentProps

const NewProjectPage: React.FC<IProps> = ({ intl, pushRouter }) => (
  <Layout
    breadcrumbRoutes={[
      {
        breadcrumbName: intl.formatMessage({ id: 'breadcrumb.home' }),
        path: '/'
      },
      {
        breadcrumbName: intl.formatMessage({ id: 'breadcrumb.newProject' }),
        path: '/newproject'
      }
    ]}
  >
    <div className={styles.close}>
      <InvisibleButton
        title={intl.formatMessage({ id: 'misc.close' })}
        onClick={() => pushRouter('/')}
      >
        <img src={closeIcon} className={styles.closeButton} />
      </InvisibleButton>
    </div>
    <div className={styles.content}>
      <img className={styles.newProjectLogo} src={newProjectLogo} />
      <Heading level={2} className={styles.title}>
        {intl.formatMessage({ id: 'newProject.title' })}
      </Heading>
      <Heading level={2} className={styles.subtitle}>
        {intl.formatMessage({ id: 'newProject.subtitle' })}
      </Heading>
      <NewProjectForm />
    </div>
  </Layout>
)

const mapDispatchToProps = (dispatch: ThunkDispatch<IRootState, {}, AnyAction>) => ({
  pushRouter: (location: string) => dispatch(push(location))
})

export default connect(undefined, mapDispatchToProps)(injectIntl(NewProjectPage))
