import React from 'react'
import { WrappedComponentProps, injectIntl } from 'react-intl'
import styles from './Footer.module.scss'

const LoginHeader: React.FC<WrappedComponentProps> = ({ intl }) => (
  <footer className={styles.container}>
    <div className={styles.copyright}>
      <span>
        {intl.formatMessage({ id: 'footer.copyright' }, { year: new Date().getFullYear() })}
      </span>
    </div>
    {/* TODO:
    <div className={styles.actions}>
      <div className={styles.links}>
        <a href="/termsofuse">{intl.formatMessage({ id: 'footer.termsOfUse' })}</a>
        <a href="/legalnotice">{intl.formatMessage({ id: 'footer.legalNotice' })}</a>
        <a href="/help">{intl.formatMessage({ id: 'footer.help' })}</a>
      </div>
    </div>
    */}
  </footer>
)

export default injectIntl(LoginHeader)
