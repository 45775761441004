import { AccountType } from '@addsome/dtos'
import {
  auth as authAction,
  brandTag,
  colorTag,
  objectProductCategories,
  productMaterialTag,
  productPrices,
  roomTag,
  styleTag
} from '@addsome/redux-store'
import { ConnectedRouter, push } from 'connected-react-router'
import React from 'react'
import { IntlProvider } from 'react-intl'
import { Provider } from 'react-redux'
import { Redirect, Route, Switch } from 'react-router-dom'
import { setLocale } from 'yup'
import AppWrapper from './AppWrapper'
import Ambiances from './pages/Ambiances'
import NewAmbiance from './pages/Ambiances/New'
import ForgottenPassword from './pages/ForgottenPassword/ForgottenPassword'
import Login from './pages/Login'
import UnderContructionPage from './pages/Misc/UnderConstructionPage'
import Products from './pages/Products'
import Product from './pages/Products/Product'
import Projects from './pages/Projects'
import NewProject from './pages/Projects/New'
import ResetPassword from './pages/ResetPassword/ResetPassword'
import ScenePage from './pages/Scene'
import Visuals from './pages/Visuals'
import NewVisual from './pages/Visuals/New'
import Batch from './pages/Visuals/New/Batch'
import Settings from './pages/Visuals/Packshot/Settings'
import Summary from './pages/Visuals/Packshot/Summary'
import Visual from './pages/Visuals/Visual'
import store, { browserHistory } from './redux'
import './styles/global.scss'
import messages_en from './translations/en.json'
import messages_fr from './translations/fr.json'
import { localeEn, localeFr } from './translations/validationLocale'
import { ILocales, IMessages } from './utils/interfaces'
import { setSentryUserInfo } from './utils/sentry'

const messages: IMessages = {
  fr: messages_fr,
  en: messages_en
}

// language without region code
let language = navigator.language.split(/[-_]/)[0]

language = language === 'fr' || language === 'en' ? language : 'en'

const validationLocales: ILocales = {
  fr: localeFr,
  en: localeEn
}
setLocale(validationLocales[language])

const freeRoutes = [
  '/login',
  '/help',
  '/legalnotice',
  '/termsofuse',
  '/forgotten-password',
  '/reset-password'
]

class App extends React.PureComponent {
  public async componentDidMount() {
    const route = store.getState().router.location.pathname
    const token = store.getState().authState.token
    if (token) {
      await store.dispatch(authAction.getAuth())
      const authState = store.getState().authState
      if (authState.type !== AccountType.BrandUser || !authState.token) {
        await store.dispatch(authAction.logout())
        await store.dispatch(push('/login'))
      } else {
        const { user } = store.getState().userState
        setSentryUserInfo(user)

        // Store hydratation
        store.dispatch(objectProductCategories.thunks.fetchValues())
        store.dispatch(productMaterialTag.thunks.fetchValues())
        store.dispatch(styleTag.thunks.fetchValues())
        store.dispatch(colorTag.thunks.fetchValues())
        store.dispatch(roomTag.thunks.fetchValues())
        store.dispatch(productPrices.fetchPrices())
        store.dispatch(brandTag.fetchPromotedBrands())
        store.dispatch(brandTag.fetchBrandLexique())
      }
    } else if (!freeRoutes.includes(route)) {
      await store.dispatch(push('/login'))
    }
  }

  public render() {
    return (
      <Provider store={store}>
        <IntlProvider locale={language} messages={messages[language]}>
          <ConnectedRouter history={browserHistory}>
            <Switch>
              <Route path="/login" exact component={Login} />
              <Route path="/forgotten-password" exact component={ForgottenPassword} />
              <Route path="/reset-password" exact component={ResetPassword} />
              <AppWrapper>
                <Route path="/" exact component={Projects} />
                <Route path="/help" exact component={UnderContructionPage} />
                <Route path="/legalnotice" exact component={UnderContructionPage} />
                <Route
                  path="/projects/:id"
                  exact
                  render={({ match }) => {
                    return match.params.id === 'new' ? (
                      <Route path="/projects/new" exact component={NewProject} />
                    ) : (
                      <Redirect to={`/projects/${match.params.id}/products`} />
                    )
                  }}
                />
                <Route path="/projects/:id/ambiances" exact component={Ambiances} />
                <Route path="/projects/:id/products" exact component={Products} />
                <Route path="/projects/:id/visuals" exact component={Visuals} />
                <Route
                  path="/projects/:projectId/visual/:visualId/type/:type"
                  exact
                  component={Visual}
                />
                <Route path="/projects/:projectId/product/:productId" exact component={Product} />
                <Route path="/projects/:id/visuals/new" exact component={NewVisual} />
                <Route path="/projects/:id/visuals/new/batch" exact component={Batch} />
                <Route path="/projects/:id/visuals/new/settings" exact component={Settings} />
                <Route path="/projects/:id/visuals/new/summary" exact component={Summary} />
                <Route path="/projects/:id/ambiance/new" exact component={NewAmbiance} />
                <Route path="/projects/:projectId/scene/:sceneId" exact component={ScenePage} />
                <Route path="/termsofuse" exact component={UnderContructionPage} />
              </AppWrapper>
              <Redirect to="/" />
            </Switch>
          </ConnectedRouter>
        </IntlProvider>
      </Provider>
    )
  }
}

export default App
