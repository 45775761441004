import { BrandUserWithBrandsDTO, ProjectDTO } from '@addsome/dtos'
import { project as projectActions } from '@addsome/redux-store'
import { InputField, TextArea } from '@addsome/ui-kit'
import { Form } from 'antd'
import { push } from 'connected-react-router'
import { Formik } from 'formik'
import React from 'react'
import { WrappedComponentProps, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import * as yup from 'yup'
import { IRootState } from '../../../redux'
import BlueButton from '../../Common/Elements/BlueButton'
import styles from './NewProjectForm.module.scss'

interface IInitialValues {
  projectName: string
  description: string
}

const initialValues: IInitialValues = {
  projectName: '',
  description: ''
}

type IProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  WrappedComponentProps

class NewProjectForm extends React.PureComponent<IProps> {
  public validationSchema = yup.object().shape({
    projectName: yup
      .string()
      .required()
      .min(5),
    description: yup.string().min(5)
  })

  public render() {
    const { user, intl, loading, registerProject } = this.props

    return (
      <div className={styles.container}>
        <Formik
          initialValues={initialValues}
          validationSchema={this.validationSchema}
          onSubmit={values => {
            if (
              user &&
              (user as BrandUserWithBrandsDTO).brands.length > 0 &&
              (user as BrandUserWithBrandsDTO).brands[0].id
            ) {
              registerProject(
                (user as BrandUserWithBrandsDTO).brands[0].id,
                (user as BrandUserWithBrandsDTO).id,
                values.description,
                values.projectName
              )
            }
          }}
        >
          {formik => (
            <Form onSubmit={formik.handleSubmit} className={styles.form}>
              <Form.Item label={intl.formatMessage({ id: 'newProject.projectName' })}>
                <InputField
                  name="projectName"
                  defaultValue={initialValues.projectName}
                  error={formik.touched.projectName ? formik.errors.projectName : undefined}
                  required
                  onChange={formik.handleChange}
                  type="text"
                  allowClear={true}
                  suppressContentEditableWarning
                />
              </Form.Item>
              <Form.Item label={intl.formatMessage({ id: 'newProject.description' })}>
                <InputField
                  error={formik.touched.description ? formik.errors.description : undefined}
                >
                  <TextArea
                    name="description"
                    defaultValue={initialValues.description}
                    onChange={formik.handleChange}
                  />
                </InputField>
              </Form.Item>
              <BlueButton labelIntlId={'misc.continue'} loading={loading} />
              <div className={styles.cancelContainer}>
                <Link className={styles.cancel} to="/">
                  {intl.formatMessage({ id: 'misc.cancel' })}
                </Link>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    )
  }
}

const mapStateToProps = (state: IRootState) => ({
  loading: state.projectState.loading,
  user: state.userState.user
})

const mapDispatchToProps = (dispatch: ThunkDispatch<IRootState, {}, AnyAction>) => ({
  pushRouter: (location: string) => dispatch(push(location)),
  registerProject: (
    brandId: string,
    brandUserId: string,
    description: string,
    projectName: string
  ) => {
    dispatch(
      projectActions.thunks.createValue({
        name: projectName,
        description,
        brandProject: {
          brandId,
          brandUserId
        }
      })
    ).then((res: ProjectDTO) => {
      dispatch(push(`/projects/${res.id}/products`))
    })
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(NewProjectForm))
