import { ProductPictureDTO } from '@addsome/dtos'
import { apiRequest, productViewer as productViewerActions } from '@addsome/redux-store'
import React, { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import playerIcon from '../../../assets/images/icons/data-3D.svg'
import { IRootState } from '../../../redux'
import settings from '../../../settings'
import ProductViewer from '../../Player/ProductViewer/ProductViewer'
import styles from './Gallery.module.scss'

interface IFetchPicturesPayload {
  data: ProductPictureDTO[]
  total: number
}

type IProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> & {
    productId: string
    defaultActiveItem?: '3dPlayer' | number
  }

// TODO: Mettre ça dans Redux
function fetchPictures(id: string) {
  return apiRequest<IFetchPicturesPayload>('GET', `/products/${id}/pictures`)
}

const ProductGallery: React.FC<IProps> = ({
  productId,
  setDisplayViewer,
  productViewState,
  displayActions,
  defaultActiveItem
}) => {
  const [pictures, setPictures] = useState<ProductPictureDTO[] | null>(null)
  const [selectedPicture, setSelectedPicture] = useState<ProductPictureDTO | null>(null)

  useEffect(() => {
    fetchPictures(productId).then(v => {
      setPictures(v.data)
      setSelectedPicture(v && v.data.length > 0 ? v.data[0] : null)

      return () => {
        setSelectedPicture(null)
        setPictures(null)
      }
    })

    if (defaultActiveItem !== undefined) {
      if (defaultActiveItem === '3dPlayer') {
        setDisplayViewer(true)
      } else {
        setDisplayViewer(false)
        setSelectedPicture(pictures && pictures[defaultActiveItem])
      }
    }

    return () => {
      setDisplayViewer(false)
    }
  }, [productId])

  const galleryImagePreview = (url: string) => {
    return settings.media.cloudimageToken
      ? `https://${settings.media.cloudimageToken}.cloudimg.io/width/72/x/${url}`
      : url
  }

  return (
    <div className={styles.container}>
      <div className={styles.preview}>
        <div
          className={`${styles.productViewer} ${
            productViewState.displayViewer ? styles.visible : styles.hidden
          }`}
        >
          <ProductViewer
            productId={productViewState.productId}
            variationIndex={productViewState.variationIndex}
          />
        </div>
        {!productViewState.displayViewer && selectedPicture && (
          <img src={selectedPicture.media.url || ''} className={styles.previewImg} />
        )}
      </div>
      {!displayActions && (
        <div className={styles.imageButtons}>
          <button
            className={`${styles.imageButton} ${
              productViewState.displayViewer ? styles.selected : ''
            }`}
            onClick={() => setDisplayViewer(true)}
          >
            <img src={playerIcon} className={styles.playerLogo} />
            <span>
              <FormattedMessage id="product.3Dview" />
            </span>
          </button>
          {pictures &&
            pictures.map(picture => (
              <button
                className={`${styles.imageButton} ${
                  !productViewState.displayViewer &&
                  selectedPicture &&
                  selectedPicture.id === picture.id
                    ? styles.selected
                    : ''
                }`}
                key={picture.id}
                onClick={() => {
                  setDisplayViewer(false)
                  setSelectedPicture(picture)
                }}
              >
                <img src={galleryImagePreview(picture.media.url || '')} />
              </button>
            ))}
        </div>
      )}
    </div>
  )
}

const mapStateToProps = (state: IRootState) => ({
  productViewState: state.productViewerState,
  displayActions: state.productViewerState.displayActions
})

const mapDispatchToProps = (dispatch: ThunkDispatch<IRootState, {}, AnyAction>) => ({
  setDisplayViewer: (displayViewer: boolean) =>
    dispatch(productViewerActions.setDisplayViewer(displayViewer))
})

export default connect(mapStateToProps, mapDispatchToProps)(ProductGallery)
