import React, { useEffect, useMemo } from 'react'
import { connect } from 'react-redux'
import { IRootState } from '../../../redux'

export const PRODUCT_VIEWER_CONTAINER_ID = 'product-viewer'

type IProps = ReturnType<typeof mapStateToProps>

const ProductViewerContainer: React.FC<IProps> = ({ displayViewer, init, productId }) => {
  const parent = document.getElementById(PRODUCT_VIEWER_CONTAINER_ID)

  const el = useMemo(() => {
    if (!init || !parent) return null
    return document.createElement('div')
  }, [init, parent])

  useEffect(() => {
    if (!el || !parent || !init) return

    if (displayViewer) {
      parent.appendChild(el)
    }
    return () => {
      if (el.parentElement) el.parentElement.removeChild(el)
    }
  }, [init, el, parent, displayViewer, productId])

  // createPortal(<ProductViewer productId={productId} variationIndex={variationIndex} />, el)
  return <div></div>
}

const mapStateToProps = (state: IRootState) => state.productViewerState

export default connect(mapStateToProps)(ProductViewerContainer)
