import { Button, Theme } from '@addsome/ui-kit'
import { Icon } from 'antd'
import React from 'react'
import {FormattedMessage} from 'react-intl'

interface IProps {
  loading: boolean
  children?: React.ReactNode
}

const UploadButton = (props: IProps) => (
  <Button theme={Theme.GHOST} disabled={props.loading} block uppercase>
    {props.children || <span><FormattedMessage id='import.upload' /></span>}
    {props.loading && <Icon type="loading" />}
  </Button>
)

export default UploadButton
