import { packshot as packshotActions } from '@addsome/redux-store'
import { IWebRenderSettingCreation } from '@addsome/redux-store/dist/store/packshot'
import { Button, InputField, Option, Select, Size, Theme } from '@addsome/ui-kit'
import { Form } from 'antd'
import { goBack, push } from 'connected-react-router'
import { Formik } from 'formik'
import React from 'react'
import { WrappedComponentProps, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import * as yup from 'yup'
import { IRootState } from '../../../../redux'
import styles from './WebForm.module.scss'

export interface IWebInitialValues {
  type: 'web' | 'print'
  units: string
  width: string
  height: string
  fileType: string
  colorMode: string
  pixelizationEffect: string
}

type IProps = ReturnType<typeof mapDispatchToProps> &
  WrappedComponentProps & {
    projectId: string
    initialValues: IWebInitialValues
  }

class WebForm extends React.Component<IProps> {
  private webValidationSchema = yup.object().shape({
    units: yup.string().required(),
    width: yup.string().required(),
    height: yup.string().required(),
    fileType: yup.string().required(),
    colorMode: yup.string().required(),
    pixelizationEffect: yup.string().required()
  })

  public render() {
    const { goBackRouter, intl, initialValues } = this.props

    return (
      <Formik
        initialValues={initialValues}
        validationSchema={this.webValidationSchema}
        onSubmit={this.submit}
      >
        {formik => (
          <Form onSubmit={formik.handleSubmit} style={{ height: '100%' }}>
            <div className={styles.container}>
              <div className={styles.form}>
                <Form.Item label={intl.formatMessage({ id: 'packshot.settings.units' })}>
                  <InputField
                    name="units"
                    error={formik.touched.width ? formik.errors.width : undefined}
                    required
                    onChange={e => formik.setFieldValue('units', e.currentTarget.value)}
                    suppressContentEditableWarning
                  >
                    <Select defaultValue={initialValues.units} onChange={formik.handleChange}>
                      <Option value={initialValues.units}>Pixels</Option>
                    </Select>
                  </InputField>
                </Form.Item>
                <div className={styles.row}>
                  <div className={styles.rowItem}>
                    <Form.Item label={intl.formatMessage({ id: 'global.width' })}>
                      <InputField
                        name="width"
                        placeholder={intl.formatMessage({ id: 'global.width' })}
                        defaultValue={initialValues.width}
                        error={formik.touched.width ? formik.errors.width : undefined}
                        required
                        onChange={e => formik.setFieldValue('width', e.currentTarget.value)}
                        type="number"
                        suppressContentEditableWarning
                      />
                    </Form.Item>
                  </div>
                  <div className={styles.rowItem}>
                    <Form.Item label={intl.formatMessage({ id: 'global.height' })}>
                      <InputField
                        name="height"
                        placeholder={intl.formatMessage({ id: 'global.height' })}
                        defaultValue={initialValues.height}
                        error={formik.touched.width ? formik.errors.height : undefined}
                        required
                        onChange={e => formik.setFieldValue('height', e.currentTarget.value)}
                        type="number"
                        suppressContentEditableWarning
                      />
                    </Form.Item>
                  </div>
                </div>
                <Form.Item label={intl.formatMessage({ id: 'packshot.settings.fileType' })}>
                  <InputField
                    name="fileType"
                    placeholder={intl.formatMessage({ id: 'packshot.settings.fileType' })}
                    defaultValue={initialValues.fileType}
                    error={formik.touched.fileType ? formik.errors.fileType : undefined}
                    required
                    onChange={e => formik.setFieldValue('fileType', e.currentTarget.value)}
                    type="text"
                    suppressContentEditableWarning
                    disabled
                  />
                </Form.Item>
                <Form.Item label={intl.formatMessage({ id: 'packshot.settings.colorMode' })}>
                  <InputField
                    name="colorMode"
                    placeholder={intl.formatMessage({ id: 'packshot.settings.colorMode' })}
                    defaultValue={initialValues.colorMode}
                    error={formik.touched.fileType ? formik.errors.colorMode : undefined}
                    required
                    onChange={e => formik.setFieldValue('fileType', e.currentTarget.value)}
                    type="text"
                    suppressContentEditableWarning
                    disabled
                  />
                </Form.Item>
                <Form.Item
                  label={intl.formatMessage({ id: 'packshot.settings.pixelizationEffect' })}
                >
                  <InputField
                    name={intl.formatMessage({ id: 'packshot.settings.pixelizationEffect' })}
                    placeholder={intl.formatMessage({
                      id: 'packshot.settings.pixelizationEffect'
                    })}
                    defaultValue={initialValues.pixelizationEffect}
                    error={
                      formik.touched.pixelizationEffect
                        ? formik.errors.pixelizationEffect
                        : undefined
                    }
                    required
                    onChange={formik.handleChange}
                    type="text"
                    suppressContentEditableWarning
                    disabled
                  />
                </Form.Item>
              </div>
              <div className={styles.buttons}>
                <Button
                  theme={Theme.DEFAULT}
                  size={Size.LARGE}
                  uppercase
                  block
                  onClick={goBackRouter}
                  className={styles.button}
                >
                  {intl.formatMessage({ id: 'misc.cancel' })}
                </Button>
                <Button
                  theme={Theme.BLACK}
                  size={Size.LARGE}
                  uppercase
                  block
                  htmlType="submit"
                  className={styles.button}
                >
                  {intl.formatMessage({ id: 'misc.confirm' })}
                </Button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    )
  }

  private submit = ({ units, pixelizationEffect, ...rest }: IWebInitialValues) => {
    const { pushRouter, projectId, setRenderSettings } = this.props

    const renderSettings: IWebRenderSettingCreation = {
      ...rest,
      unit: units,
      pixelEffect: pixelizationEffect
    }

    setRenderSettings(renderSettings)

    pushRouter(`/projects/${projectId}/visuals/new/summary`)
  }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<IRootState, {}, AnyAction>) => ({
  setRenderSettings: (renderSettings: IWebRenderSettingCreation) =>
    dispatch(packshotActions.setRenderSettings(renderSettings)),
  pushRouter: (location: string) => dispatch(push(location)),
  goBackRouter: () => dispatch(goBack())
})

export default connect(null, mapDispatchToProps)(injectIntl(WebForm))
