import * as Sentry from '@sentry/browser'
import { AccountType } from '@addsome/dtos/dist'
import { IUserState } from '@addsome/redux-store/dist/store/user'

export const setSentryUserInfo = (user: IUserState['user']) => {
  if (!user) return
  Sentry.configureScope(scope => {
    scope.setUser({
      email: user.account.email,
      id: user.id,
      accountId: user.account.id,
      accountType: AccountType.BrandUser
    })
  })
}
