import { push } from 'connected-react-router'
import React from 'react'
import { WrappedComponentProps, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'

import studio3dLogo from '../../../../assets/images/logos/addsome/studio3d.svg'
import { IRootState } from '../../../../redux'
import BlueButton from '../../../Common/Elements/BlueButton'
import styles from './EmptyCategory.module.scss'

interface IMatchParams {
  id: string
}

type IProps = ReturnType<typeof mapDispatchToProps> &
  WrappedComponentProps &
  RouteComponentProps<IMatchParams>

const EmptyCategory: React.FC<IProps> = ({
  intl,
  pushRouter,
  match: {
    params: { id }
  }
}) => {
  return (
    <div className={styles.container}>
      <img className={styles.studioLogo} src={studio3dLogo} />
      <div className={styles.description}>
        {intl.formatMessage({ id: 'project.newVisualDescription' })}
      </div>
      <div className={styles.button}>
        <BlueButton
          labelIntlId={'project.newVisualCreation'}
          onClick={() => {
            pushRouter(`/projects/${id}/visuals/new`)
          }}
        />
      </div>
    </div>
  )
}

const mapDispatchToProps = (dispatch: ThunkDispatch<IRootState, {}, AnyAction>) => ({
  pushRouter: (location: string) => dispatch(push(location))
})

export default connect(null, mapDispatchToProps)(injectIntl(withRouter(EmptyCategory)))
