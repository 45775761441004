import {
  FiltersContainer,
  FilterType,
  IFilter,
  IFilterButton,
  ObjectFilter,
  Range,
  RoomFilter,
  StyleFilter
} from '@addsome/ui-kit'
import React, { FC, useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { connect } from 'react-redux'
import { ObjectIcon, RoomIcon, StyleIcon } from '../../../assets/images/icons/pack'
import { IRootState } from '../../../redux'
import styles from './Filters.module.scss'

type IProps = ReturnType<typeof mapStateToProps> & {
  onUpdateFilters: (filters: { [key: string]: string[] }) => void
}

const Filters: FC<IProps> = ({ onUpdateFilters, filterData, loading }) => {
  const [filterButtons, setFilterButtons] = useState<IFilterButton[]>([])
  const [visibleButtonsIds, setVisibleButtonsIds] = useState<number[]>([])
  const [filters, setFilters] = useState<IFilter[]>([])
  const intl = useIntl()
  const [fetchFinished, setFetchFinished] = useState(false)
  const [wasFiltersPopulate, setWasFiltersPopulate] = useState(false)

  useEffect(() => {
    setFilterButtons([
      {
        id: 2,
        type: FilterType.OBJECT,
        icon: ObjectIcon,
        label: intl.formatMessage({ id: 'filters.object' }),
        content: (
          <ObjectFilter
            onValidate={(label, value) => {
              addFilter({ type: FilterType.OBJECT, label, value })
              setVisibleButtonsIds(visibleButtonsIds.filter(id => id !== 2))
            }}
            objectProductCategories={filterData.objectProductCategories}
          />
        )
      },
      {
        id: 4,
        type: FilterType.STYLE,
        icon: StyleIcon,
        label: intl.formatMessage({ id: 'filters.style' }),
        content: (
          <StyleFilter
            onValidate={(label, value) => {
              addFilter({ type: FilterType.STYLE, label, value })
              setVisibleButtonsIds(visibleButtonsIds.filter(id => id !== 4))
            }}
            loading={loading.styleTag}
            styleTags={filterData.styleTags}
          />
        )
      },
      // TODO: Uncomment when Addsome will be ready
      // {
      //   id: 5,
      //   type: FilterType.MATERIAL,
      //   icon: MaterialIcon,
      //   label: intl.formatMessage({ id: 'filters.material' }),
      //   content: (
      //     <MaterialsFilter
      //       onValidate={(label, value) => {
      //         addFilter({ type: FilterType.MATERIAL, label, value })
      //         setVisibleButtonsIds(visibleButtonsIds.filter(id => id !== 5))
      //       }}
      //     />
      //   )
      // },
      {
        id: 6,
        type: FilterType.ROOM,
        icon: RoomIcon,
        label: intl.formatMessage({ id: 'filters.room' }),
        content: (
          <RoomFilter
            onValidate={(label, value) => {
              addFilter({ type: FilterType.ROOM, label, value })
              setVisibleButtonsIds(visibleButtonsIds.filter(id => id !== 6))
            }}
            loading={loading.roomTag}
            roomTags={filterData.roomTags}
          />
        )
      }
      // TODO: Uncomment when Addsome will be ready
      // {
      //   id: 7,
      //   type: FilterType.COLOR,
      //   icon: ColorIcon,
      //   label: intl.formatMessage({ id: 'filters.color' }),
      //   content: (
      //     <ColorFilter
      //       onValidate={(label, value) => {
      //         addFilter({ type: FilterType.COLOR, label, value })
      //         setVisibleButtonsIds(visibleButtonsIds.filter(id => id !== 7))
      //       }}
      //     />
      //   )
      // },
    ])
    onUpdateFilters(translateFilters(filters))
  }, [fetchFinished])

  if (filterData && filterData.objectProductCategories.length > 0 && !wasFiltersPopulate) {
    setWasFiltersPopulate(true)
    setFetchFinished(true)
  }

  const addFilter = (v: IFilter) => {
    if (filters.some(f => f.type === v.type && f.value === v.value)) return
    setFilters([
      // We filter to only have one filter with the same value
      // (and only one price filter)
      ...filters.filter(
        filter =>
          filter.type !== v.type || (filter.value !== v.value && v.type !== FilterType.PRICE)
      ),
      v
    ])
  }

  const translateFilters = (catalogFilters: IFilter[]) => {
    let objectFilters: { [key: string]: string[] } = {}
    catalogFilters.forEach(filter => {
      switch (filter.type) {
        case FilterType.PRICE:
          const value = filter.value as Range
          objectFilters = {
            ...objectFilters,
            priceMin: [value[0].toString()],
            priceMax: [value[1].toString()]
          }
          return
        case FilterType.STYLE:
          objectFilters = {
            ...objectFilters,
            styleTagIds:
              'styleTagIds' in objectFilters
                ? [...objectFilters.styleTagIds, filter.value as string]
                : [filter.value as string]
          }
          return
        case FilterType.ROOM:
          objectFilters = {
            ...objectFilters,
            roomTagIds:
              'roomTagIds' in objectFilters
                ? [...objectFilters.roomTagIds, filter.value as string]
                : [filter.value as string]
          }
          return
        case FilterType.COLOR:
          objectFilters = {
            ...objectFilters,
            colorTagIds:
              'colorTagIds' in objectFilters
                ? [...objectFilters.colorTagIds, filter.value as string]
                : [filter.value as string]
          }
          return
        case FilterType.MATERIAL:
          objectFilters = {
            ...objectFilters,
            materialTagIds:
              'materialTagIds' in objectFilters
                ? [...objectFilters.materialTagIds, filter.value as string]
                : [filter.value as string]
          }
          return
        case FilterType.ORIGIN:
          objectFilters = {
            ...objectFilters,
            madeIn:
              'madeIn' in objectFilters
                ? [...objectFilters.madeIn, filter.value as string]
                : [filter.value as string]
          }
          return
        case FilterType.OBJECT:
          objectFilters = {
            ...objectFilters,
            objectProductCategoryIds:
              'objectProductCategoryIds' in objectFilters
                ? [...objectFilters.objectProductCategoryIds, filter.value as string]
                : [filter.value as string]
          }
          return
        default:
          return
      }
    })
    return objectFilters
  }

  return (
    <FiltersContainer
      className={styles.filtersContainer}
      reinitLabel={<FormattedMessage id="filters.reinit" />}
      filterButtons={filterButtons}
      filters={filters}
      visibleButtonsIds={visibleButtonsIds}
      onUpdatePopoverVisible={(id, visible) =>
        setVisibleButtonsIds(
          visible ? [...visibleButtonsIds, id] : visibleButtonsIds.filter(bId => id !== bId)
        )
      }
      onCloseFilter={filter => setFilters(filters.filter(f => f !== filter))}
      onResetFilters={() => setFilters([])}
    />
  )
}

const mapStateToProps = (state: IRootState) => ({
  prices: state.productPricesState.prices,
  filterData: {
    objectProductCategories: state.objectProductCategoriesState.values,
    roomTags: state.roomTagState.values,
    styleTags: state.styleTagState.values,
    productMaterialTags: state.productMaterialTagState.values,
    colorTags: state.colorTagState.values,
    lexique: state.brandTagState.brandLexique
  },
  loading: {
    objectProductCategories: state.objectProductCategoriesState.loading,
    roomTag: state.roomTagState.loading,
    styleTag: state.styleTagState.loading,
    productMaterialTag: state.productMaterialTagState.loading,
    colorTag: state.colorTagState.loading,
    brandTag: state.brandTagState.brandLexiqueLoading
  }
})

export default connect(mapStateToProps)(Filters)
