import { product as productActions, project as projectActions } from '@addsome/redux-store'
import React, { FC, useEffect } from 'react'
import { WrappedComponentProps, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router-dom'
import { AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import Layout from '../../../components/Common/Layout'
import ProductSheet from '../../../components/Products/ProductSheet'
import { SheetTypes } from '../../../components/Products/ProductSheet/ProductSheet'
import { IRootState } from '../../../redux'
import { convertProductForSheet } from '../../../utils/convertProduct'
import styles from './Product.module.scss'

interface IMatchParams {
  projectId: string
  productId: string
}

type IProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  WrappedComponentProps &
  RouteComponentProps<IMatchParams>

const Product: FC<IProps> = ({
  intl,
  fetchProject,
  fetchProduct,
  product,
  project,
  loading,
  match: {
    params: { projectId, productId }
  }
}) => {
  useEffect(() => {
    if (!project || project.id !== projectId) {
      fetchProject(projectId)
    }
  }, [project, projectId, fetchProject])

  useEffect(() => {
    if (!product || product.id !== productId) {
      fetchProduct(productId)
    }
  }, [product, productId, fetchProduct])

  return (
    <Layout
      className={styles.layout}
      breadcrumbRoutes={[
        {
          breadcrumbName: intl.formatMessage({ id: 'breadcrumb.home' }),
          path: '/'
        },
        {
          breadcrumbName: project ? project.name : '',
          path: `/projects/${projectId}`
        },
        {
          breadcrumbName: intl.formatMessage({ id: 'project.products' }),
          path: `/projects/${projectId}/products`
        },
        {
          breadcrumbName: product ? product.name : '',
          path: `/projects/${projectId}/product/${productId}`
        }
      ]}
    >
      <ProductSheet
        loading={loading || !product}
        products={product ? [convertProductForSheet(product)] : undefined}
        type={SheetTypes.SHEET}
        projectId={(project && project.id) || ''}
      />
    </Layout>
  )
}

const mapStateToProps = (state: IRootState) => ({
  project: state.projectState.value,
  product: state.productState.value,
  loading: state.productState.loading
})

const mapDispatchToProps = (dispatch: ThunkDispatch<IRootState, {}, AnyAction>) => ({
  fetchProduct: (productId: string) => dispatch(productActions.thunks.fetchValue(productId)),
  fetchProject: (id: string) => dispatch(projectActions.thunks.fetchValue(id))
})

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Product))
