import { AccountType, LoginDTO, Apps } from '@addsome/dtos'
import { auth } from '@addsome/redux-store'
import { InputField, InvisibleButton } from '@addsome/ui-kit'
import { Form, Icon } from 'antd'
import { push } from 'connected-react-router'
import { Formik } from 'formik'
import React from 'react'
import { WrappedComponentProps, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import * as yup from 'yup'
import { IRootState } from '../../../redux'
import BlueButton from '../../Common/Elements/BlueButton'
import styles from './Form.module.scss'
import { setSentryUserInfo } from '../../../utils/sentry'

const initialValues: LoginDTO = {
  email: '',
  password: '',
  source: Apps.Studio
}

type IProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  WrappedComponentProps

interface IState {
  showInvalidCredentials: boolean
  showPassword: boolean
}

class LoginForm extends React.PureComponent<IProps, IState> {
  public state: IState = { showInvalidCredentials: false, showPassword: false }

  public validationSchema = yup.object().shape({
    email: yup
      .string()
      .required()
      .email(),
    password: yup
      .string()
      .required()
      .min(5)
  })

  public render() {
    const { loading, intl } = this.props
    const { showInvalidCredentials, showPassword } = this.state

    return (
      <Formik
        initialValues={initialValues}
        validationSchema={this.validationSchema}
        onSubmit={this.handleSubmit}
      >
        {formik => (
          <Form onSubmit={formik.handleSubmit} className={styles.form}>
            <Form.Item label={intl.formatMessage({ id: 'login.email' })}>
              <InputField
                name="email"
                placeholder={intl.formatMessage({ id: 'login.email' })}
                defaultValue={initialValues.email}
                error={formik.touched.email ? formik.errors.email : undefined}
                required
                onChange={formik.handleChange}
                type="email"
                suppressContentEditableWarning
              />
            </Form.Item>
            <Form.Item label={intl.formatMessage({ id: 'login.password' })}>
              <InputField
                name="password"
                placeholder={intl.formatMessage({ id: 'login.password' })}
                defaultValue={initialValues.password}
                error={formik.touched.password ? formik.errors.password : undefined}
                required
                onChange={formik.handleChange}
                allowClear={true}
                type={showPassword ? 'text' : 'password'}
                addonAfter={
                  <InvisibleButton
                    onClick={() => this.setState({ showPassword: !showPassword })}
                    aria-label={intl.formatMessage({ id: 'login.passwordDisplay' })}
                    type="button"
                  >
                    <Icon type={showPassword ? 'eye-invisible' : 'eye'} />
                  </InvisibleButton>
                }
              />
            </Form.Item>
            <div className={styles.forgotContainer}>
              <Link to="/forgotten-password" className={styles.forgotPassword}>
                {intl.formatMessage({ id: 'login.forgotPassword' })}
              </Link>
            </div>
            {showInvalidCredentials ? (
              <div style={{ color: '#FF8C64' }}>
                <br />
                {intl.formatMessage({ id: 'error.credentials' })}
              </div>
            ) : (
              ''
            )}
            <BlueButton labelIntlId={'login.login'} loading={loading} />
          </Form>
        )}
      </Formik>
    )
  }

  private handleSubmit = (loginCredentials: LoginDTO) => {
    this.props
      .login(loginCredentials.email, loginCredentials.password)
      .then(() => {
        if (this.props.accountType !== AccountType.BrandUser) {
          throw new Error('Invalid account type')
        }

        const { user } = this.props
        setSentryUserInfo(user)
        this.props.push('/')
      })
      .catch(() => {
        this.setState({
          showInvalidCredentials: true
        })
      })
  }
}

const mapStateToProps = (state: IRootState) => ({
  loading: state.authState.loading,
  user: state.userState.user,
  accountType: state.authState.type
})

const mapDispatchToProps = (dispatch: ThunkDispatch<IRootState, {}, AnyAction>) => ({
  login: (email: string, password: string) => dispatch(auth.login(email, password, Apps.Studio)),
  push: (location: string) => dispatch(push(location))
})

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(LoginForm))
