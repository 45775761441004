import React, { FC, useEffect } from 'react'
import { WrappedComponentProps, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router-dom'
import { AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'

import { project as projectActions } from '@addsome/redux-store'
import { SheetTypes } from '../../../../components/Products/ProductSheet/ProductSheet'
import { IRootState } from '../../../../redux'

import Layout from '../../../../components/Common/Layout'
import ProductSheet from '../../../../components/Products/ProductSheet'

import styles from './Batch.module.scss'

interface IMatchParams {
  id: string
}

type IProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  WrappedComponentProps &
  RouteComponentProps<IMatchParams>

const ProductPage: FC<IProps> = ({
  intl,
  fetchProject,
  project,
  selectedProducts,
  match: {
    params: { id }
  }
}) => {
  useEffect(() => {
    if (!project || project.id !== id) {
      fetchProject(id)
    }
  }, [id, fetchProject])

  return (
    <Layout
      className={styles.layout}
      breadcrumbRoutes={[
        {
          breadcrumbName: intl.formatMessage({ id: 'breadcrumb.home' }),
          path: '/'
        },
        {
          breadcrumbName: project ? project.name : '',
          path: `/projects/${id}/products`
        },
        {
          breadcrumbName: intl.formatMessage({ id: 'visual.new.breadcrumbName' }),
          path: `/projects/${id}/visuals/new`
        },
        {
          breadcrumbName: intl.formatMessage({ id: 'global.configuration' }),
          path: `/projects/${id}/visuals/new/batch`
        }
      ]}
    >
      <ProductSheet products={selectedProducts} type={SheetTypes.BATCH} />
    </Layout>
  )
}

const mapStateToProps = (state: IRootState) => ({
  project: state.projectState.value,
  selectedProducts: state.packshotState.selectedProducts
})

const mapDispatchToProps = (dispatch: ThunkDispatch<IRootState, {}, AnyAction>) => ({
  fetchProject: (id: string) => dispatch(projectActions.thunks.fetchValue(id))
})

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ProductPage))
