import {
  BrandProjectSceneTemplateCreationDTO,
  BrandUserWithBrandsDTO,
  ProjectSceneUpdateDTO,
  RenderCreationDTO
} from '@addsome/dtos'
import {
  brandProjectSceneTemplate,
  IPaginationRequest,
  render as renderActions,
  scene as sceneAction
} from '@addsome/redux-store'
import { push } from 'connected-react-router'
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router-dom'
import { AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import SceneEditor from '../../components/Player/SceneEditor/SceneEditor'
import { IRootState } from '../../redux'
import styles from './ScenePage.module.scss'
import {WrappedComponentProps} from 'react-intl'

interface IMatchParams {
  projectId: string
  sceneId: string
}

type IProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  RouteComponentProps<IMatchParams> & WrappedComponentProps

const ScenePage: React.FC<IProps> = ({
  scene,
  match,
  fetchScene,
  updateScene,
  user,
  createBrandProjectSceneTemplate,
  createRender,
  token,
  pushRouter,
  products,
  intl
}) => {
  useEffect(() => {
    fetchScene(match.params.sceneId, intl.formatMessage({id: 'scenes.errorRetrievingTheScene'}))
  }, [match.params.sceneId, match.params.projectId])

  if (!scene || !user || !token) {
    return <div className={styles.container}>Loading...</div>
  }
  return (
    <div className={styles.container}>
      <SceneEditor
        token={token}
        scene={scene}
        projectId={match.params.projectId}
        onUpdateScene={updatedScene => updateScene(scene.id, updatedScene, intl.formatMessage({id:'scenes.updatedScene'}), intl.formatMessage({id:'scenes.errorEditingScene'}))}
        onSaveTemplate={updatedScene => {
          if (
            user &&
            (user as BrandUserWithBrandsDTO).brands.length > 0 &&
            (user as BrandUserWithBrandsDTO).brands[0].id
          ) {
            createBrandProjectSceneTemplate({
              brandId: (user as BrandUserWithBrandsDTO).brands[0].id,
              name: updatedScene.name || '',
              description: updatedScene.description || '',
              pictureId: updatedScene.pictureId,
              content: updatedScene.version && updatedScene.version.content,
              camera: updatedScene.version && updatedScene.version.content
            }, intl.formatMessage({id:'brandProjectScene.addedTemplate'}),
               intl.formatMessage({id: 'brandProjectScene.errorCreatingtTemplate'}))
            updateScene(scene.id, updatedScene, intl.formatMessage({id:'scenes.updatedScene'}), intl.formatMessage({id:'scenes.errorEditingScene'}))
          }
        }}
        productIds={products.length > 0 ? products.map(product => product.id) : undefined}
        onCreateRender={render => createRender(match.params.projectId, scene.id, render, intl.formatMessage({id: 'renders.renderAdded'}),
          intl.formatMessage({id:'renders.errorCreatingRendering'}), intl.formatMessage({id: 'renders.errorRetrievingRenderings'}))}
        onRedirect={pushRouter}
      />
    </div>
  )
}

const mapStateToProps = (state: IRootState) => ({
  scene: state.sceneState.scene,
  user: state.userState.user,
  token: state.authState.token,
  products: state.packshotState.selectedProducts
})

const mapDispatchToProps = (dispatch: ThunkDispatch<IRootState, {}, AnyAction>) => ({
  fetchScene: (sceneId: string, errorRetrievingTheScene?: string) => dispatch(sceneAction.fetchScene(sceneId, errorRetrievingTheScene)),
  updateScene: (sceneId: string, scene: ProjectSceneUpdateDTO, updatedScene?:string, errorEditingScene?:string) =>
    dispatch(sceneAction.patchScene(sceneId, scene,updatedScene, errorEditingScene)),
  createBrandProjectSceneTemplate: (template: BrandProjectSceneTemplateCreationDTO, addedTemplate?: string, errorCreatingTemplate?: string) =>
    dispatch(brandProjectSceneTemplate.createBrandProjectSceneTemplate(template, addedTemplate, errorCreatingTemplate)),
  createRender: (projectId: string, projectSceneId: string, payload: RenderCreationDTO, renderAdded?:string, errorCreatingRendering?:string, errorRetrievingRenderings?: string, request?: IPaginationRequest) =>
    dispatch(renderActions.createRenderFromProjectScene(projectId, projectSceneId, payload, renderAdded, errorCreatingRendering, errorRetrievingRenderings, request)),
  pushRouter: (url: string) => dispatch(push(url))
})

export default connect(mapStateToProps, mapDispatchToProps)(ScenePage)
