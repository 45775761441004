import React, { FC, SVGProps } from 'react'

export const Addsome: FC<SVGProps<SVGSVGElement>> = props => (
  <svg width="32px" height="32px" viewBox="0 0 32 32" version="1.1" {...props}>
    <title>ADC0E433-08BD-48AE-86E7-633840BD3B4F</title>
    <desc>Created with sketchtool.</desc>
    <g id="Desktop" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="Menu"
        transform="translate(-44.000000, -948.000000)"
        fill={props.color}
        fillRule="nonzero"
      >
        <g id="Group-6-Copy" transform="translate(24.000000, 928.000000)">
          <g id="Group-2" transform="translate(8.000000, 8.000000)">
            <path
              // tslint:disable-next-line
              d="M29.4744027,36.6133316 L29.4744027,44 L20.7372014,44 C15.9116675,44 12,40.0883325 12,35.2627986 C12,30.9397708 15.1394044,27.3501887 19.2627986,26.6494671 L19.2627986,19.2627986 L26.5255973,19.2627986 L26.5255973,12 L35.2627986,12 C40.0883325,12 44,15.9116675 44,20.7372014 C44,25.097357 40.8064392,28.7114146 36.6307452,29.3679465 C36.0498782,33.0623937 33.1539095,35.9880433 29.4744027,36.6133316 Z M29.4744027,33.598954 C31.9571715,32.9469695 33.7883959,30.6874249 33.7883959,28 C33.7883959,24.8030465 31.1969535,22.2116041 28,22.2116041 L22.2116041,22.2116041 L22.2116041,26.5255973 L29.4744027,26.5255973 L29.4744027,33.598954 Z M29.4744027,19.3866684 C33.0676963,19.9973058 35.9137393,22.8017696 36.5861217,26.3736498 C39.1453229,25.7751838 41.0511945,23.4788537 41.0511945,20.7372014 C41.0511945,17.5402478 38.4597522,14.9488055 35.2627986,14.9488055 L29.4744027,14.9488055 L29.4744027,19.3866684 Z M26.5255973,41.0511945 L26.5255973,29.4744027 L20.7372014,29.4744027 C17.5402478,29.4744027 14.9488055,32.0658451 14.9488055,35.2627986 C14.9488055,38.4597522 17.5402478,41.0511945 20.7372014,41.0511945 L26.5255973,41.0511945 Z"
              id="ADD-Copy"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export const AddCircle: FC<SVGProps<SVGSVGElement>> = props => (
  <svg width="48px" height="48px" viewBox="0 0 48 48" version="1.1" {...props}>
    <title>B4B2D59F-7089-409B-A9EE-3B503E6B25D9</title>
    <desc>Created with sketchtool.</desc>
    <defs>
      <polygon
        id="path-1"
        points="32 23 25 23 25 16 23 16 23 23 16 23 16 25 23 25 23 32 25 32 25 25 32 25"
      />
    </defs>
    <g id="V2" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Projects" transform="translate(-1240.000000, -118.000000)">
        <g id="Group-3" transform="translate(1240.000000, 118.000000)">
          <g id="Icon-add-photo-mobile">
            <circle id="Oval" fill="#151515" cx="24" cy="24" r="24" />
            <mask id="mask-2" fill="white">
              <use href="#path-1" />
            </mask>
            <use id="Plus" fill={props.color} href="#path-1" />
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export const Calibrate: FC<SVGProps<SVGSVGElement>> = props => (
  <svg width="18px" height="21px" viewBox="0 0 18 21" version="1.1" {...props}>
    <g id="Desktop" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Menu" transform="translate(-431.000000, -944.000000)">
        <g id="Group-6-Copy" transform="translate(24.000000, 928.000000)">
          <g id="Group-9" transform="translate(300.000000, 8.000000)">
            <g id="Group-16" transform="translate(80.000000, 0.000000)">
              <g id="Calibration" transform="translate(24.000000, 6.000000)">
                <rect id="Rectangle" fillRule="nonzero" x="0" y="0" width="24" height="24" />
                <circle id="Oval" stroke={props.color} strokeWidth="2" cx="12" cy="9" r="3" />
                <path
                  // tslint:disable-next-line
                  d="M5.06475636,12.9904749 C6.44663322,15.3869322 9.03494053,17 12,17 L12,17 C14.9693346,17 17.5608298,15.3822773 18.9412134,12.9801042"
                  id="Path"
                  stroke={props.color}
                  strokeWidth="2"
                />
                <path d="M12,6 L12,2" id="Line" stroke={props.color} strokeWidth="2" />
                <path d="M4,22 L10,11" id="Line" stroke={props.color} strokeWidth="2" />
                <path d="M20,22 L14,11" id="Line" stroke={props.color} strokeWidth="2" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export const Catalog: FC<SVGProps<SVGSVGElement>> = props => (
  <svg width="18px" height="18px" viewBox="0 0 18 18" version="1.1" {...props}>
    <g id="Desktop" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Menu" transform="translate(-351.000000, -945.000000)">
        <g id="Group-6-Copy" transform="translate(24.000000, 928.000000)">
          <g id="Group-9" transform="translate(300.000000, 8.000000)">
            <g id="Group-12">
              <g id="Catalogue" transform="translate(24.000000, 6.000000)">
                <rect id="Rectangle" fillRule="nonzero" x="0" y="0" width="24" height="24" />
                <path
                  d="M4,17.5 C4,16.1192881 5.11928813,15 6.5,15 L20,15"
                  id="Path"
                  stroke={props.color}
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  // tslint:disable-next-line
                  d="M6.5,4 L20,4 L20,20 L6.5,20 C5.11928813,20 4,18.8807119 4,17.5 L4,6.5 C4,5.11928813 5.11928813,4 6.5,4 Z"
                  id="Path"
                  stroke={props.color}
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export const Compass: FC<SVGProps<SVGSVGElement>> = props => (
  <svg width="84px" height="84px" viewBox="0 0 84 84" version="1.1" {...props}>
    <g id="V2" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Welcome" transform="translate(-678.000000, -346.000000)">
        <g id="Group" transform="translate(540.000000, 328.000000)">
          <g id="Création" transform="translate(120.000000, 0.000000)">
            <polygon id="Fill-1" points="0 120 120 120 120 0 0 0" />
            <g id="Page-1" transform="translate(20.000000, 20.000000)" strokeWidth="2.5">
              <polyline
                id="Stroke-1"
                stroke="#222829"
                points="37.2727273 7.27272727 37.2727273 0 42.7272727 0 42.7272727 7.27272727"
              />
              <polyline
                id="Stroke-3"
                stroke="#222829"
                strokeLinejoin="round"
                // tslint:disable-next-line
                points="29.0909091 25.3071253 18.1818182 50.8599509 18.1818182 63.6363636 40 16.3636364 61.8181818 63.6363636 61.8181818 50.8599509 50.9090909 25.3071253"
              />
              <path
                d="M29.0909091,40.9090909 L50.9090909,40.9090909"
                id="Stroke-11"
                stroke="#222829"
              />
              <path
                // tslint:disable-next-line
                d="M43.8961039,25.4545455 L46.4935065,22.8571429 C48.1,21.2168831 49.0909091,18.8402597 49.0909091,16.3636364 C49.0909091,11.3428571 45.0207792,7.27272727 40,7.27272727 C34.9792208,7.27272727 30.9090909,11.3428571 30.9090909,16.3636364 C30.9090909,18.8415584 31.8987013,21.2168831 33.5064935,22.8571429 L36.1038961,25.4545455"
                id="Stroke-12"
                stroke="#222829"
              />
              <path
                // tslint:disable-next-line
                d="M68.9960681,53.70769 C75.8150399,56.5001844 80,60.2759983 80,64.4314826 C80,73.0291963 62.0916129,80 40,80 C17.9083871,80 0,73.0291963 0,64.4314826 C0,60.2405172 4.25586967,56.4351943 11.1768909,53.6363636"
                id="Stroke-14"
                stroke="#36A8B3"
              />
              <path
                // tslint:disable-next-line
                d="M43.6363636,40.9090909 C43.6363636,42.9163636 42.0072727,44.5454545 40,44.5454545 C37.9927273,44.5454545 36.3636364,42.9163636 36.3636364,40.9090909 C36.3636364,38.9018182 37.9927273,37.2727273 40,37.2727273 C42.0072727,37.2727273 43.6363636,38.9018182 43.6363636,40.9090909 Z"
                id="Stroke-20"
                stroke="#222829"
                fill={props.color}
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export const Cross: FC<SVGProps<SVGSVGElement>> = props => (
  <svg width="14px" height="14px" viewBox="0 0 14 14" version="1.1" {...props}>
    <g id="V2" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Fiche-produit" transform="translate(-1421.000000, -29.000000)">
        <g id="x" transform="translate(1416.000000, 24.000000)">
          <rect id="Rectangle" fillRule="nonzero" x="0" y="0" width="24" height="24" />
          <path
            d="M18,6 L6,18"
            id="Path"
            stroke={props.color}
            strokeWidth="2"
            strokeLinejoin="round"
          />
          <path
            d="M6,6 L18,18"
            id="Path"
            stroke={props.color}
            strokeWidth="2"
            strokeLinejoin="round"
          />
        </g>
      </g>
    </g>
  </svg>
)

export const Height: FC<SVGProps<SVGSVGElement>> = props => (
  <svg width="20px" height="22px" viewBox="0 0 20 22" version="1.1" {...props}>
    <g id="Desktop" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Menu" transform="translate(-510.000000, -943.000000)">
        <g id="Group-6-Copy" transform="translate(24.000000, 928.000000)">
          <g id="Group-9" transform="translate(300.000000, 8.000000)">
            <g id="Group-15" transform="translate(160.000000, 0.000000)">
              <g id="Hauteur" transform="translate(24.000000, 6.000000)">
                <rect id="Rectangle" fillRule="nonzero" x="0" y="0" width="24" height="24" />
                <polyline
                  id="Path"
                  stroke={props.color}
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  // tslint:disable-next-line
                  points="21.0000002 11.0909091 21.0000002 17.4512858 12.0000361 22 3.00007199 17.4512858 3.00007199 11.0909091"
                />
                <polyline
                  id="Path"
                  stroke={props.color}
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  // tslint:disable-next-line
                  points="3.00007199 6.54545455 12.0000361 2 21.0000002 6.54545455 21 6.54214075 12 11.0909091 3 6.54214075"
                />
                <path
                  d="M12,22 L12,14.7272727"
                  id="Path"
                  stroke={props.color}
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export const Measure: FC<SVGProps<SVGSVGElement>> = props => (
  <svg width="24px" height="18px" viewBox="0 0 24 18" version="1.1" {...props}>
    <g id="Desktop" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Menu" transform="translate(-668.000000, -945.000000)" fillRule="nonzero">
        <g id="Group-6-Copy" transform="translate(24.000000, 928.000000)">
          <g id="Group-9" transform="translate(300.000000, 8.000000)">
            <g id="Group-11" transform="translate(320.000000, 0.000000)">
              <g id="Mesurer" transform="translate(24.000000, 6.000000)">
                <rect id="Rectangle" x="0" y="0" width="24" height="24" />
                <path
                  // tslint:disable-next-line
                  d="M17,17 L17,12 C17,7.581722 13.418278,4 9,4 C4.581722,4 1,7.581722 1,12 C1,16.418278 4.581722,20 9,20 L17,20 L23,20 L23,17"
                  id="Path"
                  stroke={props.color}
                  strokeWidth="2"
                />
                <circle id="Oval" fill={props.color} cx="9" cy="12" r="2" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export const MenuUpDown: FC<SVGProps<SVGSVGElement>> = props => (
  <svg width="10px" height="18px" viewBox="0 0 10 18" version="1.1" {...props}>
    <g id="Desktop" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Menu" transform="translate(-103.000000, -955.000000)">
        <g id="Group-6-Copy" transform="translate(24.000000, 928.000000)">
          <g id="Menu-up-down" transform="translate(72.000000, 24.000000)">
            <rect id="Rectangle" x="0" y="0" width="24" height="24" />
            <polyline id="Path" stroke={props.color} strokeWidth="2" points="16 9 12 5 8 9" />
            <polyline
              id="Path"
              stroke={props.color}
              strokeWidth="2"
              // tslint:disable-next-line
              transform="translate(12.000000, 17.000000) scale(1, -1) translate(-12.000000, -17.000000) "
              points="16 19 12 15 8 19"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export const Next: FC<SVGProps<SVGSVGElement>> = props => (
  <svg width="20px" height="19px" viewBox="0 0 20 19" version="1.1" {...props}>
    <g id="Desktop" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Menu" transform="translate(-910.000000, -945.000000)">
        <g id="Group-6-Copy" transform="translate(24.000000, 928.000000)">
          <g id="Group-9" transform="translate(300.000000, 8.000000)">
            <g id="Group-8" transform="translate(560.000000, 0.000000)">
              <g id="Rétablir" transform="translate(24.000000, 6.000000)">
                <rect id="Rectangle" fillRule="nonzero" x="0" y="0" width="24" height="24" />
                <g
                  id="rotate-cw"
                  transform="translate(3.000000, 4.000000)"
                  stroke={props.color}
                  strokeWidth="2"
                >
                  <polyline id="Path" points="18 0.894736842 18 6.26315789 12.6 6.26315789" />
                  <path
                    // tslint:disable-next-line
                    d="M15.741,10.7368421 C14.5391596,14.1187545 11.2322836,16.3027365 7.63057458,16.0932641 C4.02886552,15.8837916 1.00070527,13.3313691 0.20372579,9.83320689 C-0.593253689,6.33504473 1.03310153,2.73455939 4.19251545,1.00267682 C7.35192936,-0.729205749 11.2826587,-0.174923941 13.833,2.36210526 L18,6.26315789"
                    id="Path"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export const Previous: FC<SVGProps<SVGSVGElement>> = props => (
  <svg width="20px" height="19px" viewBox="0 0 20 19" version="1.1" {...props}>
    <g id="Desktop" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Menu" transform="translate(-830.000000, -945.000000)">
        <g id="Group-6-Copy" transform="translate(24.000000, 928.000000)">
          <g id="Group-9" transform="translate(300.000000, 8.000000)">
            <g id="Group-7" transform="translate(480.000000, 0.000000)">
              <g
                id="Annuler"
                // tslint:disable-next-line
                transform="translate(36.000000, 18.000000) scale(-1, 1) translate(-36.000000, -18.000000) translate(24.000000, 6.000000)"
              >
                <rect id="Rectangle" fillRule="nonzero" x="0" y="0" width="24" height="24" />
                <g
                  id="rotate-cw"
                  transform="translate(3.000000, 4.000000)"
                  stroke={props.color}
                  strokeWidth="2"
                >
                  <polyline id="Path" points="18 0.894736842 18 6.26315789 12.6 6.26315789" />
                  <path
                    // tslint:disable-next-line
                    d="M15.741,10.7368421 C14.5391596,14.1187545 11.2322836,16.3027365 7.63057458,16.0932641 C4.02886552,15.8837916 1.00070527,13.3313691 0.20372579,9.83320689 C-0.593253689,6.33504473 1.03310153,2.73455939 4.19251545,1.00267682 C7.35192936,-0.729205749 11.2826587,-0.174923941 13.833,2.36210526 L18,6.26315789"
                    id="Path"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export const Quotation: FC<SVGProps<SVGSVGElement>> = props => (
  <svg width="18px" height="18px" viewBox="0 0 18 18" version="1.1" {...props}>
    <g id="Desktop" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Menu" transform="translate(-991.000000, -945.000000)" fillRule="nonzero">
        <g id="Group-6-Copy" transform="translate(24.000000, 928.000000)">
          <g id="Group-9" transform="translate(300.000000, 8.000000)">
            <g id="Group-13" transform="translate(640.000000, 0.000000)">
              <g id="Devis" transform="translate(24.000000, 6.000000)">
                <g id="Calculette">
                  <rect id="Rectangle" x="0" y="0" width="24" height="24" />
                  <rect
                    id="Rectangle"
                    stroke={props.color}
                    strokeWidth="2"
                    x="4"
                    y="4"
                    width="16"
                    height="16"
                    rx="2"
                  />
                  <rect id="Rectangle" fill={props.color} x="7" y="7" width="10" height="2" />
                  <rect id="Rectangle" fill={props.color} x="7" y="11" width="2" height="2" />
                  <rect
                    id="Rectangle-Copy-4"
                    fill={props.color}
                    x="7"
                    y="15"
                    width="2"
                    height="2"
                  />
                  <rect
                    id="Rectangle-Copy-2"
                    fill={props.color}
                    x="11"
                    y="11"
                    width="2"
                    height="2"
                  />
                  <rect
                    id="Rectangle-Copy-5"
                    fill={props.color}
                    x="11"
                    y="15"
                    width="2"
                    height="2"
                  />
                  <rect
                    id="Rectangle-Copy-3"
                    fill={props.color}
                    x="15"
                    y="11"
                    width="2"
                    height="2"
                  />
                  <rect
                    id="Rectangle-Copy-6"
                    fill={props.color}
                    x="15"
                    y="15"
                    width="2"
                    height="2"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export const Render: FC<SVGProps<SVGSVGElement>> = props => (
  <svg width="22px" height="20px" viewBox="0 0 22 20" version="1.1" {...props}>
    <g id="Desktop" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Menu" transform="translate(-1069.000000, -943.000000)">
        <g id="Group-6-Copy" transform="translate(24.000000, 928.000000)">
          <g id="Group-9" transform="translate(300.000000, 8.000000)">
            <g id="Group-13-Copy" transform="translate(720.000000, 0.000000)">
              <g id="Rendu" transform="translate(24.000000, 6.000000)">
                <g id="Calculette">
                  <rect id="Rectangle" fillRule="nonzero" x="0" y="0" width="24" height="24" />
                  <path
                    // tslint:disable-next-line
                    d="M21.0000001,20 L2.99999991,20 C2.44771516,20 1.99999991,19.5522848 1.99999991,19 L1.99999991,7.0000003 C1.99999991,6.44771555 2.44771516,6.0000003 2.99999991,6.0000003 L21.0000001,6.0000003 C21.5522848,6.0000003 22.0000001,6.44771555 22.0000001,7.0000003 L22.0000001,19 C22.0000001,19.5522848 21.5522848,20 21.0000001,20 Z"
                    id="Path"
                    stroke={props.color}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <rect
                    id="Rectangle"
                    fill={props.color}
                    fillRule="nonzero"
                    x="9"
                    y="1"
                    width="6"
                    height="2"
                  />
                  <path
                    // tslint:disable-next-line
                    d="M14.8284,10.1716 C16.3905,11.7337 16.3905,14.26636 14.8284,15.82845 C13.2663,17.39055 10.73364,17.39055 9.17155,15.82845 C7.60945,14.26635 7.60945,11.73369 9.17155,10.1716 C10.73365,8.6095 13.26631,8.6095 14.8284,10.1716"
                    id="Path"
                    stroke={props.color}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export const RoomArea: FC<SVGProps<SVGSVGElement>> = props => (
  <svg width="58px" height="61px" viewBox="0 0 58 61" version="1.1" {...props}>
    <g id="V2" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Surface" transform="translate(-691.000000, -362.000000)">
        <g id="Group-5" transform="translate(540.000000, 324.000000)">
          <g id="Pop-Up-Window---Choose-Project">
            <g id="Surface" transform="translate(144.000000, 32.000000)">
              <rect id="Rectangle" fillRule="nonzero" x="0" y="0" width="72" height="72" />
              <polygon
                id="Stroke-2"
                fillOpacity="0.152768342"
                fill="#151515"
                points="36 6 12 20 12 48 36 62 60 48 60 20"
              />
              <polygon
                id="Line-2-Copy"
                stroke="#151515"
                strokeWidth="2.42487226"
                fillOpacity="0.150418931"
                fill="#151515"
                strokeLinejoin="round"
                // tslint:disable-next-line
                transform="translate(36.000000, 48.000000) scale(1, -1) translate(-36.000000, -48.000000) "
                points="36 34 12 48 36 62 60 48"
              />
              <circle
                id="Oval"
                stroke="#151515"
                strokeWidth="2"
                fill="FFFFFF"
                fillRule="nonzero"
                cx="36"
                cy="33"
                r="4"
              />
              <circle
                id="Oval-Copy-2"
                stroke="#151515"
                strokeWidth="2"
                fill="FFFFFF"
                fillRule="nonzero"
                cx="60"
                cy="48"
                r="4"
              />
              <circle
                id="Oval-Copy-3"
                stroke="#151515"
                strokeWidth="2"
                fill="FFFFFF"
                fillRule="nonzero"
                cx="12"
                cy="48"
                r="4"
              />
              <circle
                id="Oval-Copy"
                stroke="#151515"
                strokeWidth="2"
                fill="FFFFFF"
                fillRule="nonzero"
                cx="36"
                cy="62"
                r="4"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export const RoomHeight: FC<SVGProps<SVGSVGElement>> = props => (
  <svg width="48px" height="61px" viewBox="0 0 48 61" version="1.1" {...props}>
    <g id="V2" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Height" transform="translate(-696.000000, -362.000000)">
        <g id="Group-5" transform="translate(540.000000, 324.000000)">
          <g id="Pop-Up-Window---Choose-Project">
            <g id="Height" transform="translate(144.000000, 32.000000)">
              <rect id="Rectangle" fillRule="nonzero" x="0" y="0" width="72" height="72" />
              <polygon
                id="Stroke-2"
                fillOpacity="0.152768342"
                fill="#151515"
                points="36 6 12 20 12 48 36 62 60 48 60 20"
              />
              <path d="M36,59 L36,37" id="Line-2-Copy" stroke="#151515" strokeWidth="2.42487226" />
              <circle
                id="Oval"
                stroke="#151515"
                strokeWidth="2"
                fill="#FFFFFF"
                fillRule="nonzero"
                cx="36"
                cy="33"
                r="4"
              />
              <circle
                id="Oval-Copy"
                stroke="#151515"
                strokeWidth="2"
                fill="#FFFFFF"
                fillRule="nonzero"
                cx="36"
                cy="62"
                r="4"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export const TopView: FC<SVGProps<SVGSVGElement>> = props => (
  <svg width="18px" height="18px" viewBox="0 0 18 18" version="1.1" {...props}>
    <g id="Desktop" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Menu" transform="translate(-591.000000, -945.000000)" fillRule="nonzero">
        <g id="Group-6-Copy" transform="translate(24.000000, 928.000000)">
          <g id="Group-9" transform="translate(300.000000, 8.000000)">
            <g id="Group-17" transform="translate(240.000000, 0.000000)">
              <g id="Top-view" transform="translate(24.000000, 6.000000)">
                <rect id="Rectangle" x="0" y="0" width="24" height="24" />
                <path
                  d="M20,20 L20,4 L4,4 L4,20 L8,20 L8,12 C12.418278,12 16,15.581722 16,20 L20,20 Z"
                  id="Path"
                  stroke={props.color}
                  strokeWidth="2"
                  strokeLinejoin="round"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export const Modify: FC<SVGProps<SVGSVGElement>> = props => (
  <svg width="18px" height="18px" viewBox="0 0 18 18" version="1.1" {...props}>
    <g id="Desktop" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Menu" transform="translate(-751.000000, -945.000000)">
        <g id="Group-6-Copy" transform="translate(24.000000, 928.000000)">
          <g id="Group-9" transform="translate(300.000000, 8.000000)">
            <g id="Group-5" transform="translate(400.000000, 0.000000)">
              <g id="Modifier" transform="translate(24.000000, 6.000000)">
                <polygon id="Path" points="0 0 24 0 24 24 0 24" />
                <path
                  // tslint:disable-next-line
                  d="M3,17 L3,19 L9,19 L9,17 L3,17 Z M3,5 L3,7 L13,7 L13,5 L3,5 Z M13,21 L13,19 L21,19 L21,17 L13,17 L13,15 L11,15 L11,21 L13,21 Z M7,9 L7,11 L3,11 L3,13 L7,13 L7,15 L9,15 L9,9 L7,9 Z M21,13 L21,11 L11,11 L11,13 L21,13 Z M15,9 L17,9 L17,7 L21,7 L21,5 L17,5 L17,3 L15,3 L15,9 Z"
                  id="Shape"
                  fill={props.color}
                  fillRule="nonzero"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export const Infos: FC<SVGProps<SVGSVGElement>> = props => (
  <svg width="22px" height="22px" viewBox="0 0 22 22" version="1.1" {...props}>
    <g id="Desktop" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Product-menu" transform="translate(-469.000000, -863.000000)">
        <g id="Group-3" transform="translate(436.000000, 848.000000)">
          <g id="Group-4" transform="translate(30.000000, 14.000000)">
            <g id="Informations" transform="translate(2.000000, 0.000000)">
              <polygon id="Path" points="0 0 24 0 24 24 0 24" />
              <g id="info" transform="translate(2.000000, 2.000000)">
                <circle
                  id="Oval"
                  stroke={props.color}
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  cx="10"
                  cy="10"
                  r="10"
                />
                <path
                  d="M10,14 L10,10"
                  id="Path"
                  stroke={props.color}
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <circle id="Oval" fill={props.color} fillRule="nonzero" cx="10" cy="7" r="1" />
                <path
                  d="M10,6 L10,6"
                  id="Path"
                  stroke={props.color}
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export const Scale: FC<SVGProps<SVGSVGElement>> = props => (
  <svg width="22px" height="22px" viewBox="0 0 22 22" {...props}>
    <g id="Desktop" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Product-menu" transform="translate(-549.000000, -863.000000)">
        <g id="Group-3" transform="translate(436.000000, 848.000000)">
          <g id="Group-4" transform="translate(30.000000, 14.000000)">
            <g id="Echelle" transform="translate(82.000000, 0.000000)">
              <polygon id="Path" points="0 0 24 0 24 24 0 24" />
              <path
                // tslint:disable-next-line
                d="M14.4921868,17.985107 C18.6812389,17.7308562 22,14.2529972 22,10 C22,5.581722 18.418278,2 14,2 C9.73294518,2 6.24616122,5.34073665 6.01247756,9.54938052"
                id="Path"
                stroke={props.color}
                strokeWidth="2"
                fillRule="nonzero"
              />
              <path
                // tslint:disable-next-line
                d="M9.47298505,19.9771733 C9.64664365,19.9922855 9.82242091,20 10,20 C13.3137085,20 16,17.3137085 16,14 C16,10.6862915 13.3137085,8 10,8 C6.6862915,8 4,10.6862915 4,14 C4,14.179495 4.00788187,14.3571491 4.02331839,14.5326351"
                id="Path"
                stroke={props.color}
                strokeWidth="2"
                fillRule="nonzero"
              />
              <circle
                id="Oval"
                stroke={props.color}
                strokeWidth="2"
                fillRule="nonzero"
                cx="6"
                cy="18"
                r="4"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export const Duplicate: FC<SVGProps<SVGSVGElement>> = props => (
  <svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1" {...props}>
    <g id="Desktop" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Menu" transform="translate(-830.000000, -856.000000)">
        <g id="Group-4" transform="translate(497.000000, 854.000000)">
          <g id="Dupliquer" transform="translate(331.000000, 0.000000)">
            <rect id="Rectangle" fillRule="nonzero" x="0" y="0" width="24" height="24" />
            <rect
              id="Rectangle"
              stroke={props.color}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              x="10"
              y="3"
              width="11"
              height="11"
              rx="2"
            />
            <path
              // tslint:disable-next-line
              d="M6.92307692,10 L4,10 C3.44771525,10 3,10.4477153 3,11 L3,20 C3,20.5522847 3.44771525,21 4,21 L13,21 C13.5522847,21 14,20.5522847 14,20 L14,17.0769231"
              id="Path"
              stroke={props.color}
              strokeWidth="2"
              strokeLinejoin="round"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export const Variation: FC<SVGProps<SVGSVGElement>> = props => (
  <svg width="18px" height="18px" viewBox="0 0 18 18" version="1.1" {...props}>
    <g id="Desktop" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="Product-menu"
        transform="translate(-711.000000, -865.000000)"
        fill={props.color}
        fillRule="nonzero"
      >
        <g id="Group-3" transform="translate(436.000000, 848.000000)">
          <g id="Group-4" transform="translate(30.000000, 14.000000)">
            <g id="Matériaux" transform="translate(242.000000, 0.000000)">
              <path
                // tslint:disable-next-line
                d="M13.7279221,19 L19,13.7279221 L19,11.5563492 L11.5563492,19 L13.7279221,19 Z M16.5563492,19 L19,19 L19,16.5563492 L16.5563492,19 Z M8.72792206,19 L19,8.72792206 L19,6.55634919 L6.55634919,19 L8.72792206,19 Z M5,15.5563492 L5,17.7279221 L17.7279221,5 L15.5563492,5 L5,15.5563492 Z M5,12.7279221 L12.7279221,5 L10.5563492,5 L5,10.5563492 L5,12.7279221 Z M5,7.72792206 L7.72792206,5 L5,5 L5,7.72792206 Z M5,3 L19,3 C20.1045695,3 21,3.8954305 21,5 L21,19 C21,20.1045695 20.1045695,21 19,21 L5,21 C3.8954305,21 3,20.1045695 3,19 L3,5 C3,3.8954305 3.8954305,3 5,3 Z"
                id="Combined-Shape"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export const MultiSelect: FC<SVGProps<SVGSVGElement>> = props => (
  <svg width="18px" height="18px" viewBox="0 0 18 18" version="1.1" {...props}>
    <g id="Desktop" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Product-menu" transform="translate(-791.000000, -865.000000)">
        <g id="Group-3" transform="translate(436.000000, 848.000000)">
          <g id="Group-4" transform="translate(30.000000, 14.000000)">
            <g id="Tracer" transform="translate(322.000000, 0.000000)">
              <polygon id="Path" points="0 0 24 0 24 24 0 24" />
              <rect
                id="Rectangle-Copy-16"
                stroke={props.color}
                strokeWidth="2"
                fillRule="nonzero"
                x="10"
                y="10"
                width="4"
                height="4"
              />
              <rect
                id="Rectangle-Copy-5"
                fill={props.color}
                fillRule="nonzero"
                x="3"
                y="15"
                width="2"
                height="6"
              />
              <rect
                id="Rectangle-Copy-5"
                fill={props.color}
                fillRule="nonzero"
                x="3"
                y="3"
                width="2"
                height="6"
              />
              <rect
                id="Rectangle-Copy-6"
                fill={props.color}
                fillRule="nonzero"
                x="19"
                y="3"
                width="2"
                height="6"
              />
              <rect
                id="Rectangle-Copy-6"
                fill={props.color}
                fillRule="nonzero"
                x="19"
                y="15"
                width="2"
                height="6"
              />
              <rect
                id="Rectangle-Copy-7"
                fill={props.color}
                fillRule="nonzero"
                x="3"
                y="3"
                width="6"
                height="2"
              />
              <rect
                id="Rectangle-Copy-7"
                fill={props.color}
                fillRule="nonzero"
                x="15"
                y="3"
                width="6"
                height="2"
              />
              <rect
                id="Rectangle-Copy-8"
                fill={props.color}
                fillRule="nonzero"
                x="3"
                y="19"
                width="6"
                height="2"
              />
              <rect
                id="Rectangle-Copy-8"
                fill={props.color}
                fillRule="nonzero"
                x="15"
                y="19"
                width="6"
                height="2"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export const Hide: FC<SVGProps<SVGSVGElement>> = props => (
  <svg width="22px" height="22px" viewBox="0 0 22 22" version="1.1" {...props}>
    <g id="Desktop" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Product-menu" transform="translate(-869.000000, -863.000000)">
        <g id="Group-3" transform="translate(436.000000, 848.000000)">
          <g id="Group-4" transform="translate(30.000000, 14.000000)">
            <g id="Cacher" transform="translate(402.000000, 0.000000)">
              <rect id="Rectangle" fillRule="nonzero" x="0" y="0" width="24" height="24" />
              <path
                // tslint:disable-next-line
                d="M17.4,17.4 L17.4,17.4 C15.8459909,18.5845455 13.9537363,19.2407819 12,19.2727273 C5.63636359,19.2727273 1.99999996,12 1.99999996,12 L1.99999963,12.0000006 C3.13080872,9.89263701 4.69921781,8.05146429 6.59999963,6.60000065 M10.0909087,4.94545519 L10.0909082,4.94545532 C10.7166646,4.79898168 11.3573264,4.7257635 11.9999991,4.7272735 C18.3636355,4.7272735 21.9999991,12.0000008 21.9999991,12.0000008 L21.9999995,12 C21.4481632,13.0323727 20.7900541,14.0043 20.0363632,14.9 M13.9272723,13.9272727 L13.9272724,13.9272726 C12.9004451,15.0292453 11.1746997,15.0901635 10.0727269,14.0633335 C8.97075421,13.0365062 8.90983603,11.3107607 9.93666603,10.208788 C9.98040494,10.1618483 10.0257866,10.1164662 10.072726,10.072728"
                id="Shape"
                stroke={props.color}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M2,2 L22,22"
                id="Path"
                stroke={props.color}
                strokeWidth="2"
                strokeLinejoin="round"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export const Delete: FC<SVGProps<SVGSVGElement>> = props => (
  <svg width="18px" height="20px" viewBox="0 0 18 20" version="1.1" {...props}>
    <g id="Desktop" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Product-menu" transform="translate(-951.000000, -864.000000)">
        <g id="Group-3" transform="translate(436.000000, 848.000000)">
          <g id="Group-4" transform="translate(30.000000, 14.000000)">
            <g id="Supprimer---Black" transform="translate(482.000000, 0.000000)">
              <rect id="Rectangle" fillRule="nonzero" x="0" y="0" width="24" height="24" />
              <g
                id="trash-2"
                transform="translate(4.000000, 3.000000)"
                stroke={props.color}
                strokeLinejoin="round"
                strokeWidth="2"
              >
                <polyline id="Path" strokeLinecap="square" points="0 3.6 1.77777778 3.6 16 3.6" />
                <path
                  // tslint:disable-next-line
                  d="M14.2222222,3.6 L14.2222222,17 C14.2222222,17.5522847 13.774507,18 13.2222222,18 L2.77777778,18 C2.22549303,18 1.77777778,17.5522847 1.77777778,17 L1.77777778,3.6 M4.44444444,3.6 L4.44444444,1 C4.44444444,0.44771525 4.89215969,1.01453063e-16 5.44444444,0 L10.5555556,-1.11022302e-16 C11.1078403,-2.12475365e-16 11.5555556,0.44771525 11.5555556,1 L11.5555556,3.6"
                  id="Shape"
                />
                <path d="M6.22222222,8.1 L6.22222222,13.5" id="Path" strokeLinecap="square" />
                <path d="M9.77777778,8.1 L9.77777778,13.5" id="Path" strokeLinecap="square" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export const Enlarge: FC<SVGProps<SVGSVGElement>> = props => (
  <svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1" {...props}>
    <g id="Addsome---Studio" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="Ambiance---Configurateur-3D-Catalogue-Small"
        transform="translate(-1372.000000, -676.000000)"
      >
        <g id="Group-3-Copy-2" transform="translate(24.000000, 656.000000)">
          <g id="Agrandir" transform="translate(1344.000000, 16.000000)">
            <g id="Retour">
              <rect id="Rectangle" fillRule="nonzero" x="0" y="0" width="24" height="24" />
              <polyline
                id="Path"
                stroke={props.color}
                strokeWidth="2"
                points="10 5 5 5 4.99999991 10"
              />
              <polyline
                id="Path"
                stroke={props.color}
                strokeWidth="2"
                points="18.9999999 10 18.9999999 5 13.9999999 5"
              />
              <polyline
                id="Path"
                stroke={props.color}
                strokeWidth="2"
                points="13.9999999 19 18.9999999 19 18.9999998 14"
              />
              <polyline
                id="Path"
                stroke={props.color}
                strokeWidth="2"
                points="4.99999983 13.9999999 4.99999983 19.0000002 9.99999983 19.0000002"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export const VisualSearch: FC<SVGProps<SVGSVGElement>> = props => (
  <svg width="44px" height="44px" viewBox="0 0 44 44" version="1.1" {...props}>
    <g id="Addsome---Studio" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="Ambiance---Configurateur-3D---Large(1)"
        transform="translate(-710.000000, -370.000000)"
      >
        <g id="Visual-Search" transform="translate(708.000000, 368.000000)">
          <rect id="Rectangle" fillRule="nonzero" x="0" y="0" width="48" height="48"></rect>
          <g id="Mobilier" transform="translate(12.000000, 12.000000)">
            <polygon id="Path" points="0 0 24 0 24 24 0 24"></polygon>
            <path
              // tslint:disable-next-line
              d="M5,20 L3,20 C1.8954305,20 1,19.1045695 1,18 L1,10 C1,8.8954305 1.8954305,8 3,8 L3,4 C3,2.8954305 3.8954305,2 5,2 L19,2 C20.1045695,2 21,2.8954305 21,4 L21,8 C22.1045695,8 23,8.8954305 23,10 L23,18 C23,19.1045695 22.1045695,20 21,20 L19,20 L19,22 L17,22 L17,20 L7,20 L7,22 L5,22 L5,20 Z M7,18 L17,18 L17,15 L7,15 L7,18 Z M7,13 L17,13 L17,10 C17,8.8954305 17.8954305,8 19,8 L19,4 L5,4 L5,8 C6.1045695,8 7,8.8954305 7,10 L7,13 Z M19,10 L19,18 L21,18 L21,10 L19,10 Z M3,10 L3,18 L5,18 L5,10 L3,10 Z"
              id="Combined-Shape"
              fill={props.color}
              fillRule="nonzero"
            ></path>
          </g>
          <g id="Group-6" transform="translate(2.000000, 2.000000)" fillRule="nonzero">
            <path
              d="M1,7 L1,5 C1,2.790861 2.790861,1 5,1 L7,1"
              id="Path"
              stroke={props.color}
              strokeWidth="2"
            ></path>
            <rect
              id="Rectangle-Copy-22"
              fill={props.color}
              opacity="0.151343936"
              x="17"
              y="0"
              width="2"
              height="2"
            ></rect>
            <rect
              id="Rectangle-Copy-35"
              fill={props.color}
              opacity="0.151343936"
              x="17"
              y="42"
              width="2"
              height="2"
            ></rect>
            <rect
              id="Rectangle-Copy-22"
              fill={props.color}
              opacity="0.151343936"
              x="13"
              y="0"
              width="2"
              height="2"
            ></rect>
            <rect
              id="Rectangle-Copy-36"
              fill={props.color}
              opacity="0.151343936"
              x="13"
              y="42"
              width="2"
              height="2"
            ></rect>
            <rect
              id="Rectangle-Copy-27"
              fill={props.color}
              opacity="0.151343936"
              x="9"
              y="0"
              width="2"
              height="2"
            ></rect>
            <rect
              id="Rectangle-Copy-37"
              fill={props.color}
              opacity="0.151343936"
              x="9"
              y="42"
              width="2"
              height="2"
            ></rect>
            <rect
              id="Rectangle-Copy-28"
              fill={props.color}
              opacity="0.151343936"
              x="0"
              y="9"
              width="2"
              height="2"
            ></rect>
            <rect
              id="Rectangle-Copy-42"
              fill={props.color}
              opacity="0.151343936"
              x="42"
              y="9"
              width="2"
              height="2"
            ></rect>
            <rect
              id="Rectangle-Copy-29"
              fill={props.color}
              opacity="0.151343936"
              x="0"
              y="13"
              width="2"
              height="2"
            ></rect>
            <rect
              id="Rectangle-Copy-43"
              fill={props.color}
              opacity="0.151343936"
              x="42"
              y="13"
              width="2"
              height="2"
            ></rect>
            <rect
              id="Rectangle-Copy-30"
              fill={props.color}
              opacity="0.151343936"
              x="0"
              y="17"
              width="2"
              height="2"
            ></rect>
            <rect
              id="Rectangle-Copy-44"
              fill={props.color}
              opacity="0.151343936"
              x="42"
              y="17"
              width="2"
              height="2"
            ></rect>
            <rect
              id="Rectangle-Copy-31"
              fill={props.color}
              opacity="0.151343936"
              x="0"
              y="21"
              width="2"
              height="2"
            ></rect>
            <rect
              id="Rectangle-Copy-45"
              fill={props.color}
              opacity="0.151343936"
              x="42"
              y="21"
              width="2"
              height="2"
            ></rect>
            <rect
              id="Rectangle-Copy-32"
              fill={props.color}
              opacity="0.151343936"
              x="0"
              y="25"
              width="2"
              height="2"
            ></rect>
            <rect
              id="Rectangle-Copy-46"
              fill={props.color}
              opacity="0.151343936"
              x="42"
              y="25"
              width="2"
              height="2"
            ></rect>
            <rect
              id="Rectangle-Copy-33"
              fill={props.color}
              opacity="0.151343936"
              x="0"
              y="29"
              width="2"
              height="2"
            ></rect>
            <rect
              id="Rectangle-Copy-47"
              fill={props.color}
              opacity="0.151343936"
              x="42"
              y="29"
              width="2"
              height="2"
            ></rect>
            <rect
              id="Rectangle-Copy-34"
              fill={props.color}
              opacity="0.151343936"
              x="0"
              y="33"
              width="2"
              height="2"
            ></rect>
            <rect
              id="Rectangle-Copy-48"
              fill={props.color}
              opacity="0.151343936"
              x="42"
              y="33"
              width="2"
              height="2"
            ></rect>
            <rect
              id="Rectangle-Copy-23"
              fill={props.color}
              opacity="0.151343936"
              x="21"
              y="0"
              width="2"
              height="2"
            ></rect>
            <rect
              id="Rectangle-Copy-38"
              fill={props.color}
              opacity="0.151343936"
              x="21"
              y="42"
              width="2"
              height="2"
            ></rect>
            <rect
              id="Rectangle-Copy-24"
              fill={props.color}
              opacity="0.151343936"
              x="25"
              y="0"
              width="2"
              height="2"
            ></rect>
            <rect
              id="Rectangle-Copy-39"
              fill={props.color}
              opacity="0.151343936"
              x="25"
              y="42"
              width="2"
              height="2"
            ></rect>
            <rect
              id="Rectangle-Copy-25"
              fill={props.color}
              opacity="0.151343936"
              x="29"
              y="0"
              width="2"
              height="2"
            ></rect>
            <rect
              id="Rectangle-Copy-40"
              fill={props.color}
              opacity="0.151343936"
              x="29"
              y="42"
              width="2"
              height="2"
            ></rect>
            <rect
              id="Rectangle-Copy-26"
              fill={props.color}
              opacity="0.151343936"
              x="33"
              y="0"
              width="2"
              height="2"
            ></rect>
            <rect
              id="Rectangle-Copy-41"
              fill={props.color}
              opacity="0.151343936"
              x="33"
              y="42"
              width="2"
              height="2"
            ></rect>
            <path
              d="M37,7 L37,5 C37,2.790861 38.790861,1 41,1 L43,1"
              id="Path"
              stroke={props.color}
              strokeWidth="2"
              // tslint:disable-next-line
              transform="translate(40.000000, 4.000000) rotate(-270.000000) translate(-40.000000, -4.000000) "
            ></path>
            <path
              d="M1,43 L1,41 C1,38.790861 2.790861,37 5,37 L7,37"
              id="Path"
              stroke={props.color}
              strokeWidth="2"
              // tslint:disable-next-line
              transform="translate(4.000000, 40.000000) rotate(-90.000000) translate(-4.000000, -40.000000) "
            ></path>
            <path
              d="M37,43 L37,41 C37,38.790861 38.790861,37 41,37 L43,37"
              id="Path"
              stroke={props.color}
              strokeWidth="2"
              // tslint:disable-next-line
              transform="translate(40.000000, 40.000000) rotate(-180.000000) translate(-40.000000, -40.000000) "
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export const RenderAnimation: FC<SVGProps<SVGSVGElement>> = props => (
  <svg width="58px" height="58px" viewBox="0 0 58 58" version="1.1" {...props}>
    <g id="Addsome---Studio" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Packshot---Render-summay-Copy" transform="translate(-703.000000, -381.000000)">
        <g id="Group-5" transform="translate(504.000000, 338.000000)">
          <g id="Pop-Up-Window---Choose-Project">
            <g id="Group-10" transform="translate(196.000000, 40.000000)">
              <g id="Visual-Search">
                <rect id="Rectangle" fillRule="nonzero" x="0" y="0" width="64" height="64"></rect>
                <circle
                  id="Oval"
                  stroke=""
                  strokeWidth="2"
                  // tslint:disable-next-line
                  transform="translate(32.000000, 32.000000) rotate(-90.000000) translate(-32.000000, -32.000000) "
                  cx="32"
                  cy="32"
                  r="28"
                ></circle>
                <g id="Mobilier" transform="translate(20.000000, 20.000000)">
                  <polygon id="Path" points="0 0 24 0 24 24 0 24"></polygon>
                  <path
                    // tslint:disable-next-line
                    d="M5,20 L3,20 C1.8954305,20 1,19.1045695 1,18 L1,10 C1,8.8954305 1.8954305,8 3,8 L3,4 C3,2.8954305 3.8954305,2 5,2 L19,2 C20.1045695,2 21,2.8954305 21,4 L21,8 C22.1045695,8 23,8.8954305 23,10 L23,18 C23,19.1045695 22.1045695,20 21,20 L19,20 L19,22 L17,22 L17,20 L7,20 L7,22 L5,22 L5,20 Z M7,18 L17,18 L17,16 L7,16 L7,18 Z M7,14 L17,14 L17,10 C17,8.8954305 17.8954305,8 19,8 L19,4 L5,4 L5,8 C6.1045695,8 7,8.8954305 7,10 L7,14 Z M19,10 L19,18 L21,18 L21,10 L19,10 Z M3,10 L3,18 L5,18 L5,10 L3,10 Z"
                    id="Combined-Shape"
                    fill="#151515"
                    fillRule="nonzero"
                  ></path>
                </g>
                <g
                  id="Group-6"
                  transform="translate(21.000000, 34.000000)"
                  fill="#FFFFFF"
                  fillRule="nonzero"
                >
                  <rect id="Rectangle-Copy-68" x="4" y="4" width="2" height="2"></rect>
                  <rect id="Rectangle-Copy-74" x="4" y="0" width="2" height="2"></rect>
                  <rect id="Rectangle-Copy-69" x="0" y="4" width="2" height="2"></rect>
                  <rect id="Rectangle-Copy-75" x="0" y="0" width="2" height="2"></rect>
                  <rect id="Rectangle-Copy-70" x="8" y="4" width="2" height="2"></rect>
                  <rect id="Rectangle-Copy-76" x="8" y="0" width="2" height="2"></rect>
                  <rect id="Rectangle-Copy-71" x="12" y="4" width="2" height="2"></rect>
                  <rect id="Rectangle-Copy-77" x="12" y="0" width="2" height="2"></rect>
                  <rect id="Rectangle-Copy-72" x="16" y="4" width="2" height="2"></rect>
                  <rect id="Rectangle-Copy-78" x="16" y="0" width="2" height="2"></rect>
                  <rect id="Rectangle-Copy-73" x="20" y="4" width="2" height="2"></rect>
                  <rect id="Rectangle-Copy-79" x="20" y="0" width="2" height="2"></rect>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)
