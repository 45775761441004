import { MediaDTO } from '@addsome/dtos'
import { apiRequest } from '@addsome/redux-store'
import settings from '../settings'

const MEDIAS_PATH = '/medias'

export const mediaUploadUrl = `${settings.api.baseUrl}${MEDIAS_PATH}`

export function deleteMedia(id: string): Promise<void> {
  return apiRequest('DELETE', `${MEDIAS_PATH}/${id}`)
}

export const createFileFromUrl = async (url: string) => {
  const response = await fetch(url)
  const data = await response.blob()

  const file = new File([data], 'file')
  return file
}

export const uploadMedia = (file: File) => {
  const formData = new FormData()
  formData.append('file', file)
  return apiRequest<MediaDTO>('POST', MEDIAS_PATH, formData)
}
