import { Route } from 'antd/lib/breadcrumb/Breadcrumb'
import Menu, { ClickParam } from 'antd/lib/menu'
import Modal from 'antd/lib/modal'
import classNames from 'classnames'
import { push } from 'connected-react-router'
import React, { FC } from 'react'
import { WrappedComponentProps, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'

import { auth } from '@addsome/redux-store'
import { InvisibleButton, Layout } from '@addsome/ui-kit'

import { IRootState } from '../../../redux'

import { itemRender } from '../../AppBreadcrumb/AppBreadcrumb'
import styles from './Layout.module.scss'

type IProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  WrappedComponentProps & {
    className?: string
    activeTabKey?: string
    breadcrumbRoutes: Route[]
    tabs?: React.ReactNode[]
    title?: string
    onTabChange?: (activeKey: string) => void
    addButtonTitle?: string
    onAddButtonClick?: () => void
    onEdit?: (newValue: string) => void
  }

const CustomLayout: FC<IProps> = ({
  className,
  children,
  intl,
  logout,
  pushRouter,
  user,
  onEdit,
  ...props
}) => {
  const handleClick = ({ key }: ClickParam) => {
    switch (key) {
      case 'disconnect':
        Modal.confirm({
          centered: true,
          icon: <></>,
          title: intl.formatMessage({ id: 'header.disconnectConfirm' }),
          onOk: () => {
            logout()
            pushRouter('/login')
          },
          okText: intl.formatMessage({ id: 'global.yes' }),
          cancelText: intl.formatMessage({ id: 'global.no' }),
          okButtonProps: { className: styles.okButton },
          cancelButtonProps: { className: styles.cancelButton }
        })
    }
  }

  return (
    <Layout
      className={classNames(styles.container, className)}
      breadcrumbProps={{ itemRender }}
      userMenu={
        <Menu onClick={handleClick}>
          <Menu.Item key="disconnect">
            <InvisibleButton>{intl.formatMessage({ id: 'header.disconnect' })}</InvisibleButton>
          </Menu.Item>
        </Menu>
      }
      logoUrl={require('../../../assets/images/logos/addsome/black.png')}
      onLogoClick={() => pushRouter('/')}
      firstName={
        user && user.account && user.account.firstName ? user.account.firstName : undefined
      }
      lastName={user && user.account && user.account.lastName ? user.account.lastName : undefined}
      profilePictureUrl={
        user && user.account && user.account.profilePicture && user.account.profilePicture.url
          ? user.account.profilePicture.url
          : undefined
      }
      defaultProfilePictureUrl={require('../../../assets/images/backgroud/login.png')}
      onEdit={onEdit}
      {...props}
    >
      {children}
    </Layout>
  )
}

CustomLayout.defaultProps = {
  className: ''
}

const mapStateToProps = ({ userState: { user } }: IRootState) => ({ user })

const mapDispatchToProps = (dispatch: ThunkDispatch<IRootState, {}, AnyAction>) => ({
  pushRouter: (location: string) => dispatch(push(location)),
  logout: () => dispatch(auth.logout())
})

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(CustomLayout))
