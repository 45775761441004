import React, { FC } from 'react'
import styles from './RenderSettings.module.scss'
import { IWebRenderSetting } from '@addsome/redux-store/dist/store/packshot'
import globe from '../../../assets/images/icons/globe.png'
import printer from '../../../assets/images/icons/printer.png'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { InvisibleButton } from '@addsome/ui-kit'
import closeIcon from '../../../assets/images/icons/close.png'

interface IProps {
  renderSetting: IWebRenderSetting
  onClickDelete: (id: string) => void
}

const RenderSettings: FC<IProps & WrappedComponentProps> = ({
  renderSetting,
  intl,
  onClickDelete
}) => (
  <div className={styles.container}>
    <div className={styles.iconContainer}>
      <img src={renderSetting.type === 'web' ? globe : printer} />
    </div>
    <span className={styles.text}>{`${
      renderSetting.type === 'web'
        ? intl.formatMessage({ id: 'packshot.render.web' }).toUpperCase()
        : intl.formatMessage({ id: 'packshot.render.print' }).toUpperCase()
    } : ${renderSetting.width} X ${renderSetting.height} px`}</span>
    <InvisibleButton
      onClick={() => onClickDelete(renderSetting.id)}
      className={styles.closeButtonContainer}
    >
      <img src={closeIcon} className={styles.closeButton} />
    </InvisibleButton>
  </div>
)

export default injectIntl(RenderSettings)
