import { project as projectActions } from '@addsome/redux-store'
import { ContentContainer, Tabs, Loading } from '@addsome/ui-kit'
import { push } from 'connected-react-router'
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { WrappedComponentProps, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import notification from 'antd/lib/notification'
import { IRootState } from '../../../redux'
import Layout from '../../Common/Layout'

interface IMatchParams {
  id: string
}

type IProps = ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps> &
  WrappedComponentProps &
  RouteComponentProps<IMatchParams> & {
    activeTabKey: string
    children?: React.ReactNode
    hasContent?: boolean
  }

const ProjectLayout: FC<IProps> = ({
  fetchProject,
  project,
  pushRouter,
  children,
  activeTabKey,
  intl,
  hasContent,
  updateProjectTitle,
  match: {
    params: { id }
  }
}) => {
  const tabs = useMemo(() => {
    return [
      <Tabs.TabPane tab={intl.formatMessage({ id: 'project.products' })} key="products" />,
      <Tabs.TabPane tab={intl.formatMessage({ id: 'project.ambiances' })} key="ambiances" />,
      <Tabs.TabPane tab={intl.formatMessage({ id: 'project.visual' })} key="visuals" />
    ]
  }, [])

  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    if (!project || project.id !== id) {
      setLoaded(true)
      fetchProject(id).then(function(result) {
        if (!result) {
          notification.error({
            message: intl.formatMessage({ id: 'project.edit.error' }),
            description: intl.formatMessage({ id: 'project.edit.tryAgain' })
          })
          history.go(-1)
        }
        setLoaded(false)
      })
    }
  }, [project, id, fetchProject])

  const onTabChange = useCallback(
    (activeKey: string) => {
      pushRouter(`/projects/${id}/${activeKey}`)
    },
    [id, pushRouter]
  )

  const goToNewVisual = useCallback(() => {
    pushRouter(`/projects/${id}/visuals/new`)
  }, [id, pushRouter])

  const onEdit = useCallback(
    async newValue => {
      await updateProjectTitle(id, newValue)
    },
    [id]
  )

  const name = project ? project.name : ''

  return loaded ? (
    <Loading />
  ) : (
    <Layout
      activeTabKey={activeTabKey}
      breadcrumbRoutes={[
        {
          breadcrumbName: intl.formatMessage({ id: 'breadcrumb.home' }),
          path: '/'
        },
        {
          breadcrumbName: name,
          path: `/projects/${id}/products`
        },
        {
          breadcrumbName: intl.formatMessage({ id: `breadcrumb.${activeTabKey}` }),
          path: `/${id}/${activeTabKey}`
        }
      ]}
      title={name}
      onEdit={onEdit}
      tabs={tabs}
      onTabChange={onTabChange}
      addButtonTitle={hasContent ? intl.formatMessage({ id: 'project.newVisual' }) : undefined}
      onAddButtonClick={hasContent ? goToNewVisual : undefined}
    >
      <ContentContainer>{children}</ContentContainer>
    </Layout>
  )
}

ProjectLayout.defaultProps = {
  hasContent: false
}

const mapStateToProps = (state: IRootState) => ({
  project: state.projectState.value
})

const mapDispatchToProps = (dispatch: ThunkDispatch<IRootState, {}, AnyAction>) => ({
  pushRouter: (location: string) => dispatch(push(location)),
  fetchProject: (id: string) => dispatch(projectActions.thunks.fetchValue(id)),
  updateProjectTitle: (id: string, name: string) =>
    dispatch(projectActions.thunks.patchValue(id, { name }))
})

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(withRouter(ProjectLayout)))
