/**
 * TypeAction is an action triggered by user (click on a button)
 */
export enum TypeAction {
  // Main Actions
  Catalog = 'Catalog',
  Recalibrate = 'Recalibrate',
  Height = 'Height',
  ChangeHeight = 'ChangeHeight',
  Area = 'Area',
  WallCount = 'WallCount',
  TopView = 'TopView',
  Measure = 'Measure',
  Modify = 'Modify',
  Cancel = 'Cancel',
  Undo = 'Undo',
  Estimation = 'Estimation',
  Rendering = 'Rendering',
  Save = 'Save',
  Quit = 'Quit',
  Test = 'Test',
  Redo = 'Redo',
  Select = 'Select',
  None = 'None',
  FullScreen = 'FullScreen',

  // Object selection Subactions
  ObjectInfos = 'ObjectInfos',
  ObjectScale = 'ObjectScale',
  ObjectDuplicate = 'ObjectDuplicate',
  ObjectVariation = 'ObjectVariation',
  ObjectMultiSelect = 'ObjectMultiSelect',
  ObjectHide = 'ObjectHide',
  ObjectDelete = 'ObjectDelete'
}

/**
 * ApiMethod is the method name to send to the unity player API
 */
export enum ApiMethod {
  Calibrate = 'StartCalibration',
  CalibrationHeight = 'SetCalibrationRoomHeight',
  CalibrationArea = 'SetCalibrationRoomArea',
  CalibrationWallCount = 'SetCalibrationWallCount',
  ValidateCalibration = 'ValidateCalibration',
  GetSceneVersion = 'GetSceneVersion',
  LoadSceneFromProjectSceneContent = 'LoadSceneFromProjectSceneContent',
  EnableKeyboardInput = 'EnableKeyboardInput',
  DisableKeyboardInput = 'DisableKeyboardInput',
  AddPlayerProduct = 'AddPlayerProduct',
  Undo = 'Undo',
  Redo = 'Redo',
  HasSceneContentChanged = 'HasSceneContentChanged',
  SetPlayerBackgroundColor = 'SetPlayerBackgroundColor',
  ResetScale = 'ResetScale',
  DuplicateSelected = 'DuplicateSelected',
  NextPlayerVariationIndex = 'NextPlayerVariationIndex',
  StartMultiSelection = 'StartMultiSelection',
  DeleteSelected = 'DeleteSelected',
  UseOrbitalCamera = 'UseOrbitalCamera',
  UseFrontCamera = 'UseFrontCamera',
  ShowCalibrationPoints = 'ShowCalibrationPoints',
  CancelRecalibrationFromPoints = 'CancelRecalibrationFromPoints',
  ValidateRecalibrationFromPoints = 'ValidateRecalibrationFromPoints',
  RecalibrateFromRoomHeight = 'RecalibrateFromRoomHeight',
  Init = 'Init',
  AddPlayerProducts = 'AddPlayerProducts'
}

/**
 * Player mode is a particuliar view of player (calibration, Loading etc..)
 */
export enum EditorMode {
  Loading,
  Default,
  Calibration,
  Action
}

export enum EditorTheme {
  LIGHT = 'light',
  DARK = 'dark'
}

export enum WallCountValue {
  None = '0',
  Wall = '1',
  Angle = '2',
  Cube = '3'
}
