import { IPaginationRequest, product as productActions } from '@addsome/redux-store'
import { Button, Searchbar, SelectedNumber, Size, Theme } from '@addsome/ui-kit'
import Icon from 'antd/lib/icon'
import classNames from 'classnames'
import React, { FC, useEffect, useMemo, useState } from 'react'
import { connect } from 'react-redux'
import { Waypoint } from 'react-waypoint'
import { AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import { IRootState } from '../../../../../redux'
import { DEFAULT_PRODUCTS_FILTERS } from '../../../../../utils/filters'
import Filters from '../../../../Common/Filters/Filters'
import ProductGrid, { ISelectedProduct } from '../../../../Common/ProductGrid/ProductGrid'
import ProductsPreview from '../../../../Common/ProductsPreview/ProductsPreview'
import IconButton from '../IconButton'
import { Addsome, Cross, Enlarge } from '../PlayerIcons'
import styles from './PlayerCatalog.module.scss'
import {WrappedComponentProps, injectIntl, FormattedMessage} from 'react-intl'

const PAGE_SIZE = 20

type IProps = ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps> & WrappedComponentProps &{
    onClickProduct: (productId: string) => void
    onAddProducts: (productIds: string[]) => void
    onClickFullScreen: () => void
    onClose: () => void
    isOpen: boolean
    isFullScreen: boolean
    isToolbarTop: boolean
  }

const PlayerCatalog: FC<IProps> = ({
  isOpen,
  isFullScreen,
  onClickFullScreen,
  isToolbarTop,
  onClose,
  fetchProducts,
  loading,
  total,
  onClickProduct,
  products,
  onAddProducts
}) => {
  const [page, setPage] = useState(1)
  const [searchValue, setSearchValue] = useState('')
  const [filters, setFilters] = useState<{ [key: string]: string[] }>({})
  const [selectedProducts, setSelectedProducts] = useState<ISelectedProduct[]>(
    products.map(product => ({ id: product.id, selected: false }))
  )

  useEffect(() => {
    fetchProducts({})
  }, [])

  useEffect(() => {
    setPage(1)
    fetchProducts({ filters: { ...filters, query: [searchValue] } })
  }, [filters, searchValue])

  useEffect(() => {
    if (!isOpen) {
      setPage(1)
      setFilters({})
      setSearchValue('')
      fetchProducts({}, false)
    }
  }, [isOpen])

  useEffect(() => {
    setSelectedProducts(products.map(product => ({ id: product.id, selected: false })))
  }, [products])

  const nbSelectedProducts = useMemo(() => selectedProducts.filter(p => p.selected).length, [
    selectedProducts
  ])

  return (
    <div
      className={classNames(styles.container, {
        [styles.bottom]: !isToolbarTop && !isFullScreen,
        [styles.top]: isToolbarTop && !isFullScreen,
        [styles.open]: isOpen && !isFullScreen,
        [styles.fullScreen]: isFullScreen && isOpen
      })}
    >
      <div className={styles.actions}>
        <IconButton title="Agrandir" onClick={onClickFullScreen} displayTooltip>
          <Enlarge color="#000" />
        </IconButton>
        <IconButton title="Fermer" onClick={() => onClose()} displayTooltip>
          <Cross color="#000" />
        </IconButton>
      </div>
      {isFullScreen && (
        <>
          <div className={styles.header}>
            <Addsome className={styles.logo} color="#000" />
          </div>
          <Filters onUpdateFilters={setFilters} />
        </>
      )}
      <h1 className={isFullScreen ? styles.fullScreenTitle : ''}>Selectionner un produit</h1>
      {isFullScreen ? (
        <>
          <Searchbar
            className={styles.search}
            onSearch={value => setSearchValue(value)}
            placeholder="Rechercher un produit"
          />
          <ProductGrid
            onClickProduct={onClickProduct}
            onSelectProduct={productId =>
              setSelectedProducts(
                selectedProducts.map(selectedProduct =>
                  selectedProduct.id === productId
                    ? { ...selectedProduct, selected: !selectedProduct.selected }
                    : selectedProduct
                )
              )
            }
            selectedProducts={selectedProducts}
          />
          {loading && (
            <div className={styles.loadingContainer}>
              <Icon className={styles.loading} type="loading" />
            </div>
          )}
          <div className={styles.waypoint}>
            <Waypoint
              onEnter={() => {
                if (!loading && PAGE_SIZE * page < total) {
                  fetchProducts(
                    { skip: PAGE_SIZE * page, filters: { ...filters, name: [searchValue] } },
                    true
                  ).then(() => setPage(page + 1))
                }
              }}
            />
          </div>
        </>
      ) : (
        <ProductsPreview onClickProduct={onClickProduct} />
      )}
      {nbSelectedProducts > 0 && (
        <SelectedNumber
          selectedNumber={nbSelectedProducts}
          selectedNumberText="Produit séléctionné"
          selectedNumberPluralText="Produits selectionnés"
          firstButton={
            <Button
              onClick={() => onAddProducts(selectedProducts.filter(p => p.selected).map(p => p.id))}
              theme={Theme.BLACK}
              uppercase
              size={Size.LARGE}
            >
              <FormattedMessage id='drivepage.foldermodal.addfolder'/>
            </Button>
          }
        />
      )}
    </div>
  )
}

const mapStateToProps = (state: IRootState) => ({
  products: state.productState.values,
  total: state.productState.total,
  loading: state.productState.loading
})

const mapDispatchToProps = (dispatch: ThunkDispatch<IRootState, {}, AnyAction>) => ({
  fetchProducts: (request: IPaginationRequest, concatenate?: boolean) =>
    dispatch(
      productActions.thunks.fetchValues(
        {
          ...request,
          filters: {
            ...DEFAULT_PRODUCTS_FILTERS,
            ...request.filters
          }
        },
        concatenate
      )
    )
})

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(PlayerCatalog))
