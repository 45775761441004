import React, { FC, SVGProps } from 'react'
import { EditorTheme } from '../../enums'
import styles from './PlayerButton.module.scss'

const DARK_THEME_ICON_COLOR = '#FFF'
const LIGHT_THEME_ICON_COLOR = '#000'
const DARK_THEME_SELECTED_ICON_COLOR = '#FFF'
const LIGHT_THEME_SELECTED_ICON_COLOR = '#151515'

interface IProps {
  theme?: EditorTheme
  selected?: boolean
  text: string
  icon: FC<SVGProps<SVGSVGElement>>
  onClick: () => void
}

const PlayerButton: FC<IProps> = ({
  theme = EditorTheme.LIGHT,
  selected = false,
  text,
  icon,
  onClick
}) => {
  const Icon = icon
  const iconColor = theme === EditorTheme.DARK ? DARK_THEME_ICON_COLOR : LIGHT_THEME_ICON_COLOR
  const selectedIconColor =
    theme === EditorTheme.DARK ? DARK_THEME_SELECTED_ICON_COLOR : LIGHT_THEME_SELECTED_ICON_COLOR
  return (
    <button
      className={`${styles.container} ${styles[theme]} ${selected ? styles.selected : ''}`}
      onClick={onClick}
    >
      <div className={styles.icon}>
        <Icon color={selected ? selectedIconColor : iconColor} />
      </div>
      <span className={styles.text}>{text}</span>
    </button>
  )
}

export default PlayerButton
