import { ProductLightDTO } from '@addsome/dtos'
import { Card, InvisibleButton } from '@addsome/ui-kit'
import React from 'react'
import { WrappedComponentProps, injectIntl } from 'react-intl'
import { getCloudImageUrl } from '../../../utils'
import styles from './ProductCard.module.scss'
import Price from '../Price'

interface IProps {
  product: ProductLightDTO
  onClick: () => void
  className: string
  onSelect: (productId: string) => void
  selected: boolean
}

class ProductCard extends React.Component<WrappedComponentProps & IProps> {
  public render() {
    const { product, onClick, className, intl, onSelect, selected } = this.props

    return (
      <InvisibleButton onClick={onClick}>
        <Card
          className={`${styles.product} ${className}`}
          selectable
          selected={selected}
          onSelect={() => onSelect(product.id)}
          inner
          title={
            (product.brand && product.brand.name) || intl.formatMessage({ id: 'product.nobrand' })
          }
          desc={product.name}
          image={
            product.thumbnail && product.thumbnail.media && product.thumbnail.media.url
              ? getCloudImageUrl(product.thumbnail.media.url, 400)
              : ''
          }
          side={
            <div className={styles.price}>
              {product.suggestedPrice && <Price amount={product.suggestedPrice} />}
            </div>
          }
          ratio={[7, 5]}
        />
      </InvisibleButton>
    )
  }
}

export default injectIntl(ProductCard)
