import * as Sentry from '@sentry/browser'
import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import * as serviceWorker from './serviceWorker'

Sentry.init({
  dsn: process.env.REACT_APP_STUDIO_SENTRY_DSN,
  environment: process.env.REACT_APP_ENV,
  release: `studio@${process.env.SENTRY_RELEASE}`
})

ReactDOM.render(<App />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
