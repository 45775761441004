import React, { FC } from 'react'
import { EditorTheme, WallCountValue } from '../../enums'
import PlayerButton from '../PlayerButton'
import { Height } from '../PlayerIcons'
import styles from './WallCount.module.scss'

interface IProps {
  display: boolean
  selectedWallCount: WallCountValue
  onChangeSelectedWall: (value: WallCountValue) => void
}

const WallCount: FC<IProps> = ({ onChangeSelectedWall, display, selectedWallCount }) => {
  return (
    <div className={`${styles.container} ${display ? styles.open : ''}`}>
      <PlayerButton
        text="Mur"
        icon={Height}
        theme={EditorTheme.LIGHT}
        selected={selectedWallCount === WallCountValue.Wall}
        onClick={() => onChangeSelectedWall(WallCountValue.Wall)}
      />
      <PlayerButton
        text="Angle"
        icon={Height}
        theme={EditorTheme.LIGHT}
        selected={selectedWallCount === WallCountValue.Angle}
        onClick={() => onChangeSelectedWall(WallCountValue.Angle)}
      />
      <PlayerButton
        text="Cube"
        icon={Height}
        theme={EditorTheme.LIGHT}
        selected={selectedWallCount === WallCountValue.Cube}
        onClick={() => onChangeSelectedWall(WallCountValue.Cube)}
      />
    </div>
  )
}

export default WallCount
