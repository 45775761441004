import { FC, SVGProps } from 'react'
import {
  Calibrate,
  Catalog,
  Delete,
  Duplicate,
  Height,
  Infos,
  MultiSelect,
  Next,
  Previous,
  Render,
  Scale,
  TopView,
  Variation
} from '../components/PlayerIcons'
import { TypeAction } from '../enums'

export const ApiPlayerName = 'WebPlayerAPI'
export const playerBackground = '#f4f4f4'

interface IToobarItems {
  type: TypeAction
  label: string
  icon: FC<SVGProps<SVGSVGElement>>
}

export const toolbarItems: IToobarItems[] = [
  {
    type: TypeAction.Catalog,
    label: 'Catalogue',
    icon: Catalog
  },
  {
    type: TypeAction.Recalibrate,
    label: 'Calibration',
    icon: Calibrate
  },
  {
    type: TypeAction.ChangeHeight,
    label: 'Hauteur',
    icon: Height
  },
  {
    type: TypeAction.TopView,
    label: 'Top View',
    icon: TopView
  },
  // Todo: Not implemented yet
  // {
  //   type: TypeAction.Measure,
  //   label: 'Mesurer',
  //   icon: Measure
  // },
  // {
  //   type: TypeAction.Modify,
  //   label: 'Modifier',
  //   icon: Modify
  // },
  {
    type: TypeAction.Undo,
    label: 'Annuler',
    icon: Previous
  },
  {
    type: TypeAction.Redo,
    label: 'Rétablir',
    icon: Next
  },
  {
    type: TypeAction.Rendering,
    label: 'Rendu',
    icon: Render
  },
  {
    type: TypeAction.FullScreen,
    label: 'Plein écran',
    icon: Scale
  }
]

export const displaySubBarActions: TypeAction[] = [TypeAction.Modify, TypeAction.Select]

export const ACTION_SUBITEMS: { [key: string]: IToobarItems[] } = {
  [TypeAction.Select]: [
    {
      type: TypeAction.ObjectInfos,
      label: 'Infos',
      icon: Infos
    },
    {
      type: TypeAction.ObjectScale,
      label: 'Echelle',
      icon: Scale
    },
    {
      type: TypeAction.ObjectDuplicate,
      label: 'Dupliquer',
      icon: Duplicate
    },
    {
      type: TypeAction.ObjectVariation,
      label: 'Variations',
      icon: Variation
    },
    {
      type: TypeAction.ObjectMultiSelect,
      label: 'Multi-select',
      icon: MultiSelect
    },
    // Todo: Not implemented yet
    // {
    //   type: TypeAction.ObjectHide,
    //   label: 'Cacher',
    //   icon: Hide
    // },
    {
      type: TypeAction.ObjectDelete,
      label: 'Supprimer',
      icon: Delete
    }
  ]
}
