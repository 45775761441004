import { LocaleObject } from 'yup'

export const localeFr: LocaleObject = {
  mixed: {
    required: 'Ce champs est obligatoire',
    default: 'Champs invalide'
  },
  string: {
    min: 'Le champs doit comprendre au moins ${min} caractères',
    max: 'Le champs doit comprendre moins de ${max} caractères',
    matches: 'La valeur entrée ne respecte pas les règles de validation',
    email: 'Veuillez entrer un email valide',
    url: 'Veuillez entrer une URL valide'
  },
  number: {
    min: 'La valeur doit être au dessus de ${min}',
    max: 'La valeur doit être en dessous de ${max}',
    positive: 'Veuillez entrer une valeur positive',
    negative: 'Veuillez entrer une valeur negative'
  },
  date: {
    min: 'La date doit être supérieure à ${min}',
    max: 'La date doit être antérieure à ${max}'
  }
}

export const localeEn: LocaleObject = {
  mixed: {
    required: 'Required field',
    default: 'Invalid field'
  },
  string: {
    min: 'The field must have at least ${min} characters',
    max: 'The field must not have more than ${max} characters',
    email: 'Please enter a valid email',
    url: 'Please enter a valid URL'
  },
  number: {
    min: 'The value must be greater than ${min}',
    max: 'The value must be lower than ${max}',
    positive: 'Positive values only',
    negative: 'Negative values only'
  },
  date: {
    min: 'The date must be after ${min}',
    max: 'The date must be before ${max}'
  }
}
