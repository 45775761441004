import {
  packshot as packshotActions,
  productViewer as productViewerActions
} from '@addsome/redux-store'
import { Button, Theme } from '@addsome/ui-kit'
import { Icon } from 'antd'
import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import Unity, { UnityContent } from 'react-unity-webgl'
import { AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import cameraIcon from '../../../assets/images/icons/Camera.svg'
import { IRootState } from '../../../redux'
import settings from '../../../settings'
import styles from './ProductViewer.module.scss'

export interface IProductViewerProps {
  productId?: string
  variationIndex: number
}

type IProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  IProductViewerProps

interface IMessageParameter {
  [key: string]: any
}

const API_PLAYER_NAME = 'WebPlayerAPI'

let unityContent: UnityContent | undefined

function getUnityContent() {
  if (!unityContent) {
    unityContent = new UnityContent(
      `${window.location.origin}/AddsomeWebPlayer/Build/AddsomeWebPlayer.json`,
      `${window.location.origin}/AddsomeWebPlayer/Build/UnityLoader.js`
    )
  }
  return unityContent
}

interface IState {
  isLoading: boolean
  isUnityPlayerReady: boolean
  camera: any | null
}

class ProductViewer extends Component<IProps, IState> {
  public state = {
    isLoading: true,
    isUnityPlayerReady: false,
    camera: null
  }
  public componentDidMount() {
    this.addEventListener('WebPlayerAPI_OnWebPlayerReadyForInit', this.setApiInfos)
    this.addEventListener('WebPlayerAPI_OnWebPlayerReady', this.initViewer)
    this.addEventListener('WebPlayerAPI_OnViewerCameraAnglesReceived', this.handleCamera)
    this.addEventListener('WebPlayerAPI_OnViewerProductSet', this.onViewerProductSet)
  }

  public componentWillUnmount() {
    // this.sendMessage(API_PLAYER_NAME, 'ClearScene')
    getUnityContent().remove()
  }

  public componentDidUpdate(prevProps: IProps) {
    if (!this.state.isUnityPlayerReady) return
    const { productId, variationIndex } = this.props
    if (prevProps.productId !== productId) {
      this.sendMessage(API_PLAYER_NAME, 'SetViewerProduct', {
        productId,
        variationIndex
      })
    }
    if (prevProps.variationIndex !== variationIndex || prevProps.productId !== productId) {
      this.sendMessage(API_PLAYER_NAME, 'SetViewerVariationIndex', {
        variationIndex
      })
    }
  }

  public render() {
    const { displayActions, setCamera } = this.props
    const { camera } = this.state
    return (
      <div className={styles.container}>
        {this.state.isLoading && (
          <div className={styles.loading}>
            <Icon type="loading" style={{ fontSize: '3rem', color: '#000000' }} />
          </div>
        )}
        {displayActions && (
          <div className={styles.actions}>
            <div className={styles.instructions}>
              <FormattedMessage id="player.cameraAngle" />
            </div>
            <Button
              theme={Theme.WHITE}
              className={styles.pickCamera}
              block
              onClick={() => setCamera(camera)}
            >
              <img src={cameraIcon} alt="Pick camera angle" />
            </Button>
          </div>
        )}
        <Unity unityContent={getUnityContent()} height="100%" width="100%" />
      </div>
    )
  }

  private sendMessage = (
    gameObjectName: string,
    methodName: string,
    parameter?: IMessageParameter
  ) => unityContent && unityContent.send(gameObjectName, methodName, JSON.stringify(parameter))

  private addEventListener = (eventName: string, eventCallback: (e?: any) => void) =>
    getUnityContent().on(eventName, eventCallback)

  private setApiInfos = () =>
    this.sendMessage(API_PLAYER_NAME, 'Init', {
      url: settings.api.baseUrl,
      token: this.props.token
    })

  private onViewerProductSet = () => {
    this.setState({ isLoading: false })
  }

  private initViewer = () => {
    const { productId, variationIndex } = this.props
    this.setState({ isUnityPlayerReady: true }, () => {
      if (productId) {
        this.sendMessage(API_PLAYER_NAME, 'UseViewerCamera')
        this.sendMessage(API_PLAYER_NAME, 'SetViewerProduct', {
          productId,
          variationIndex
        })
      }
    })
  }

  private handleCamera = (e?: CustomEvent<any>) => {
    if (!e) return
    this.setState({
      camera: JSON.parse(e.detail)
    })
  }
}

const mapStateToProps = (state: IRootState) => ({
  displayViewer: state.productViewerState.displayViewer,
  displayActions: state.productViewerState.displayActions,
  token: state.authState.token
})

const mapDispatchToProps = (dispatch: ThunkDispatch<IRootState, {}, AnyAction>) => ({
  setCamera: (camera: any) => dispatch(packshotActions.setCustomCamera(camera)),
  setVariationIndex: (index: number) => dispatch(productViewerActions.setVariationIndex(index))
})

export default connect(mapStateToProps, mapDispatchToProps)(ProductViewer)
