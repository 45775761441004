import React, { FC } from 'react'
import { ACTION_SUBITEMS, displaySubBarActions, toolbarItems } from '../../constants'
import { EditorTheme, TypeAction } from '../../enums'
import IconButton from '../IconButton'
import PlayerButton from '../PlayerButton'
import { Addsome, Cross, MenuUpDown } from '../PlayerIcons'
import PlayerSubBar from '../PlayerSubBar'
import styles from './PlayerToolbar.module.scss'

interface IProps {
  currentTypeAction: TypeAction
  currentSubAction: TypeAction
  onAction: (action: TypeAction) => void
  onSubAction: (subaction: TypeAction) => void
  onSwitchPosition: () => void
  isDraft: boolean
  isTopView: boolean
  isToolbarTop: boolean
  selectedProductId?: string
}

const PlayerToolbar: FC<IProps> = ({
  currentTypeAction,
  currentSubAction,
  onAction,
  onSubAction,
  onSwitchPosition,
  isDraft,
  isTopView,
  isToolbarTop,
  selectedProductId
}) => {
  const displaySubBar = displaySubBarActions.some(action => action === currentTypeAction)

  return (
    <div className={`${styles.container} ${isToolbarTop ? styles.top : styles.bottom}`}>
      <div className={styles.leftPanel}>
        <IconButton
          title="Addsome Logo"
          className={styles.logo}
          onClick={() => onAction(TypeAction.Quit)}
        >
          <Addsome color="#FFF" />
        </IconButton>
        <IconButton
          title="Position"
          className={styles.updown}
          onClick={onSwitchPosition}
          displayTooltip
        >
          <MenuUpDown color="#FFF" />
        </IconButton>
      </div>

      <div className={styles.middlePanel}>
        {toolbarItems.map(item => (
          <PlayerButton
            key={item.label}
            selected={
              item.type === TypeAction.TopView ? isTopView : currentTypeAction === item.type
            }
            text={item.label}
            icon={item.icon}
            theme={EditorTheme.DARK}
            onClick={() => onAction(item.type)}
          />
        ))}
      </div>

      <div className={`${styles.rightPanel}`}>
        <button
          onClick={() => onAction(TypeAction.Save)}
          className={styles.btnSave}
          disabled={!isDraft}
        >
          Sauvegarder
        </button>

        <IconButton
          title="Fermer"
          className={styles.btnClose}
          onClick={() => onAction(TypeAction.Quit)}
          displayTooltip
        >
          <Cross color="#FFF" />
        </IconButton>
      </div>

      {/* Sub actions bar */}
      <PlayerSubBar
        isOpen={displaySubBar}
        className={`${styles.subbar} ${isToolbarTop ? styles.top : styles.bottom}`}
      >
        {displaySubBar &&
          ACTION_SUBITEMS[currentTypeAction].map(item => (
            <PlayerButton
              key={item.label}
              text={item.label}
              icon={item.icon}
              selected={currentSubAction === item.type}
              onClick={() => onSubAction(item.type)}
            />
          ))}
      </PlayerSubBar>

      {/* Object select bar */}
      <PlayerSubBar
        isOpen={!!selectedProductId}
        className={`${styles.subbar} ${isToolbarTop ? styles.top : styles.bottom}`}
      >
        {ACTION_SUBITEMS[TypeAction.Select].map(item => (
          <PlayerButton
            key={item.label}
            text={item.label}
            icon={item.icon}
            selected={currentSubAction === item.type}
            onClick={() => onSubAction(item.type)}
          />
        ))}
      </PlayerSubBar>
    </div>
  )
}

export default PlayerToolbar
