import { ProductLightExtendedDTO } from '@addsome/dtos'
import { packshot, productViewer } from '@addsome/redux-store/dist'
import { IProductSetting } from '@addsome/redux-store/dist/store/packshot'
import { Button } from '@addsome/ui-kit'
import { Icon } from 'antd'
import { push } from 'connected-react-router'
import React, { FC, useCallback } from 'react'
import { WrappedComponentProps, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import { IRootState } from '../../../redux'
import styles from './PackshotVariations.module.scss'
import ProductVariations from './ProductVariations'

interface IMatchParams {
  id: string
}

type IProps = ReturnType<typeof mapDispatchToProps> &
  WrappedComponentProps &
  RouteComponentProps<IMatchParams> & {
    products: ProductLightExtendedDTO[]
    productsSettings: IProductSetting[]
    onProductSelect: (index: number) => void
    onProductDelete: (index: number) => void
    onCustomCameraSelect: (index: number) => void
    selectedProductIndex: number
    readonly?: boolean
  }

const PackshotVariations: FC<IProps> = ({
  products,
  productsSettings,
  onProductSelect,
  onProductDelete,
  onCustomCameraSelect,
  selectedProductIndex,
  pushRouter,
  intl,
  match: {
    params: { id }
  },
  setDisplayViewer,
  setIsAdd,
  readonly
}) => {
  const goToSelection = useCallback(() => {
    setIsAdd(true)
    setDisplayViewer(false)
    pushRouter(id ? `/projects/${id}/visuals/new` : '/')
  }, [id, pushRouter, setDisplayViewer, setIsAdd])

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.title}>
          {intl.formatMessage({ id: 'visual.new.products' })} : {products.length}
        </div>
        {!readonly && (
          <Button onClick={goToSelection} className={styles.addProductButton}>
            <div className={styles.buttonContent}>
              <Icon className={styles.buttonIcon} type="plus-circle" theme="filled" />
              {intl.formatMessage({ id: 'visual.new.addProduct' })}
            </div>
          </Button>
        )}
      </div>
      <div className={styles.content}>
        {products.map((product, index) => (
          <ProductVariations
            key={product.id}
            product={product}
            settings={productsSettings.find(s => s.productId === product.id)}
            onClick={() => onProductSelect(index)}
            onDelete={() => onProductDelete(index)}
            onCustomCamera={() => onCustomCameraSelect(index)}
            selected={selectedProductIndex === index}
            readonly={readonly}
            hasCustomCamera={productsSettings[index] && productsSettings[index].customCamera}
          />
        ))}
      </div>
    </div>
  )
}

const mapStateToProps = (state: IRootState) => ({
  productSettings: state.packshotState.productsSettings
})

const mapDispatchToProps = (dispatch: ThunkDispatch<IRootState, {}, AnyAction>) => ({
  pushRouter: (location: string) => dispatch(push(location)),
  setDisplayViewer: (displayViewer: boolean) =>
    dispatch(productViewer.setDisplayViewer(displayViewer)),
  setIsAdd: (isAdd: boolean) => dispatch(packshot.setIsAdd(isAdd))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(withRouter(PackshotVariations)))
