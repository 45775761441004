import { Button, Modal, Size, Theme } from '@addsome/ui-kit'
import React, { FC } from 'react'
import { WrappedComponentProps, injectIntl } from 'react-intl'
import { VisualSearch } from '../PlayerIcons'
import styles from './PlayerSaveAndQuit.module.scss'

type IProps = WrappedComponentProps & {
  visible: boolean
  onSaveAndQuit: () => void
  onQuitWithoutSaving: () => void
  onCancel: () => void
}

const PlayerSaveAndQuit: FC<IProps> = ({
  onSaveAndQuit,
  onQuitWithoutSaving,
  onCancel,
  visible,
  intl
}) => (
  <Modal
    visible={visible}
    mask={false}
    footer={false}
    width={360}
    centered
    closable={false}
    className={styles.container}
  >
    <VisualSearch color="#000" />
    <div className={styles.text}>
      {intl.formatMessage({ id: 'player.saveandquit.text1' })}
      <br />
      {intl.formatMessage({ id: 'player.saveandquit.text2' })}
    </div>
    <div>
      <Button onClick={onSaveAndQuit} block uppercase size={Size.LARGE} theme={Theme.PRIMARY}>
        {intl.formatMessage({ id: 'player.saveandquit.saveandquit' })}
      </Button>
      <Button onClick={onQuitWithoutSaving} block uppercase size={Size.LARGE} theme={Theme.WHITE}>
        {intl.formatMessage({ id: 'player.saveandquit.quitwosaving' })}
      </Button>
      <Button onClick={onCancel} block uppercase size={Size.LARGE} theme={Theme.WHITE}>
        {intl.formatMessage({ id: 'player.saveandquit.cancel' })}
      </Button>
    </div>
  </Modal>
)

export default injectIntl(PlayerSaveAndQuit)
