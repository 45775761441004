import { push } from 'connected-react-router'
import React from 'react'
import { WrappedComponentProps, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'

import studio3dLogo from '../../../assets/images/logos/addsome/studio3d.svg'
import { IRootState } from '../../../redux'
import BlueButton from '../../Common/Elements/BlueButton'
import styles from './NoProjects.module.scss'

type IProps = ReturnType<typeof mapDispatchToProps> & WrappedComponentProps

const NoProjects: React.FC<IProps> = ({ intl, pushRouter }) => (
  <div className={styles.container}>
    <div>
      <img className={styles.studioLogo} src={studio3dLogo} />
      <div className={styles.welcome}>{intl.formatMessage({ id: 'home.welcome' })}</div>
      <BlueButton
        labelIntlId={'home.createFirstProject'}
        onClick={() => {
          pushRouter('/projects/new')
        }}
      />
    </div>
  </div>
)

const mapDispatchToProps = (dispatch: ThunkDispatch<IRootState, {}, AnyAction>) => ({
  pushRouter: (location: string) => dispatch(push(location))
})

export default connect(null, mapDispatchToProps)(injectIntl(NoProjects))
