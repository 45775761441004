import { brandUserProject, IPaginationRequest } from '@addsome/redux-store'
import { ContentContainer, ICardData, InfiniteGridWithSearch, Popconfirm } from '@addsome/ui-kit'
import { push } from 'connected-react-router'
import moment from 'moment'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { WrappedComponentProps, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import trashIcon from '../../assets/images/icons/trash-white.png'
import Layout from '../../components/Common/Layout'
import NoProjects from '../../components/Projects/Empty'
import { IRootState } from '../../redux'
import { getCloudImageUrl } from '../../utils'
import styles from './Projects.module.scss'

type IProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  WrappedComponentProps

const ProjectsPage: React.FC<IProps> = ({
  projects,
  totalProjects,
  fetchProjects,
  deleteProject,
  user,
  intl,
  pushRouter,
  emptyProjects
}) => {
  const [loaded, setLoaded] = useState(false)
  const [allProjectsNumber, setAllProjectsNumber] = useState(0)
  const [filters, setFilters] = useState({})

  useEffect(() => {
    if (!user) return
    fetchProjects(user.id, intl.formatMessage({id:'userProjects.errorRetrievingProjects'}) ,{ order: { createdAt: 'ASC' } }).then(() => setLoaded(true))
  }, [user])

  useEffect(() => {
    if (loaded) {
      setAllProjectsNumber(totalProjects)
    }
  }, [loaded])

  useEffect(() => {
    return () => {
      emptyProjects()
    }
  }, [])

  const projectsForGrid: ICardData[] = useMemo(() => {
    return projects.map(({ name, updatedAt, picture, ...rest }) => {
      let image = null

      if (picture && picture.url) {
        image = getCloudImageUrl(picture.url, 400)
      }

      return {
        ...rest,
        title: name,
        footer: `${intl.formatMessage({ id: 'global.updatedAt' })} ${moment(updatedAt).format(
          'DD/MM à hh:mm'
        )}`,
        image
      }
    })
  }, [projects])

  const fetchItems = useCallback(
    (
      fetchFilters: { [key: string]: string[] },
      renew: boolean,
      skip: number,
      order?: { [key: string]: 'ASC' | 'DESC' }
    ) => {
      if (!user) return Promise.resolve()
      return fetchProjects(user.id, intl.formatMessage({id:'userProjects.errorRetrievingProjects'}), { skip, filters: fetchFilters, order }, !renew)
    },
    [user, fetchProjects]
  )

  const goToProject = useCallback(
    (project: ICardData) => {
      pushRouter(`/projects/${project.id}/products`)
    },
    [pushRouter]
  )

  const goToCreateProject = useCallback(() => {
    pushRouter(`/projects/new`)
  }, [pushRouter])

  const handleDeleteConfirm = useCallback(
    (project: ICardData) => {
      if (!user) return
      deleteProject(user.id, project.id, intl.formatMessage({id:'architectProject.deletedProject'}),
       intl.formatMessage({id:'architectProject.errorDeletingProject'}),
       intl.formatMessage({id: 'userProjects.errorRetrievingProjects'}),
       {
        take: projects.length - 1,
        filters
      })
    },
    [user, deleteProject, projects, filters]
  )

  const renderTopIcons = useCallback(
    (project: ICardData) => {
      return (
        <div className={styles.deleteContainer}>
          <Popconfirm
            title={intl.formatMessage({ id: 'projects.remove' })}
            onConfirm={() => handleDeleteConfirm(project)}
            okText={intl.formatMessage({ id: 'global.yes' })}
            cancelText={intl.formatMessage({ id: 'global.no' })}
            placement="top"
          >
            <img className={styles.icon} src={trashIcon} />
          </Popconfirm>
        </div>
      )
    },
    [handleDeleteConfirm]
  )

  if (!loaded) {
    return (
      <Layout
        breadcrumbRoutes={[
          {
            breadcrumbName: intl.formatMessage({ id: 'breadcrumb.home' }),
            path: '/'
          }
        ]}
      />
    )
  }

  return (
    <Layout
      breadcrumbRoutes={[
        {
          breadcrumbName: intl.formatMessage({ id: 'breadcrumb.home' }),
          path: '/'
        }
      ]}
      title={allProjectsNumber ? intl.formatMessage({ id: 'projects.title' }) : undefined}
      addButtonTitle={allProjectsNumber ? intl.formatMessage({ id: 'projects.new' }) : undefined}
      onAddButtonClick={goToCreateProject}
    >
      <ContentContainer>
        {!allProjectsNumber ? (
          <NoProjects />
        ) : (
          <InfiniteGridWithSearch
            orderItems={[
              {
                id: 1,
                label: '+ Récents',
                orderKey: 'createdAt',
                order: 'DESC'
              },
              {
                id: 2,
                label: '- Récents',
                orderKey: 'createdAt',
                order: 'ASC'
              }
            ]}
            headerClassName={styles.gridHeader}
            title={intl.formatMessage({ id: 'projects.chooseProject' })}
            searchPlaceHolder={intl.formatMessage({ id: 'projects.searchProject' })}
            onSearchChange={setFilters}
            fetchItems={fetchItems}
            totalItems={totalProjects}
            cardsData={projectsForGrid}
            doInitialFetch={false}
            shadow
            onCardClick={goToProject}
            hoverText={intl.formatMessage({ id: 'global.open' })}
            renderTopIcons={renderTopIcons}
          />
        )}
      </ContentContainer>
    </Layout>
  )
}

const mapStateToProps = (state: IRootState) => ({
  projects: state.brandUserProjectState.projects,
  totalProjects: state.brandUserProjectState.total,
  user: state.userState.user
})

const mapDispatchToProps = (dispatch: ThunkDispatch<IRootState, {}, AnyAction>) => ({
  pushRouter: (location: string) => dispatch(push(location)),
  deleteProject: (brandId: string, projectId: string, deletedProject?: string,
    errorDeletingProject? :string, errorRetrievingProjects?: string, request?: IPaginationRequest) =>
    dispatch(brandUserProject.deleteProject(brandId, projectId, deletedProject, errorDeletingProject, errorRetrievingProjects, request)),
  fetchProjects: (brandId: string, errorRetrievingProjects?: string, request?: IPaginationRequest, concatenate?: boolean) =>
    dispatch(brandUserProject.fetchBrandUserProjects(brandId, errorRetrievingProjects, request, concatenate)),
  emptyProjects: () => dispatch(brandUserProject.setUserProjects([], 0))
})

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ProjectsPage))
