import { Button, Size, Theme } from '@addsome/ui-kit'
import React, { FC, useState } from 'react'
import { ApiMethod, TypeAction, WallCountValue } from '../../enums'
import { RoomArea, RoomHeight } from '../PlayerIcons'
import PlayerPopupMeasure from '../PlayerPopupMeasure/PlayerPopupMeasure'
import WallCount from '../WallCount'
import styles from './RoomCalibrate.module.scss'

interface IProps {
  currentTypeAction: TypeAction
  onValidate: (method: ApiMethod, value?: string) => void
}

const RoomCalibrate: FC<IProps> = ({ currentTypeAction, onValidate }) => {
  const [selectedWallCount, setSelectedWallCount] = useState<WallCountValue>(WallCountValue.None)

  return (
    <div className={styles.container}>
      <div className={styles.infos}>
        <span>
          {currentTypeAction === TypeAction.WallCount
            ? 'Choisissez votre configuration'
            : 'Caractéristiques de la pièce'}
        </span>
      </div>
      <PlayerPopupMeasure
        visible={currentTypeAction === TypeAction.Height}
        onValidate={onValidate}
        method={ApiMethod.CalibrationHeight}
        text="Hauteur de la pièce"
        icon={<RoomHeight />}
      />
      <PlayerPopupMeasure
        visible={currentTypeAction === TypeAction.Area}
        onValidate={onValidate}
        method={ApiMethod.CalibrationArea}
        text="Surface de la pièce"
        icon={<RoomArea />}
        isSquareUnits={true}
      />
      <WallCount
        display={currentTypeAction === TypeAction.WallCount}
        selectedWallCount={selectedWallCount}
        onChangeSelectedWall={value => {
          setSelectedWallCount(value)
          onValidate(ApiMethod.CalibrationWallCount, value)
        }}
      />
      <div
        className={`${styles.actions} ${
          currentTypeAction === TypeAction.WallCount ? styles.open : ''
        }`}
      >
        <Button
          theme={Theme.WHITE}
          size={Size.LARGE}
          uppercase
          block
          onClick={() => {
            setSelectedWallCount(WallCountValue.None)
            onValidate(ApiMethod.CalibrationWallCount, WallCountValue.None)
          }}
          disabled={selectedWallCount === WallCountValue.None}
        >
          Annuler
        </Button>
        <Button
          theme={Theme.PRIMARY}
          size={Size.LARGE}
          uppercase
          block
          onClick={() => onValidate(ApiMethod.ValidateCalibration)}
          disabled={selectedWallCount === WallCountValue.None}
        >
          Valider
        </Button>
      </div>
    </div>
  )
}

export default RoomCalibrate
