import React from 'react'
import { connect } from 'react-redux'
import { IRootState } from '../../../redux'
import ProductCard from '../ProductCard/ProductCard'
import styles from './ProductGrid.module.scss'

export interface ISelectedProduct {
  id: string
  selected: boolean
}
interface IOwnProps {
  className?: string
  onClickProduct: (productId: string) => void
  selectedProducts: ISelectedProduct[]
  onSelectProduct: (productId: string) => void
}

type IProps = ReturnType<typeof mapStateToProps> & IOwnProps

const ProductGrid: React.FC<IProps> = ({
  products,
  className,
  onClickProduct,
  selectedProducts,
  onSelectProduct
}) => {
  return (
    <div className={`${styles.products} ${className}`}>
      {products.length > 0 &&
        products.map(product => (
          <ProductCard
            key={product.id}
            className={styles.product}
            product={product}
            onClick={() => onClickProduct(product.id)}
            selected={!!selectedProducts.find(p => p.id === product.id && p.selected === true)}
            onSelect={onSelectProduct}
          />
        ))}
    </div>
  )
}

const mapStateToProps = (state: IRootState) => ({
  products: state.productState.values
})

export default connect(mapStateToProps)(ProductGrid)
