import React from 'react'
import { LoginLayout } from '@addsome/ui-kit'
import logo from '../../assets/images/logos/addsome/white.png'
import bg from '../../assets/images/backgroud/login.png'
import Footer from '../../components/Footer/Footer'

interface IProps {
  title: React.ReactNode
  subtitle?: React.ReactNode
  children: React.ReactNode
}

const ConnectionLayout: React.FC<IProps> = ({ title, subtitle, children }) => {
  return (
    <LoginLayout
      logo={logo}
      backgroundImage={bg}
      pageTitle="Addsome Studio"
      title={title}
      subtitle={subtitle}
      footer={<Footer />}
    >
      {children}
    </LoginLayout>
  )
}

export default ConnectionLayout
