import { ObjectProductVariationDTO, ProductLightExtendedDTO } from '@addsome/dtos'
import {
  apiRequest,
  cameraTemplate as cameraTemplateActions,
  IPaginationRequest,
  packshot as packshotActions
} from '@addsome/redux-store'
import { IProductSetting } from '@addsome/redux-store/dist/store/packshot'
import { Button, InvisibleButton, MultipleSelect, Option, Theme } from '@addsome/ui-kit'
import { Icon } from 'antd'
import classNames from 'classnames'
import React, { FC, useEffect, useState } from 'react'
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import closeIcon from '../../../../assets/images/icons/close.png'
import { IRootState } from '../../../../redux'
import styles from './ProductVariations.module.scss'

type IProps = ReturnType<typeof mapStateTopProps> &
  ReturnType<typeof mapDispatchToProps> &
  WrappedComponentProps & {
    product: ProductLightExtendedDTO
    settings?: IProductSetting
    onClick: () => void
    onDelete: () => void
    onCustomCamera: () => void
    selected: boolean
    readonly?: boolean
    hasCustomCamera?: boolean
  }

function fetchObjectProductVariations(id: string) {
  return apiRequest<ObjectProductVariationDTO[]>('GET', `object-products/${id}/variations`)
}

const ProductVariations: FC<IProps> = ({
  product,
  settings,
  onClick,
  onDelete,
  selected,
  onCustomCamera,
  setProductSettingsVariationIds,
  fecthCameraTemplates,
  cameraTemplates,
  setProductSettingsCameraIds,
  intl,
  readonly,
  hasCustomCamera
}) => {
  const [variations, setVariations] = useState<ObjectProductVariationDTO[]>([])
  const [loading, setLoading] = useState(true)
  const [customCameraButtonHover, setCameraButtonHover] = useState(false)

  useEffect(() => {
    fecthCameraTemplates()
    if (product.objectProductId) {
      fetchObjectProductVariations(product.objectProductId).then(v => {
        setLoading(true)
        return setVariations(v)
      })
    } else {
      setLoading(true)
    }
  }, [fecthCameraTemplates, product])

  const getCameraMessage = () => {
    if (readonly && selected) {
      return `product.variations.cancelCustomCamera`
    } else {
      if (hasCustomCamera) {
        return customCameraButtonHover
          ? `product.variations.customCameraModify`
          : `product.variations.customCamera`
      } else {
        return `product.variations.setCustomCamera`
      }
    }
  }

  const cameraMessage = getCameraMessage()

  return (
    <div className={styles.container}>
      <div className={styles.infoAndButtonContainer}>
        <InvisibleButton
          onClick={onClick}
          className={classNames(styles.headerContainer, {
            [styles.selected]: selected
          })}
          disabled={readonly}
        >
          <div className={styles.infoContainer}>
            <div
              className={classNames(styles.imagePlaceholder, {
                [styles.selected]: selected,
                imagePlaceholder: true
              })}
            >
              <div className={styles.overIcon}>
                <Icon type="eye" />
              </div>
              {product.thumbnail && product.thumbnail.media && product.thumbnail.media.url && (
                <img src={product.thumbnail.media.url} />
              )}
            </div>
            <div className={styles.info}>
              <div className={styles.name}>{product.name}</div>
            </div>
          </div>
        </InvisibleButton>
        <InvisibleButton onClick={onDelete} disabled={readonly}>
          <img src={closeIcon} className={styles.closeButton} />
        </InvisibleButton>
      </div>
      <div className={styles.field}>
        <MultipleSelect
          className={styles.colorSelect}
          loading={loading}
          allowClear={true}
          showArrow={true}
          value={(settings && settings.variationIds) || []}
          onChange={value => setProductSettingsVariationIds(product.id, value as string[])}
          placeholder={intl.formatMessage({ id: 'product.variations.selectVariation' })}
          disabled={readonly}
          filterOption={(input, option) =>
            (option!.props!.children as string).toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {variations.map(variation => (
            <Option key={variation.id} value={variation.id}>
              {variation.name}
            </Option>
          ))}
        </MultipleSelect>
      </div>
      <div className={styles.field}>
        <MultipleSelect
          className={styles.angleSelect}
          allowClear={true}
          showArrow={true}
          value={(settings && settings.cameraIds) || []}
          onChange={value => setProductSettingsCameraIds(product.id, value as string[])}
          placeholder={intl.formatMessage({ id: 'product.variations.selectCamera' })}
          disabled={readonly}
          filterOption={(input, option) =>
            (option!.props!.children as string).toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {cameraTemplates.map(cameraTemplate => (
            <Option key={cameraTemplate.id} value={cameraTemplate.id}>
              {cameraTemplate.name}
            </Option>
          ))}
        </MultipleSelect>
      </div>
      <div className={styles.field}>
        <Button
          block
          onClick={onCustomCamera}
          theme={Theme.GHOST}
          disabled={readonly && !selected}
          onMouseEnter={() => {
            setCameraButtonHover(true)
          }}
          onMouseLeave={() => {
            setCameraButtonHover(false)
          }}
        >
          <div className={styles.customCamera}>
            <Icon type="camera" />
            <FormattedMessage id={cameraMessage} />
          </div>
        </Button>
      </div>
    </div>
  )
}

const mapStateTopProps = (state: IRootState) => ({
  cameraTemplates: state.cameraTemplateState.values
})

const mapDispatchToProps = (dispatch: ThunkDispatch<IRootState, {}, AnyAction>) => ({
  setProductSettingsVariationIds: (productId: string, variationIds: string[]) =>
    dispatch(packshotActions.setProductSettingsVariationIds(productId, variationIds)),
  setProductSettingsCameraIds: (productId: string, cameraSettings: string[]) =>
    dispatch(packshotActions.setProductSettingsCameraIds(productId, cameraSettings)),
  fecthCameraTemplates: (request?: IPaginationRequest) =>
    dispatch(cameraTemplateActions.thunks.fetchValues(request))
})

export default connect(mapStateTopProps, mapDispatchToProps)(injectIntl(ProductVariations))
