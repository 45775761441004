import { auth } from '@addsome/redux-store/dist'
import { Button, InputField, InvisibleButton, Size, Theme } from '@addsome/ui-kit'
import { Form, Icon } from 'antd'
import { Formik } from 'formik'
import { parse } from 'querystring'
import React, { useCallback, useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import * as yup from 'yup'
import { IRootState } from '../../redux'
import styles from './ResetPassword.module.scss'
import ConnectionLayout from '../../components/ConnectionLayout/ConnectionLayout'

type IProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>

const ResetPassword: React.FC<IProps> = ({ queryParams, resetPassword, authError }) => {
  const [password, setPassword] = useState('')
  const [token, setToken] = useState<string>()
  const [showPassword, setShowPassword] = useState(false)
  const [sent, setSent] = useState(false)

  const validationSchema = yup.object().shape({
    password: yup
      .string()
      .required()
      .min(5)
  })

  useEffect(() => {
    if (queryParams.token) setToken(queryParams.token as string)
  }, [queryParams])

  const handleSubmit = useCallback(async () => {
    if (token && password) {
      await resetPassword(token, password)
      setSent(true)
    }
  }, [password, token])

  return (
    <ConnectionLayout
      title={<FormattedMessage id="resetpassword.title" />}
      subtitle={
        <div className={authError ? styles.error : ''}>
          <FormattedMessage
            id={`resetpassword.${sent ? (authError ? 'error' : 'success') : 'presentation'}`}
            values={{
              link: (
                <Link to="/login">
                  <FormattedMessage id="resetpassword.link" />
                </Link>
              )
            }}
          />
        </div>
      }
    >
      {!sent && (
        <Formik
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          initialValues={{ password }}
        >
          {formik => (
            <Form noValidate onSubmit={formik.handleSubmit} className={styles.resetPassword}>
              <Form.Item required colon={false} label="Mot de passe">
                <InputField
                  error={formik.errors.password}
                  name="password"
                  type={showPassword ? 'text' : 'password'}
                  required
                  onChange={e => {
                    formik.handleChange(e)
                    setPassword(e.target.value)
                  }}
                  value={password}
                  addonAfter={
                    <InvisibleButton
                      onClick={() => setShowPassword(!showPassword)}
                      aria-label="Afficher / Masquer le mot de passe"
                      type="button"
                    >
                      <Icon type={showPassword ? 'eye-invisible' : 'eye'} />
                    </InvisibleButton>
                  }
                />
              </Form.Item>

              <Button
                theme={Theme.PRIMARY}
                block
                uppercase
                size={Size.LARGE}
                htmlType="submit"
                className={styles.submit}
                disabled={!password || !token || Object.values(formik.errors).length > 0}
              >
                <FormattedMessage id="resetpassword.submit" />
              </Button>
            </Form>
          )}
        </Formik>
      )}
    </ConnectionLayout>
  )
}

const mapStateToProps = (state: IRootState) => ({
  queryParams: parse(state.router.location.search.replace(/^\?/, '')),
  authError: state.authState.error
})

const mapDispatchToProps = (dispatch: ThunkDispatch<IRootState, {}, AnyAction>) => ({
  resetPassword: (token: string, password: string) => dispatch(auth.resetPassword(token, password))
})

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword)
