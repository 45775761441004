import { Button, Modal, Size, Theme } from '@addsome/ui-kit'
import React, { FC } from 'react'
import { RenderAnimation } from '../PlayerIcons'
import styles from './RenderModal.module.scss'

interface IProps {
  visible: boolean
  onRedirect: () => void
  onCancel: () => void
}

const RenderModal: FC<IProps> = ({ visible, onRedirect, onCancel }) => {
  return (
    <Modal
      visible={visible}
      mask={false}
      footer={false}
      width={457}
      centered
      closable={false}
      className={styles.container}
    >
      <div className={styles.spinnerContainer}>
        <RenderAnimation className={styles.icon} />
      </div>
      <h1>Edition de votre visuel en cours…</h1>
      <h2>
        Dans quelques instants votre visuel sera disponible
        <br />
        sur la page de votre projet...
      </h2>
      <div className={styles.actions}>
        <Button onClick={onRedirect} theme={Theme.PRIMARY} size={Size.LARGE} block uppercase>
          Accéder à mes visuels
        </Button>
        <Button onClick={onCancel} theme={Theme.WHITE} size={Size.LARGE} block uppercase>
          Revenir à ma scène
        </Button>
      </div>
    </Modal>
  )
}

export default RenderModal
