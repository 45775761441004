import { IPaginationRequest, scene as sceneActions } from '@addsome/redux-store'
import { ICardData, InfiniteGridWithSearch, Popconfirm } from '@addsome/ui-kit'
import { push } from 'connected-react-router'
import moment from 'moment'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { WrappedComponentProps, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router-dom'
import { AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import trashIcon from '../../assets/images/icons/trash-white.png'
import ProjectLayout from '../../components/Projects/Layout'
import EmptyCategory from '../../components/Projects/Project/Empty'
import { IRootState } from '../../redux'
import { getCloudImageUrl } from '../../utils'
import styles from './Ambiances.module.scss'

interface IMatchParams {
  id: string
}

type IProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  WrappedComponentProps &
  RouteComponentProps<IMatchParams>

const Ambiances: React.FC<IProps> = ({
  scenes,
  fetchScenes,
  match,
  intl,
  project,
  pushRouter,
  total,
  deleteScene
}) => {
  const [loaded, setLoaded] = useState(false)
  const [empty, setEmpty] = useState(true)

  useEffect(() => {
    fetchScenes(match.params.id, intl.formatMessage({id: 'scenes.errorRetrievingScenes'})).then(() => setLoaded(true))
  }, [match.params.id])

  useEffect(() => {
    if (empty && scenes.length) {
      setEmpty(false)
    }
  }, [scenes])

  const scenesForList: ICardData[] = useMemo(() => {
    return scenes.map(({ name, description: desc, picture, updatedAt, ...rest }) => {
      let image = null

      if (picture && picture.url) {
        image = getCloudImageUrl(picture.url, 400)
      }

      return {
        ...rest,
        title: name,
        desc,
        footer: `${intl.formatMessage({ id: 'global.updatedAt' })} ${moment(updatedAt).format(
          'DD/MM à hh:mm'
        )}`,
        image
      }
    })
  }, [scenes])

  const fetchItems = useCallback(
    (
      filters: { [key: string]: string[] },
      renew: boolean,
      skip: number,
      order?: { [key: string]: 'ASC' | 'DESC' }
    ) => {
      if (!project) return Promise.resolve()
      return fetchScenes(project.id,intl.formatMessage({id: 'scenes.errorRetrievingScenes'}), { skip, filters, order }, !renew)
    },
    [fetchScenes, project]
  )

  const goToScene = useCallback(
    (scene: ICardData) => {
      pushRouter(`/projects/${match.params.id}/scene/${scene.id}`)
    },
    [pushRouter, match]
  )

  const handleDeleteConfirm = useCallback(
    (sceneData: ICardData) => {
      deleteScene(match.params.id, sceneData.id, intl.formatMessage({id: 'scenes.deletedScene'}), intl.formatMessage({id :'scenes.errorDeletingScene'}),
      intl.formatMessage({id: 'scenes.errorRetrievingScenes'}), {
        take: scenes.length - 1
      })
    },
    [match.params.id, deleteScene, scenes]
  )

  const renderTopIcons = useCallback(
    (scene: ICardData) => {
      return (
        <div className={styles.deleteContainer}>
          <Popconfirm
            title={intl.formatMessage({ id: 'ambiances.remove' })}
            onConfirm={() => handleDeleteConfirm(scene)}
            okText={intl.formatMessage({ id: 'global.yes' })}
            cancelText={intl.formatMessage({ id: 'global.no' })}
            placement="top"
          >
            <img className={styles.icon} src={trashIcon} />
          </Popconfirm>
        </div>
      )
    },
    [handleDeleteConfirm]
  )

  return (
    <ProjectLayout activeTabKey="ambiances" hasContent={!empty}>
      {empty && loaded ? (
        <EmptyCategory />
      ) : (
        <InfiniteGridWithSearch
          orderItems={[
            {
              id: 1,
              label: '+ Récentes',
              orderKey: 'createdAt',
              order: 'DESC'
            },
            {
              id: 2,
              label: '- Récentes',
              orderKey: 'createdAt',
              order: 'ASC'
            }
          ]}
          className={styles.grid}
          headerClassName={styles.gridHeader}
          searchBarClassName={styles.searchBar}
          searchPlaceHolder={intl.formatMessage({ id: 'ambiances.searchAmbiance' })}
          title=""
          fetchItems={fetchItems}
          totalItems={total}
          doInitialFetch={false}
          cardsData={scenesForList}
          onCardClick={goToScene}
          inner
          ratio={[3, 2]}
          hoverText={intl.formatMessage({ id: 'global.open' })}
          renderTopIcons={renderTopIcons}
          loading={!loaded}
        />
      )}
    </ProjectLayout>
  )
}

const mapStateToProps = (state: IRootState) => ({
  project: state.projectState.value,
  scenes: state.sceneState.scenes,
  totalProjects: state.brandUserProjectState.total,
  user: state.userState.user,
  total: state.sceneState.total
})

const mapDispatchToProps = (dispatch: ThunkDispatch<IRootState, {}, AnyAction>) => ({
  pushRouter: (location: string) => dispatch(push(location)),
  fetchScenes: (projectId: string,errorRetrievingScenes?: string, request?: IPaginationRequest, concatenate?: boolean) =>
    dispatch(sceneActions.fetchScenes(projectId,errorRetrievingScenes ,request, concatenate)),
  deleteScene: (projectId: string, sceneId: string, deletedScene?: string, errorDeletingScene?: string, errorRetrievingScenes?: string, request?: IPaginationRequest) =>
    dispatch(sceneActions.deleteScene(projectId, sceneId,deletedScene,errorDeletingScene,errorRetrievingScenes, request))
})

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Ambiances))
