import React from 'react'
import { WrappedComponentProps, injectIntl } from 'react-intl'

import styles from './UnderConstructionPage.module.scss'

type IProps = WrappedComponentProps

const UnderConstructionPage: React.FC<IProps> = ({ intl }) => (
  <div className={styles.container}>
    {intl.formatMessage({ id: 'misc.constructionWelcome' })}
    <a className={styles.a} href="/">
      {intl.formatMessage({ id: 'misc.constructionGoHome' })}
    </a>
  </div>
)

export default injectIntl(UnderConstructionPage)
