import { Apps } from '@addsome/dtos'
import { auth } from '@addsome/redux-store'
import { Button, InputField, Size, Theme } from '@addsome/ui-kit'
import { Form } from 'antd'
import { Formik } from 'formik'
import React, { useCallback, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import * as yup from 'yup'
import { IRootState } from '../../../redux'
import styles from './RetrievePasswordForm.module.scss'

type IProps = ReturnType<typeof mapDispatchToProps>

const RetrievePasswordForm: React.FC<IProps> = ({ requestPasswordReset }) => {
  const [email, setEmail] = useState('')
  const [sent, setSent] = useState(false)

  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .required()
      .email()
  })

  const handleSubmit = useCallback(async () => {
    await requestPasswordReset(email)
    setSent(true)
    setEmail('')
  }, [email, requestPasswordReset])

  return (
    <Formik validationSchema={validationSchema} onSubmit={handleSubmit} initialValues={{ email }}>
      {formik => (
        <Form noValidate onSubmit={formik.handleSubmit} className={styles.retrieveForm}>
          <Form.Item required colon={false} label="Email">
            <InputField
              error={formik.errors.email}
              name="email"
              required
              onChange={e => {
                formik.handleChange(e)
                setEmail(e.target.value)
              }}
              value={email}
            />
          </Form.Item>

          <Button
            theme={Theme.PRIMARY}
            block
            uppercase
            size={Size.LARGE}
            htmlType="submit"
            className={styles.submit}
            disabled={!email || Object.values(formik.errors).length > 0}
          >
            <FormattedMessage id="forgottenpassword.submit" />
          </Button>

          {sent && (
            <div className={styles.success}>
              <FormattedMessage id="forgottenpassword.success" />
            </div>
          )}
        </Form>
      )}
    </Formik>
  )
}

const mapDispatchToProps = (dispatch: ThunkDispatch<IRootState, {}, AnyAction>) => ({
  requestPasswordReset: (email: string) => dispatch(auth.requestPasswordReset(Apps.Studio, email))
})

export default connect(undefined, mapDispatchToProps)(RetrievePasswordForm)
