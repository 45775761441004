import React from 'react'
import { useIntl } from 'react-intl'
import Form from '../../components/Login/Form'
import ConnectionLayout from '../../components/ConnectionLayout/ConnectionLayout'

const LoginPage = () => {
  const intl = useIntl()

  return (
    <ConnectionLayout
      title={
        <>
          {intl.formatMessage({ id: 'login.title' })}
          <br />
          Addsome Studio
        </>
      }
      subtitle={intl.formatMessage({ id: 'login.presentation' })}
    >
      <Form />
    </ConnectionLayout>
  )
}

export default LoginPage
