import { Icon } from 'antd'
import React, { FC } from 'react'
import { connect } from 'react-redux'
import style from './AppWrapper.module.scss'
import ProductViewerContainer from './components/Player/ProductViewer/ProductViewerContainer'
import { IRootState } from './redux'

type IProps = ReturnType<typeof mapStateToProps>

const AppWrapper: FC<IProps> = ({ children, token, accountType }) => (
  <>
    <ProductViewerContainer />
    {token && !accountType ? (
      <div className={style.loading}>
        <Icon type="loading" />
      </div>
    ) : (
      children
    )}
  </>
)

const mapStateToProps = (state: IRootState) => ({
  token: state.authState.token,
  accountType: state.authState.type
})

export default connect(mapStateToProps)(AppWrapper)
