import { ProjectPackshotBashEntryCreationDTO, ProjectPackshotCreationBashDTO } from '@addsome/dtos'
import { packshot as packshotActions, project as projectActions } from '@addsome/redux-store'
import { Button, Heading, InvisibleButton, Size, Theme } from '@addsome/ui-kit'
import { Icon } from 'antd'
import { goBack, push } from 'connected-react-router'
import React from 'react'
import { WrappedComponentProps, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router-dom'
import { AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import logo from '../../../../assets/images/icons/construction.svg'
import BlueButton from '../../../../components/Common/Elements/BlueButton'
import Layout from '../../../../components/Common/Layout'
import Modal from '../../../../components/Common/Modal'
import Viewer from '../../../../components/Products/Viewer'
import { IRootState } from '../../../../redux'
import styles from './Summary.module.scss'
import RenderSettings from '../../../../components/Common/RenderSetting'

const PACKSHOT_SCENE_BASE_CONTENT = {
  version: 1,
  display: { backgroundUid: process.env.REACT_APP_DEFAULT_BACKGROUND_ID },
  perspective: {
    pictureWidth: 1152,
    pictureHeight: 648,
    wallCount: 3,
    input0: { x: 288, y: 486.1 },
    input1: { x: 864, y: 486 },
    input2: { x: 288, y: 162 },
    input3: { x: 864, y: 162 },
    input4: { x: 172.8, y: 550.8 },
    input5: { x: 979.2, y: 550.8 },
    input6: { x: 172.8, y: 97.29999 },
    input7: { x: 979.2, y: 97.20001 },
    cameraFOV: 42,
    cameraPosition: { x: 2.1742, y: 1.2248, z: -6.3814 },
    cameraRotation: { x: -7.629395e-6, y: 0.02591189, z: 0.003777521 },
    roomHeight: 2.45,
    roomWidth: 0,
    roomDepth: 0
  },
  models: []
}

interface IMatchParams {
  id: string
}

type IProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  WrappedComponentProps &
  RouteComponentProps<IMatchParams>

class Summary extends React.PureComponent<IProps> {
  private modalRef = React.createRef<Modal>()

  public componentDidMount() {
    const {
      match: {
        params: { id }
      },
      project,
      products,
      pushRouter,
      fetchProject
    } = this.props

    if (!products || !products.length) {
      pushRouter('/')
      return
    }

    if (!project || project.id !== id) {
      fetchProject(id)
    }
  }

  public render() {
    const { intl, products, project, pushRouter } = this.props

    if (!products || !products.length) {
      return null
    }

    const projectId = project ? project.id : ''
    const productPicture = products && products[0].thumbnail ? products[0].thumbnail.media : null

    return (
      <Layout
        className={styles.layout}
        breadcrumbRoutes={[
          {
            breadcrumbName: intl.formatMessage({ id: 'breadcrumb.home' }),
            path: '/'
          },
          {
            breadcrumbName: project ? project.name : '',
            path: `/projects/${projectId}/products`
          },
          {
            breadcrumbName: intl.formatMessage({ id: 'visual.new.breadcrumbName' }),
            path: `/projects/${projectId}/visuals/new`
          },
          {
            breadcrumbName: intl.formatMessage({ id: 'packshot.settings.settings' }),
            path: `/projects/${projectId}/visuals/new/batch`
          }
        ]}
      >
        <Modal ref={this.modalRef}>
          <div className={styles.modal}>
            <div className={styles.spinLogo}>
              {/* <Icon type="loading" spin className={styles.spin} /> */}
              <img src={logo} className={styles.logo} />
            </div>
            <Heading level={2} strong className={styles.title}>
              {intl.formatMessage({ id: 'visual.modal.title' })}
            </Heading>
            <div className={styles.subtitle}>
              {intl.formatMessage({ id: 'visual.modal.subtitle' })}
            </div>
            <BlueButton
              labelIntlId={'visual.modal.ok'}
              onClick={() => pushRouter(`/projects/${projectId}/visuals`)}
            />
            <InvisibleButton
              className={styles.cancel}
              onClick={() => {
                this.modalRef.current!.hideModal()
                pushRouter(`/projects/${projectId}/products`)
              }}
            >
              {intl.formatMessage({ id: 'visual.modal.cancel' })}
            </InvisibleButton>
          </div>
        </Modal>

        <div className={styles.content}>
          <div className={styles.viewer}>
            <Viewer picture={productPicture ? productPicture : undefined} />
          </div>
          <div className={styles.summary}>
            <div className={styles.header}>
              <Heading level={3} strong>
                {intl.formatMessage({ id: 'packshot.summary.title' })}
              </Heading>
              <Button
                onClick={() =>
                  this.props.pushRouter(
                    `/projects/${this.props.match.params.id}/visuals/new/settings`
                  )
                }
                className={styles.addButton}
              >
                <div className={styles.buttonContent}>
                  <Icon className={styles.buttonIcon} type="plus-circle" theme="filled" />
                  {intl.formatMessage({ id: 'packshot.summary.add' })}
                </div>
              </Button>
            </div>
            <div className={styles.renderSettings}>
              {this.props.renderSettings.map(setting => (
                <RenderSettings
                  key={setting.id}
                  renderSetting={setting}
                  onClickDelete={this.props.removeRenderSettings}
                />
              ))}
            </div>
            <div className={styles.buttons}>
              <Button
                theme={Theme.DEFAULT}
                size={Size.LARGE}
                uppercase
                block
                onClick={() => {
                  this.props.unsetRenderSettings()
                  this.props.pushRouter(`/projects/${this.props.match.params.id}/visuals/new/batch`)
                }}
                className={styles.button}
              >
                {intl.formatMessage({ id: 'misc.cancel' })}
              </Button>
              <Button
                theme={Theme.BLACK}
                size={Size.LARGE}
                uppercase
                block
                onClick={this.handleConfirm}
                htmlType="submit"
                className={styles.button}
                disabled={this.props.renderSettings.length === 0}
              >
                {intl.formatMessage({ id: 'misc.confirm' })}
              </Button>
            </div>
          </div>
        </div>
      </Layout>
    )
  }

  private handleConfirm = () => {
    const renderSettings = this.props.renderSettings
    if (this.props.project) {
      let entries: ProjectPackshotBashEntryCreationDTO[]
      entries = renderSettings.reduce(
        (acc: any[], s) => [
          ...acc,
          ...this.props.settings.reduce(
            (acc: any[], setting) => [
              ...acc,
              ...setting.cameraIds.map(cameraId => {
                const camera = this.props.cameraTemplates.find(template => template.id === cameraId)
                if (!camera) return undefined
                return {
                  renderSettings: {
                    width: s.width,
                    height: s.height
                  },
                  baseContent: PACKSHOT_SCENE_BASE_CONTENT,
                  camera: camera.settings,
                  cameraName: camera.name,
                  objectProductVariationIds: setting.variationIds
                }
              }),
              // Custom camera
              ...(setting.customCamera
                ? [
                    {
                      renderSettings: {
                        width: s.width,
                        height: s.height
                      },
                      baseContent: PACKSHOT_SCENE_BASE_CONTENT,
                      camera: setting.customCamera,
                      cameraName: 'Caméra personnalisée',
                      objectProductVariationIds: setting.variationIds
                    }
                  ]
                : [])
            ],
            []
          )
        ],
        []
      )

      this.props.createProjectPackshotBash({
        projectId: this.props.project.id,
        entries
      }, this.props.intl.formatMessage({id: 'pachshots.errorCreatingPackshots'}))
      this.props.unsetRenderSettings()

      this.modalRef.current!.showModal()
    }
  }
}

const mapStateToProps = (state: IRootState) => ({
  project: state.projectState.value,
  products: state.packshotState.selectedProducts,
  settings: state.packshotState.productsSettings,
  renderSettings: state.packshotState.renderSettings,
  cameraTemplates: state.cameraTemplateState.values,
  customCamera: state.packshotState.customCamera
})

const mapDispatchToProps = (dispatch: ThunkDispatch<IRootState, {}, AnyAction>) => ({
  fetchProject: (id: string) => dispatch(projectActions.thunks.fetchValue(id)),
  pushRouter: (location: string) => dispatch(push(location)),
  goBackRouter: () => dispatch(goBack()),
  createProjectPackshotBash: (payload: ProjectPackshotCreationBashDTO, errorCreatingPackshots?: string) =>
    dispatch(packshotActions.createProjectPackshotBash(payload, errorCreatingPackshots)),
  unsetRenderSettings: () => dispatch(packshotActions.unsetRenderSettings()),
  removeRenderSettings: (id: string) => dispatch(packshotActions.removeRenderSettings(id))
})

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Summary))
