import { album as albumsActions, IPaginationRequest } from '@addsome/redux-store'
import { ICardData, InfiniteGridWithSearch } from '@addsome/ui-kit'
import { push } from 'connected-react-router'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { WrappedComponentProps, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router-dom'
import { AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import ProjectLayout from '../../components/Projects/Layout'
import EmptyCategory from '../../components/Projects/Project/Empty'
import { IRootState } from '../../redux'
import { getCloudImageUrl } from '../../utils'
import styles from './Visuals.module.scss'

interface IMatchParams {
  id: string
}

type IProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  RouteComponentProps<IMatchParams> &
  WrappedComponentProps

const Visuals: React.FC<IProps> = ({
  fetchAlbums,
  albums,
  totalAlbums,
  project,
  intl,
  pushRouter,
  match: {
    params: { id }
  }
}) => {
  const [loaded, setLoaded] = useState(false)
  const [empty, setEmpty] = useState(true)

  useEffect(() => {
    if (!project) return
    fetchAlbums(project.id, intl.formatMessage({id:'albums.errorRetrievingAlbums'})).then(() => setLoaded(true))
  }, [project])

  useEffect(() => {
    if (empty && albums.length) {
      setEmpty(false)
    }
  }, [albums])

  const albumsForGrid: ICardData[] = useMemo(() => {
    const resizePicture = (url: string) => getCloudImageUrl(url, 400)

    return albums.map(album => {
      const { typeId, name, media, ...rest } = album
      let image = null

      if (media && media.url) {
        image = resizePicture(media.url)
      }

      return {
        ...rest,
        id: typeId,
        title: name,
        image
      }
    })
  }, [albums])

  const fetchItems = useCallback(
    (filters: { [key: string]: string[] }, renew: boolean, skip: number) => {
      if (!project) return Promise.resolve()
      return fetchAlbums(project.id, intl.formatMessage({id:'albums.errorRetrievingAlbums'}),{ skip, filters }, !renew)
    },
    [fetchAlbums, project]
  )

  const goToVisual = useCallback(
    (visual: ICardData) => {
      const album = albums.find(a => a.typeId === visual.id)
      pushRouter(`/projects/${id}/visual/${visual.id}/type/${album && album.type}`)
    },
    [pushRouter, albums]
  )

  return (
    <ProjectLayout activeTabKey="visuals" hasContent={!empty}>
      {empty && loaded ? (
        <EmptyCategory />
      ) : (
        <InfiniteGridWithSearch
          headerClassName={styles.gridHeader}
          title=""
          searchPlaceHolder={intl.formatMessage({ id: 'visuals.searchAlbum' })}
          fetchItems={fetchItems}
          totalItems={totalAlbums}
          doInitialFetch={false}
          onCardClick={goToVisual}
          cardsData={albumsForGrid}
          loading={!loaded}
          shadow
          hoverText={intl.formatMessage({ id: 'global.open' })}
        />
      )}
    </ProjectLayout>
  )
}

const mapStateToProps = ({
  albumState: { albums, total: totalAlbums },
  projectState: { value: project }
}: IRootState) => ({
  albums,
  totalAlbums,
  project
})

const mapDispatchToProps = (dispatch: ThunkDispatch<IRootState, {}, AnyAction>) => ({
  fetchAlbums: (projectId: string, errorRetrievingAlbums?: string, request: IPaginationRequest = {}, concatenate = false) =>
    dispatch(albumsActions.fetchAlbums(projectId,errorRetrievingAlbums ,request, concatenate)),
  pushRouter: (location: string) => dispatch(push(location))
})

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Visuals))
