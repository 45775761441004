import { ProjectSceneDTO, ProjectSceneUpdateDTO, RenderCreationDTO } from '@addsome/dtos'
import { Loading } from '@addsome/ui-kit'
import Modal from 'antd/lib/modal'
import React, { Component } from 'react'
import { WrappedComponentProps, injectIntl } from 'react-intl'
import Unity, { UnityContent } from 'react-unity-webgl'
import settings from '../../../settings'
import PlayerCatalog from './components/PlayerCatalog'
import { RoomHeight } from './components/PlayerIcons'
import PlayerPopupMeasure from './components/PlayerPopupMeasure/PlayerPopupMeasure'
import PlayerProduct from './components/PlayerProduct'
import PlayerSaveAndQuit from './components/PlayerSaveAndQuit'
import PlayerToolbar from './components/PlayerToolbar/PlayerToolbar'
import RecalibrateActions from './components/RecalibrateActions'
import RenderModal from './components/RenderModal'
import RoomCalibrate from './components/RoomCalibrate'
import { ApiPlayerName, playerBackground } from './constants'
import { ApiMethod, EditorMode, TypeAction } from './enums'
import styles from './SceneEditor.module.scss'

export interface ISceneEditorProps {
  token: string
  projectId: string
  productIds?: string[]
  variationIndex?: string
  scene: ProjectSceneDTO
  onUpdateScene: (scene: ProjectSceneUpdateDTO) => void
  onSaveTemplate: (scene: ProjectSceneUpdateDTO) => void
  onCreateRender: (render: RenderCreationDTO) => void
  onRedirect: (url: string) => void
}

interface IState {
  isDraft: boolean
  isToolbarTop: boolean
  isTopView: boolean
  isCatalogOpen: boolean
  isCatalogFullScreen: boolean
  currentTypeAction: TypeAction
  currentSubAction: TypeAction
  currentEditorMode: EditorMode
  selectedProductId?: string
  modalProductId?: string
  displaySaveAndQuit: boolean
  productLoading: boolean
}

class SceneEditor extends Component<ISceneEditorProps & WrappedComponentProps, IState> {
  public state: IState = {
    isDraft: false,
    isToolbarTop: false,
    isTopView: false,
    isCatalogOpen: false,
    isCatalogFullScreen: false,
    displaySaveAndQuit: false,
    currentTypeAction: TypeAction.None,
    currentSubAction: TypeAction.None,
    currentEditorMode: EditorMode.Loading,
    productLoading: false
  }

  public unityContent = new UnityContent(
    `${window.location.origin}/AddsomeWebPlayer/Build/AddsomeWebPlayer.json`,
    `${window.location.origin}/AddsomeWebPlayer/Build/UnityLoader.js`,
    { adjustOnWindowResize: true }
  )

  public componentDidMount() {
    window.addEventListener('beforeunload', this.handleBrowserUnload)
    this.addEventListener('WebPlayerAPI_OnWebPlayerReadyForInit', this.setApiInfos)
    this.addEventListener('WebPlayerAPI_OnWebPlayerReady', this.initScene)
    this.addEventListener('WebPlayerAPI_OnCalibrationStarted', this.handleCalibrationStarted)
    this.addEventListener('WebPlayerAPI_OnSceneVersionReceived', this.handleSceneVersionReceived)
    this.addEventListener('WebPlayerAPI_OnSceneLoaded', this.handleSceneLoaded)
    this.addEventListener('WebPlayerAPI_OnCalibrationPerformed', this.handleCalibrationPerformed)
    this.addEventListener('WebPlayerAPI_OnHasSceneContentChanged', this.handleSceneContentChanged)
    this.addEventListener('WebPlayerAPI_OnItemSelected', this.handleItemSelected)
    this.addEventListener('WebPlayerAPI_OnPlayerProductAdded', this.handleProductAdded)
    this.addEventListener('WebPlayerAPI_OnPlayerProductsAdded', this.handleProductsAdded)
  }

  public componentWillUnmount() {
    window.removeEventListener('beforeunload', this.handleBrowserUnload)
    this.unityContent.remove()
  }

  public render() {
    const {
      isToolbarTop,
      isDraft,
      currentTypeAction,
      currentEditorMode,
      currentSubAction,
      selectedProductId,
      isTopView,
      isCatalogOpen,
      isCatalogFullScreen,
      modalProductId,
      displaySaveAndQuit,
      productLoading
    } = this.state
    return (
      <div className={styles.container}>
        {currentEditorMode === EditorMode.Loading && (
          <div className={styles.loading}>
            <Loading subtitle={this.props.intl.formatMessage({ id: 'player.loading' })} />
          </div>
        )}
        {productLoading && (
          <div className={styles.productLoading}>
            <Loading subtitle={this.props.intl.formatMessage({ id: 'player.productLoading' })} />
          </div>
        )}
        <Unity unityContent={this.unityContent} />
        {currentEditorMode === EditorMode.Default && (
          <>
            <PlayerToolbar
              currentTypeAction={currentTypeAction}
              currentSubAction={currentSubAction}
              isDraft={isDraft}
              isTopView={isTopView}
              onAction={this.handleAction}
              onSubAction={this.handleSubAction}
              onSwitchPosition={this.toggleToolbarTop}
              isToolbarTop={isToolbarTop}
              selectedProductId={selectedProductId}
            />
            <PlayerCatalog
              onAddProducts={this.handleAddProducts}
              onClickProduct={this.handleCatalogProductClicked}
              isOpen={isCatalogOpen}
              isFullScreen={isCatalogFullScreen}
              onClose={this.toggleCatalogOpen}
              onClickFullScreen={this.toggleCatalogFullScreen}
              isToolbarTop={isToolbarTop}
            />
            <PlayerProduct
              productId={modalProductId}
              onAddProduct={this.handleAddProduct}
              onCancel={this.unsetModalProductId}
              displayActions={isCatalogOpen}
            />
          </>
        )}
        {currentEditorMode === EditorMode.Calibration && (
          <RoomCalibrate currentTypeAction={currentTypeAction} onValidate={this.handleMethod} />
        )}
        <RecalibrateActions
          isOpen={currentTypeAction === TypeAction.Recalibrate}
          onValidate={this.handleMethod}
        />
        <PlayerPopupMeasure
          visible={currentTypeAction === TypeAction.ChangeHeight}
          onValidate={this.handleMethod}
          method={ApiMethod.RecalibrateFromRoomHeight}
          text="Hauteur de la pièce"
          icon={<RoomHeight />}
          closable={true}
          maskClosable={true}
          onCancel={() => {
            this.sendMessage(ApiPlayerName, ApiMethod.EnableKeyboardInput)
            this.setState({
              currentTypeAction: TypeAction.None,
              currentEditorMode: EditorMode.Default
            })
          }}
        />
        <PlayerSaveAndQuit
          visible={displaySaveAndQuit}
          onSaveAndQuit={this.handleSaveAndQuit}
          onQuitWithoutSaving={this.handleQuitWithoutSaving}
          onCancel={this.handleCancel}
        />
        <RenderModal
          visible={currentTypeAction === TypeAction.Rendering}
          onRedirect={() => this.props.onRedirect(`/projects/${this.props.projectId}/visuals`)}
          onCancel={() => this.setState({ currentTypeAction: TypeAction.None })}
        />
      </div>
    )
  }

  private handleAddProducts = (productIds: string[]) => {
    this.setState({ productLoading: true })
    this.sendMessage(ApiPlayerName, ApiMethod.AddPlayerProducts, {
      products: productIds.map(id => ({ productId: id }))
    })
    this.toggleCatalogOpen()
  }

  private handleSaveAndQuit = () => this.handleMethod(ApiMethod.GetSceneVersion)

  private handleCancel = () =>
    this.setState({ isDraft: true, currentTypeAction: TypeAction.None, displaySaveAndQuit: false })

  private handleQuitWithoutSaving = () =>
    this.props.onRedirect(`/projects/${this.props.projectId}/ambiances`)

  private sendMessage = (
    gameObjectName: string,
    methodName: string,
    parameter?: any,
    isStringFormated?: boolean
  ) =>
    this.unityContent.send(
      gameObjectName,
      methodName,
      isStringFormated ? parameter : JSON.stringify(parameter)
    )

  private addEventListener = (eventName: string, eventCallback: (e?: any) => void) =>
    this.unityContent.on(eventName, eventCallback)

  private toggleToolbarTop = () => this.setState({ isToolbarTop: !this.state.isToolbarTop })

  private toggleTopView = () =>
    this.setState({ isTopView: !this.state.isTopView }, () =>
      this.state.isTopView
        ? this.handleMethod(ApiMethod.UseOrbitalCamera)
        : this.handleMethod(ApiMethod.UseFrontCamera)
    )

  private toggleCatalogOpen = () => {
    this.setState({
      isCatalogOpen: !this.state.isCatalogOpen,
      currentTypeAction: this.state.isCatalogOpen ? TypeAction.None : TypeAction.Catalog,
      isCatalogFullScreen: false
    })
    // We enable player keyboard inputs
    this.handleMethod(ApiMethod.EnableKeyboardInput)
  }

  private toggleCatalogFullScreen = () => {
    // If we open the catalog in fullscreen we disable player keyboard inputs
    if (this.state.isCatalogFullScreen) {
      this.handleMethod(ApiMethod.EnableKeyboardInput)
    } else this.handleMethod(ApiMethod.DisableKeyboardInput)

    this.setState({ isCatalogFullScreen: !this.state.isCatalogFullScreen })
  }

  private handleAddProduct = (productId: string, variationIndex: number) => {
    this.setState({ productLoading: true })
    this.sendMessage(ApiPlayerName, ApiMethod.AddPlayerProduct, { productId, variationIndex })
    this.unsetModalProductId()
    this.toggleCatalogOpen()
  }

  private fullScreen = () => {
    const fullScreenElement = document.fullscreenElement

    if (fullScreenElement) {
      document.exitFullscreen()
    } else {
      const elem = document.documentElement
      elem.requestFullscreen()
    }
  }

  private setModalProductId = (productId: string) => this.setState({ modalProductId: productId })

  private unsetModalProductId = () => this.setState({ modalProductId: undefined })

  private handleCatalogProductClicked = (productId: string) => {
    if (this.state.isCatalogFullScreen) this.setModalProductId(productId)
    else this.handleAddProduct(productId, 0)
  }

  // Handle Player API calls
  private handleMethod = (method: ApiMethod, value?: string) => {
    switch (method) {
      case ApiMethod.CalibrationHeight:
        this.sendMessage(ApiPlayerName, ApiMethod.CalibrationHeight, {
          roomHeight: value
        })
        this.setState({ currentTypeAction: TypeAction.Area })
        return
      case ApiMethod.RecalibrateFromRoomHeight:
        this.sendMessage(ApiPlayerName, ApiMethod.RecalibrateFromRoomHeight, {
          roomHeight: value
        })
        this.sendMessage(ApiPlayerName, ApiMethod.EnableKeyboardInput)
        this.setState({
          isDraft: true,
          currentTypeAction: TypeAction.None,
          currentEditorMode: EditorMode.Default
        })
        return
      case ApiMethod.CalibrationArea:
        this.sendMessage(ApiPlayerName, ApiMethod.CalibrationArea, {
          roomArea: value
        })
        this.sendMessage(ApiPlayerName, ApiMethod.EnableKeyboardInput)
        this.setState({ currentTypeAction: TypeAction.WallCount })
        return
      case ApiMethod.ValidateRecalibrationFromPoints:
        this.sendMessage(ApiPlayerName, ApiMethod.ValidateRecalibrationFromPoints)
        this.setState({
          isDraft: true,
          currentTypeAction: TypeAction.None,
          currentEditorMode: EditorMode.Default
        })
        return
      case ApiMethod.CancelRecalibrationFromPoints:
        this.sendMessage(ApiPlayerName, ApiMethod.CancelRecalibrationFromPoints)
        this.setState({ currentTypeAction: TypeAction.None, currentEditorMode: EditorMode.Default })
        return
      case ApiMethod.CalibrationWallCount:
        this.sendMessage(ApiPlayerName, ApiMethod.CalibrationWallCount, {
          wallCount: value
        })
        return
      case ApiMethod.ValidateCalibration:
        this.sendMessage(ApiPlayerName, ApiMethod.ValidateCalibration)
        this.setState({ currentEditorMode: EditorMode.Default, isDraft: true })
        return
      case ApiMethod.ResetScale:
        this.sendMessage(ApiPlayerName, ApiMethod.ResetScale)
        this.sendMessage(ApiPlayerName, ApiMethod.HasSceneContentChanged)
        return
      default:
        this.sendMessage(ApiPlayerName, method)
        return
    }
  }

  // Handle Editor main action
  private handleAction = (action: TypeAction) => {
    this.setState({ currentTypeAction: action })
    switch (action) {
      case TypeAction.Catalog:
        this.toggleCatalogOpen()
        return
      case TypeAction.Save:
        this.handleSave()
        return
      case TypeAction.Quit:
        // We call handleQuit in setState callback to make sure the state is updated
        this.setState({ currentTypeAction: TypeAction.Quit }, () => this.handleQuit())
        return
      case TypeAction.Undo:
        this.handleMethod(ApiMethod.Undo)
        this.handleMethod(ApiMethod.HasSceneContentChanged)
        this.setState({ currentTypeAction: TypeAction.None })
        return
      case TypeAction.Redo:
        this.handleMethod(ApiMethod.Redo)
        this.handleMethod(ApiMethod.HasSceneContentChanged)
        this.setState({ currentTypeAction: TypeAction.None })
        return
      case TypeAction.FullScreen:
        this.fullScreen()
        return
      case TypeAction.TopView:
        this.toggleTopView()
        return
      case TypeAction.Recalibrate:
        this.handleMethod(ApiMethod.ShowCalibrationPoints)
        if (this.state.isTopView) this.toggleTopView()
        this.setState({ currentEditorMode: EditorMode.Action })
        return
      case TypeAction.ChangeHeight:
        // We disable keyboard input in the player to fill the height input
        this.sendMessage(ApiPlayerName, ApiMethod.DisableKeyboardInput)
        if (this.state.isTopView) this.toggleTopView()
        this.setState({ currentEditorMode: EditorMode.Action })
        return
      case TypeAction.Rendering:
        // We call the method in setState callback to make sure the state is updated
        this.setState({ currentTypeAction: TypeAction.Rendering }, () =>
          this.handleMethod(ApiMethod.GetSceneVersion)
        )
        return
      default:
        this.setState({ currentTypeAction: TypeAction.None })
        return
    }
  }

  // Handle Editor subaction
  private handleSubAction = (subaction: TypeAction) => {
    this.setState({ currentSubAction: subaction })
    switch (subaction) {
      case TypeAction.ObjectInfos:
        this.setState({
          modalProductId: this.state.selectedProductId,
          currentTypeAction: TypeAction.None
        })
        return
      case TypeAction.ObjectScale:
        this.handleMethod(ApiMethod.ResetScale)
        this.setState({ currentSubAction: TypeAction.None })
        return
      case TypeAction.ObjectDuplicate:
        this.handleMethod(ApiMethod.DuplicateSelected)
        this.setState({ currentSubAction: TypeAction.None })
        return
      case TypeAction.ObjectVariation:
        this.handleMethod(ApiMethod.NextPlayerVariationIndex)
        this.setState({ currentSubAction: TypeAction.None })
        return
      case TypeAction.ObjectMultiSelect:
        this.handleMethod(ApiMethod.StartMultiSelection)
        return
      case TypeAction.ObjectDelete:
        this.handleMethod(ApiMethod.DeleteSelected)
        this.handleMethod(ApiMethod.HasSceneContentChanged)
        this.setState({ selectedProductId: undefined, currentSubAction: TypeAction.None })
        return
      default:
        this.setState({ currentSubAction: TypeAction.None })
        return
    }
  }

  private handleSave = () => this.handleMethod(ApiMethod.GetSceneVersion)

  private handleQuit = () => this.handleMethod(ApiMethod.HasSceneContentChanged)

  private setPlayerBackground = (color: string) =>
    this.sendMessage(ApiPlayerName, ApiMethod.SetPlayerBackgroundColor, { color })

  /*
   ** Unity Player Events Handlers
   */

  private handleProductAdded = () => this.setState({ productLoading: false })

  private handleProductsAdded = () => this.setState({ productLoading: false })

  private setApiInfos = () =>
    this.sendMessage(ApiPlayerName, ApiMethod.Init, {
      url: settings.api.baseUrl,
      token: this.props.token
    })

  private initScene = () => {
    const { scene } = this.props
    if (!scene.picture) throw new Error('Scene picture does not exist')

    if (!(scene.latestVersion && scene.latestVersion.content)) {
      // If scene content is null, we initialize the scene with the calibration from scene picture
      this.setState({ currentEditorMode: EditorMode.Calibration })
      this.sendMessage(ApiPlayerName, ApiMethod.Calibrate, {
        pictureId: scene.picture.id
      })
    } else {
      // We load the scene from content
      this.sendMessage(
        ApiPlayerName,
        ApiMethod.LoadSceneFromProjectSceneContent,
        scene.latestVersion.content,
        true
      )
    }
  }

  private handleCalibrationStarted = () => {
    // We set the current type action to Height to display the height modal
    this.setState({ currentTypeAction: TypeAction.Height })
    // We disable keyboard input in the player to fill the calibration inputs
    this.sendMessage(ApiPlayerName, ApiMethod.DisableKeyboardInput)
    // We set a white background
    this.setPlayerBackground(playerBackground)
  }

  private handleSceneLoaded = () => {
    // We set the editor to default mode to display the toolbar
    this.setState({ currentEditorMode: EditorMode.Default })
    // We set a white background
    this.setPlayerBackground(playerBackground)

    // If productIds is defined, that means we need to add products from selections
    if (this.props.productIds !== undefined) {
      this.sendMessage(ApiPlayerName, ApiMethod.AddPlayerProducts, {
        products: this.props.productIds.map(id => ({ productId: id }))
      })
    }
  }

  private handleCalibrationPerformed = () => {
    // We set a white background
    this.setPlayerBackground(playerBackground)
    if (this.state.currentTypeAction === TypeAction.Recalibrate) {
      // If we recalibrate the room, we only set editor to draft
      this.setState({ isDraft: true, currentTypeAction: TypeAction.None })
    } else {
      // We save the scene content and create a template
      this.handleMethod(ApiMethod.GetSceneVersion)
    }
  }

  private handleSceneVersionReceived = (e?: CustomEvent<any>) => {
    const { scene, onUpdateScene, onSaveTemplate, onCreateRender } = this.props
    if (!e) return
    const { content, products, cameraViewpoint } = JSON.parse(e.detail)
    if (this.state.currentEditorMode === EditorMode.Calibration) {
      // If we get the scene content after calibration we save the scene as a template
      onSaveTemplate({
        ...scene,
        pictureId: (scene.picture && scene.picture.id) || undefined,
        version: {
          camera: cameraViewpoint,
          // We do not need products for the template
          products: [],
          content: JSON.stringify(content)
        }
      })

      // If productIds is defined, that means we need to add products from selections
      if (this.props.productIds !== undefined) {
        this.sendMessage(ApiPlayerName, ApiMethod.AddPlayerProducts, {
          products: this.props.productIds.map(id => ({ productId: id }))
        })
      }
    } else {
      // We update the scene
      onUpdateScene({
        ...scene,
        version: {
          camera: cameraViewpoint,
          products: products.map((product: any) => ({
            productId: product.id,
            variationId: product.variationId
          })),
          content: JSON.stringify(content)
        }
      })
    }

    // If we saved before quit we redirect to the project scenes
    if (this.state.currentTypeAction === TypeAction.Quit) {
      this.props.onRedirect(`/projects/${this.props.projectId}/ambiances`)
      // If current action is rendering, we create a render
    } else if (this.state.currentTypeAction === TypeAction.Rendering) {
      onCreateRender({
        camera: cameraViewpoint,
        content,
        renderSettings: { width: 1920, height: 1080 }
      })
    } else this.setState({ currentTypeAction: TypeAction.None, isDraft: false })
  }

  private handleSceneContentChanged = (e?: CustomEvent<any>) => {
    if (!e) return
    // We get the boolean from the custom event
    const { sceneContentHasChanged } = JSON.parse(e.detail)

    if (sceneContentHasChanged) {
      if (this.state.currentTypeAction === TypeAction.Quit) {
        // We open the save and quit modal
        this.setState({ displaySaveAndQuit: true })
      } else {
        // We set the editor to draft state
        this.setState({ isDraft: true })
      }
    } else {
      if (this.state.currentTypeAction === TypeAction.Quit) {
        Modal.confirm({
          centered: true,
          icon: <></>,
          title: this.props.intl.formatMessage({ id: 'player.quit' }),
          onOk: () => this.props.onRedirect(`/projects/${this.props.projectId}/ambiances`),
          onCancel: () => this.setState({ currentTypeAction: TypeAction.None }),
          okText: this.props.intl.formatMessage({ id: 'global.yes' }),
          cancelText: this.props.intl.formatMessage({ id: 'global.no' }),
          okButtonProps: { className: styles.okButton },
          cancelButtonProps: { className: styles.cancelButton }
        })
      } else this.setState({ isDraft: false })
    }
  }

  private handleItemSelected = (e?: CustomEvent<any>) => {
    // Check if scene content has changed
    this.handleMethod(ApiMethod.HasSceneContentChanged)

    // Get selected object
    if (!e) return
    const { type, id } = JSON.parse(e.detail)

    if (type) {
      if (type === 'Product') {
        // We reset the action and make sur the catalog is closed to select a product
        this.setState({
          selectedProductId: id,
          isCatalogOpen: false,
          currentTypeAction: TypeAction.None
        })
      }
    } else this.setState({ currentSubAction: TypeAction.None, selectedProductId: undefined })
  }

  private handleBrowserUnload = (e: BeforeUnloadEvent) => {
    e.preventDefault()
  }
}

export default injectIntl(SceneEditor)
