import { ProductLightExtendedDTO } from '@addsome/dtos'
import { packshot as packshotActions, productViewer } from '@addsome/redux-store/dist'
import { IProductSetting } from '@addsome/redux-store/dist/store/packshot'
import { Button, Loading, Size, Theme } from '@addsome/ui-kit'
import { goBack as goBackAction, push } from 'connected-react-router'
import React, { FC, useCallback, useEffect, useState } from 'react'
import { WrappedComponentProps, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import { IRootState } from '../../../redux'
import Gallery from '../Gallery/Gallery'
import Informations from '../Informations/Informations'
import PackshotVariations from '../PackshotVariations'
import styles from './ProductSheet.module.scss'

export enum SheetTypes {
  SHEET = 'SHEET',
  BATCH = 'BATCH'
}

interface IMatchParams {
  id: string
}

type IProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  WrappedComponentProps &
  RouteComponentProps<IMatchParams> & {
    type: SheetTypes
    products?: ProductLightExtendedDTO[]
    loading?: boolean
    projectId?: string
  }

const ProductSheet: FC<IProps> = ({
  intl,
  pushRouter,
  goBack,
  type,
  products,
  loading,
  match: {
    params: { id }
  },
  setViewerProductId,
  setViewerVariationIndex,
  setSelectedProducts,
  setDisplayViewer,
  projectId,
  productsSettings,
  selectedProducts,
  setProductsSettings,
  setDisplayActions,
  displayActions,
  customCamera,
  setCustomCamera
}) => {
  const [selectedProductIndex, setSelectedProductIndex] = useState(0)

  useEffect(() => {
    if (products && products.length > 0) {
      setViewerProductId(products[selectedProductIndex].id)
      setViewerVariationIndex(0)
    }
  }, [
    products,
    selectedProductIndex,
    setDisplayViewer,
    setViewerProductId,
    setViewerVariationIndex,
    type
  ])

  useEffect(() => {
    switch (type) {
      case SheetTypes.SHEET:
        if (!loading && (!products || products.length !== 1)) {
          pushRouter('/')
        }
        break
      case SheetTypes.BATCH:
        if (!products || products.length === 0) {
          pushRouter(id ? `/projects/${id}/visuals/new` : '/')
        }
        break
    }
  }, [type, products, goBack, loading, pushRouter, id])

  const goToSettings = useCallback(() => {
    setDisplayViewer(false)
    pushRouter(id ? `/projects/${id}/visuals/new/settings` : '/')
  }, [setDisplayViewer, pushRouter, id])

  const handleProductDelete = useCallback(
    index => {
      if (products) {
        const newProducts = [...products]
        newProducts.splice(index, 1)
        setSelectedProducts(newProducts)
        setProductsSettings(
          productsSettings.filter(productSetting =>
            newProducts.find(p => p.id === productSetting.productId)
          )
        )
      }
    },
    [products, productsSettings, setProductsSettings, setSelectedProducts]
  )

  /*
   * Open player for selecting a new visual
   */
  const selectCustomCameraForProduct = useCallback(
    (index: number) => {
      if (displayActions && selectedProductIndex === index) {
        setDisplayActions(false)
      } else {
        setSelectedProductIndex(index)
        setDisplayViewer(true)
        setDisplayActions(true)
      }
    },
    [displayActions, selectedProductIndex, setDisplayActions, setDisplayViewer]
  )

  /*
   * When user select a new camera, update product settings
   */
  useEffect(() => {
    if (customCamera && displayActions && selectedProductIndex > -1) {
      setProductsSettings(
        productsSettings.map((productSetting, index) => {
          if (index === selectedProductIndex) {
            return { ...productSetting, customCamera }
          }
          return productSetting
        })
      )
      setDisplayActions(false)
      setCustomCamera(null)
    }
  }, [
    customCamera,
    displayActions,
    productsSettings,
    selectedProductIndex,
    setCustomCamera,
    setDisplayActions,
    setProductsSettings
  ])

  const selectForVisual = useCallback(() => {
    if (!products || !selectedProducts.find(selected => products.some(p => p.id === selected.id))) {
      setSelectedProducts([...selectedProducts, ...(products || [])])
    }

    setDisplayViewer(false)

    if (projectId) pushRouter(`/projects/${projectId}/visuals/new`)
  }, [products, projectId, pushRouter, selectedProducts, setDisplayViewer, setSelectedProducts])

  const isFormInvalid = useCallback((): boolean => {
    if (type === SheetTypes.SHEET) return false
    if (displayActions) return true

    // Set invalid until each product has at least one camera and one variation
    return productsSettings.reduce(
      (invalid: boolean, productSetting) =>
        invalid ||
        (productSetting.cameraIds.length === 0 && !productSetting.customCamera) ||
        productSetting.variationIds.length === 0,
      false
    )
  }, [displayActions, productsSettings, type])

  return (
    <div className={styles.container}>
      {!loading && products && products.length > 0 ? (
        <div className={styles.content}>
          <Gallery
            productId={products[selectedProductIndex] && products[selectedProductIndex].id}
          />
          <div className={styles.description}>
            <div className={styles.informationsWrapper}>
              {type === SheetTypes.BATCH ? (
                <PackshotVariations
                  selectedProductIndex={selectedProductIndex}
                  onProductSelect={setSelectedProductIndex}
                  onProductDelete={handleProductDelete}
                  readonly={displayActions}
                  onCustomCameraSelect={selectCustomCameraForProduct}
                  products={products}
                  productsSettings={productsSettings}
                />
              ) : (
                <Informations productId={products[0].id} type={type} />
              )}
            </div>
            <div className={styles.buttons}>
              {type !== SheetTypes.BATCH && (
                <Button
                  theme={Theme.DEFAULT}
                  size={Size.LARGE}
                  uppercase
                  block
                  onClick={() => {
                    setDisplayViewer(false)
                    goBack()
                  }}
                >
                  {intl.formatMessage({ id: 'misc.return' })}
                </Button>
              )}
              {!displayActions && (
                <Button
                  theme={Theme.BLACK}
                  size={Size.LARGE}
                  uppercase
                  block
                  onClick={type === SheetTypes.BATCH ? goToSettings : selectForVisual}
                  disabled={isFormInvalid()}
                >
                  {intl.formatMessage({
                    id:
                      type === SheetTypes.BATCH
                        ? 'misc.confirm'
                        : `product.${selectedProducts.length > 0 ? 'add' : 'select'}`
                  })}
                </Button>
              )}
            </div>
          </div>
        </div>
      ) : (
        <Loading />
      )}
    </div>
  )
}

ProductSheet.defaultProps = {
  loading: false
}

const mapStateToProps = (state: IRootState) => ({
  productsSettings: state.packshotState.productsSettings,
  selectedProducts: state.packshotState.selectedProducts,
  customCamera: state.packshotState.customCamera,
  displayActions: state.productViewerState.displayActions
})

const mapDispatchToProps = (dispatch: ThunkDispatch<IRootState, {}, AnyAction>) => ({
  pushRouter: (location: string) => dispatch(push(location)),
  goBack: () => dispatch(goBackAction()),
  setViewerProductId: (productId: string) => dispatch(productViewer.setProductId(productId)),
  setViewerVariationIndex: (variationIndex: number) =>
    dispatch(productViewer.setVariationIndex(variationIndex)),
  setSelectedProducts: (selectedProducts: ProductLightExtendedDTO[]) =>
    dispatch(packshotActions.setSelectedProducts(selectedProducts)),
  setDisplayViewer: (displayViewer: boolean) =>
    dispatch(productViewer.setDisplayViewer(displayViewer)),
  setDisplayActions: (displayActions: boolean) =>
    dispatch(productViewer.setDisplayActions(displayActions)),
  setProductsSettings: (productSettings: IProductSetting[]) =>
    dispatch(packshotActions.setProductsSettings(productSettings)),
  setCustomCamera: (camera: null | any) => dispatch(packshotActions.setCustomCamera(camera))
})

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(withRouter(ProductSheet)))
