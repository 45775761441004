import {
  AbstractRouterType,
  album,
  architect,
  auth,
  axiosInstance,
  brand,
  brandProjectSceneTemplate,
  brandTag,
  brandUser,
  brandUserProject,
  cameraTemplate,
  colorTag,
  designer,
  IAbstractRouter,
  ISharedRootState,
  metrics,
  notification,
  objectProduct,
  objectProductCategories,
  objectProductVariations,
  packshot,
  product,
  productMaterialTag,
  productPrices,
  productProblem,
  productViewer,
  project,
  render,
  roomTag,
  scene,
  setApiBaseUrl,
  styleTag,
  surfaceProduct,
  user
} from '@addsome/redux-store'
import { AxiosError } from 'axios'
import { connectRouter, push, routerMiddleware, RouterState } from 'connected-react-router'
import { createBrowserHistory, History } from 'history'
import { AnyAction, applyMiddleware, combineReducers, compose, createStore, Store } from 'redux'
import thunk, { ThunkDispatch } from 'redux-thunk'
import settings from '../settings'
import {createIntl} from 'react-intl'
import english from '../../src/translations/en.json'
import french from '../../src/translations/fr.json'


export type IRootState = ISharedRootState & {
  router: RouterState
}

export const abstractRouter: IAbstractRouter = {
  type: AbstractRouterType.Action,
  push
}

type CustomStore = Store<IRootState> & {
  dispatch: ThunkDispatch<IRootState, {}, AnyAction>
}

const reducers = (history: History) =>
  combineReducers({
    router: connectRouter(history),
    authState: auth.reducer,
    notificationState: notification.reducer,
    brandState: brand.reducer,
    albumState: album.reducer,
    architectState: architect.reducer,
    brandUserState: brandUser.reducer,
    brandUserProject: brandUserProject.reducer,
    packshotState: packshot.reducer,
    productState: product.reducer,
    productProblemState: productProblem.reducer,
    projectState: project.reducer,
    sceneState: scene.reducer,
    renderState: render.reducer,
    metricsState: metrics.reducer,
    userState: user.reducer,
    objectProductState: objectProduct.reducer,
    surfaceProductState: surfaceProduct.reducer,
    productViewerState: productViewer.reducer,
    objectProductVariationsState: objectProductVariations.reducer,
    designerState: designer.reducer,
    brandUserProjectState: brandUserProject.reducer,
    cameraTemplateState: cameraTemplate.reducer,
    brandProjectSceneTemplateState: brandProjectSceneTemplate.reducer,
    projectSceneState: scene.reducer,
    objectProductCategoriesState: objectProductCategories.reducer,
    productMaterialTagState: productMaterialTag.reducer,
    styleTagState: styleTag.reducer,
    colorTagState: colorTag.reducer,
    roomTagState: roomTag.reducer,
    brandTagState: brandTag.reducer,
    productPricesState: productPrices.reducer
  })

export const browserHistory = createBrowserHistory()

const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

setApiBaseUrl(settings.api.baseUrl)

// Axios interceptor
const errorInterceptor = (error: AxiosError) => {
  if (error.response && error.response.status === 401) {
    browserHistory.push('/login')
  }
  return Promise.reject(error)
}
axiosInstance.interceptors.response.use(undefined, errorInterceptor)

let locale = localStorage.getItem('lang') || 'fr'
let messages
if (locale === 'en')
  messages = english
if (locale === 'fr')
   messages = french

const intl = createIntl({locale,messages})

const store: CustomStore = createStore(
  reducers(browserHistory),
  composeEnhancers(applyMiddleware(routerMiddleware(browserHistory), thunk))
)

// Initialize notifications
brand.createNotifications(store.dispatch,
  {errorRetrievingBrands : intl.formatMessage({id: 'brandpage.panel.errorRetrievingBrands'}),
  errorRetrievingABrand: intl.formatMessage({id: 'brandpage.panel.errorRetrievingABrand'}),
  brandAdded: intl.formatMessage({id: 'brandpage.panel.brandAdded'}),
  errorCreatingBrand: intl.formatMessage({id: 'brandpage.panel.errorCreatingBrand'}),
  updatedBrand: intl.formatMessage({id: 'brandpage.panel.updatedBrand'}),
  errorRegisteringBrand: intl.formatMessage({id: 'brandpage.panel.errorRegisteringBrand'}),
  brandRemoved: intl.formatMessage({id :'brandpage.panel.brandRemoved'}),
  errorDeletingBrand: intl.formatMessage({id: 'brandpage.panel.errorDeletingBrand'})
  })
architect.createNotifications(store.dispatch,{errorRetrievingArchitects : intl.formatMessage({id: 'userspage.architect.errorRetrievingArchitects'}),
errorRetrievingAnArchitect: intl.formatMessage({id: 'userspage.architect.errorRetrievingAnArchitect'}),
updatedArchitect: intl.formatMessage({id: 'userspage.architect.updatedArchitect'}),
errorRegisteringArchitect: intl.formatMessage({id: 'userspage.architect.errorRegisteringArchitect'}),
architectRemoved: intl.formatMessage({id :'userspage.architect.architectRemoved'}),
errorDeletingArchitect: intl.formatMessage({id: 'userspage.architect.errorDeletingArchitect'})
})
brandUser.createNotifications(store.dispatch,{errorRetrievingBrandUsers : intl.formatMessage({id: 'userspage.branduser.errorRetrievingBrandUsers'}),
errorRetrievingABrandUser: intl.formatMessage({id: 'userspage.branduser.errorRetrievingABrandUser'}),
addBrandUser: intl.formatMessage({id: 'userspage.branduser.addBrandUser'}),
errorCreatingBrandUser: intl.formatMessage({id: 'userspage.branduser.errorCreatingBrandUser'}),
userUpdated: intl.formatMessage({id: 'userspage.branduser.userUpdated'}),
errorRegisteringBrandUser: intl.formatMessage({id: 'userspage.branduser.errorRegisteringBrandUser'}),
userDeleted: intl.formatMessage({id :'userspage.branduser.userDeleted'}),
errorDeletingBrandUser: intl.formatMessage({id: 'userspage.branduser.errorDeletingBrandUser'})
})
product.createNotifications(store.dispatch,{errorRetrievingProducts : intl.formatMessage({id: 'productpage.panel.errorRetrievingProducts'}),
errorRetrievingAProduct: intl.formatMessage({id: 'productpage.panel.errorRetrievingAProduct'}),
productAdded: intl.formatMessage({id: 'productpage.panel.productAdded'}),
errorCreatingProduct: intl.formatMessage({id: 'productpage.panel.errorCreatingProduct'}),
updatedProduct: intl.formatMessage({id: 'productpage.panel.updatedProduct'}),
errorRegisteringProduct: intl.formatMessage({id: 'productpage.panel.errorRegisteringProduct'}),
productRemoved: intl.formatMessage({id :'productpage.panel.productRemoved'}),
errorDeletingProduct: intl.formatMessage({id: 'productpage.panel.errorDeletingProduct'})
})
productProblem.createNotifications(store.dispatch, {errorRetrievingProblems : intl.formatMessage({id: 'productspage.problems.errorRetrievingProblems'}),
errorRetrievingAProblem: intl.formatMessage({id: 'productspage.problems.errorRetrievingAProblem'}),
addProblem: intl.formatMessage({id: 'productspage.problems.addProblem'}),
errorCreatingProblem: intl.formatMessage({id: 'productspage.problems.errorCreatingProblem'}),
updateProblem: intl.formatMessage({id: 'productspage.problems.updateProblem'}),
errorRegisteringProblem: intl.formatMessage({id: 'productspage.problems.errorRegisteringProblem'}),
removedProblem: intl.formatMessage({id :'productspage.problems.removedProblem'}),
errorDeletingProblem: intl.formatMessage({id: 'productspage.problems.errorDeletingProblem'})
})
project.createNotifications(store.dispatch,{errorRetrievingProjects : intl.formatMessage({id: 'project.errorRetrievingProjects'}),
errorRetrievingAProject: intl.formatMessage({id: 'project.errorRetrievingAProject'}),
addedProject: intl.formatMessage({id: 'project.addedProject'}),
errorCreatingProject: intl.formatMessage({id: 'project.errorCreatingProject'}),
updatedProject: intl.formatMessage({id: 'project.updatedProject'}),
errorRegisteringProject: intl.formatMessage({id: 'project.errorRegisteringProject'}),
removedProject: intl.formatMessage({id :'project.removedProject'}),
errorDeletingProject: intl.formatMessage({id: 'project.errorDeletingProject'})
})
objectProduct.createNotifications(store.dispatch, {errorRetrievingDimentions : intl.formatMessage({id: 'objectProduct.errorRetrievingDimentions'}),
errorRetrievingADimension: intl.formatMessage({id: 'objectProduct.errorRetrievingADimension'}),
errorCreatingDimension: intl.formatMessage({id: 'objectProduct.errorCreatingDimension'}),
errorRegisteringDimension: intl.formatMessage({id: 'objectProduct.errorRegisteringDimension'}),
errorDeletingDimension: intl.formatMessage({id: 'objectProduct.errorDeletingDimension'})
})
surfaceProduct.createNotifications(store.dispatch, {errorRetrievingDimentions : intl.formatMessage({id: 'objectProduct.errorRetrievingDimentions'}),
errorRetrievingADimension: intl.formatMessage({id: 'objectProduct.errorRetrievingADimension'}),
errorCreatingDimension: intl.formatMessage({id: 'objectProduct.errorCreatingDimension'}),
errorRegisteringDimension: intl.formatMessage({id: 'objectProduct.errorRegisteringDimension'}),
errorDeletingDimension: intl.formatMessage({id: 'objectProduct.errorDeletingDimension'})
})
designer.createNotifications(store.dispatch, {errorRetrievingDesigners : intl.formatMessage({id: 'designer.errorRetrievingDesigners'}),
errorRetrievingADesigner: intl.formatMessage({id: 'designer.errorRetrievingADesigner'}),
addedDesigner: intl.formatMessage({id: 'designer.addedDesigner'}),
errorCreatingDesigner: intl.formatMessage({id: 'designer.errorCreatingDesigner'}),
updatesDesigner: intl.formatMessage({id: 'designer.updatesDesigner'}),
errorRegisteringDesigner: intl.formatMessage({id: 'designer.errorRegisteringDesigner'}),
removedDesigner: intl.formatMessage({id :'designer.removedDesigner'}),
errorDeletingDesigner: intl.formatMessage({id: 'designer.errorDeletingDesigner'})
})

export default store
