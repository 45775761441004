import { ObjectProductDTO, ObjectProductVariationDTO } from '@addsome/dtos'
import {
  apiRequest,
  brand as brandActions,
  cameraTemplate as cameraTemplateActions,
  IPaginationRequest,
  packshot as packshotActions,
  product as productActions,
  productViewer as productViewerActions
} from '@addsome/redux-store'
import { Loading, Option, Select } from '@addsome/ui-kit'
import { SelectValue } from 'antd/lib/select'
import React, { useEffect, useState } from 'react'
import { WrappedComponentProps, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import addsomeLogo from '../../../assets/images/logos/addsome/black.png'
import { IRootState } from '../../../redux'
import { convertObjectProductForFront } from '../../../utils/convertObjectProduct'
import { SheetTypes } from '../ProductSheet/ProductSheet'
import styles from './Informations.module.scss'
import Collapsible from '../../Collapsible/Collapsible'
import Price from '../../Common/Price'

interface IOwnProps {
  productId: string
}

type IProps = ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps> &
  IOwnProps &
  WrappedComponentProps & {
    type: SheetTypes
  }

// TODO: Mettre tout ça dans Redux
function fetchObjectProductVariations(id: string) {
  return apiRequest<ObjectProductVariationDTO[]>('GET', `object-products/${id}/variations`)
}

const ProductInformation: React.FC<IProps> = ({
  productId,
  product,
  productLoading,
  brandLoading,
  brand,
  variationIndex,
  setVariationIndex,
  fetchProduct,
  fetchBrand,
  intl,
  type,
  fecthCameraTemplates
}) => {
  const [variations, setVariations] = useState<ObjectProductVariationDTO[] | null>(null)
  const [dimensions, setDimensions] = useState<ObjectProductDTO>()

  const sortVariations = (list: ObjectProductVariationDTO[] | null) => {
    list && list.sort((a, b) => {
      if (a.listIndex === null) {
        return 1;
      }

      if (b.listIndex === null) {
        return -1;
      }

      if (a.listIndex === b.listIndex) {
        return 0;
      }

      return a.listIndex < b.listIndex ? -1 : 1;
    });
    return (list);
  }

  useEffect(() => {
    fecthCameraTemplates()
    if (!product || product.id !== productId) {
      fetchProduct(productId)
    }
  }, [fecthCameraTemplates, fetchProduct, product, productId])

  useEffect(() => {
    if (product && product.objectProduct && product.objectProduct.id) {
      fetchObjectProductVariations(product.objectProduct.id).then(v => setVariations(sortVariations(v)))
      setDimensions(convertObjectProductForFront(product.objectProduct))

      if (product.brand && (!brand || brand.id !== product.brand.id)) {
        fetchBrand(product.brand.id)
      }
    }
  }, [product, fetchBrand, brand])

  if (!variations || !product || brandLoading || productLoading) {
    return <Loading />
  }

  const brandLogo = product.brand && product.brand.logo && product.brand.logo.url
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={`${styles.brandLogo} ${brandLogo ? '' : styles.defaultLogo}`}>
          <img src={brandLogo || addsomeLogo} />
        </div>
        <div className={styles.productTitle}>
          {product.name}
          {product.details ? ` / ${product.details}` : ''}
        </div>
      </div>

      <div className={styles.details}>
        {product.suggestedPrice && (
          <div className={styles.section}>
            <div className={styles.title}>
              {intl.formatMessage({ id: 'product.informations.price' })}
            </div>
            {product.suggestedPrice && (
              <div className={styles.price}>
                <Price amount={product.suggestedPrice} />
              </div>
            )}
          </div>
        )}
        {type === SheetTypes.SHEET && variations.length > 0 && (
          <div className={styles.section}>
            <div className={styles.title}>
              {intl.formatMessage({ id: 'product.informations.variations' })}
            </div>
            <Select
              defaultValue={variationIndex}
              onChange={(index: SelectValue) => setVariationIndex(index as number)}
              disabled={variations.length === 1}
            >
              {variations.map((variation, index) => (
                <Option key={variation.id} value={index}>
                  {variation.name}
                </Option>
              ))}
            </Select>
          </div>
        )}
        {(product.collection || product.designers.length > 0 || product.designedDate) && (
          <div className={styles.title}>
            {intl.formatMessage({ id: 'product.informations.details' })}
          </div>
        )}
        {product.collection && (
          <div className={styles.information}>
            <div className={styles.bold}>
              {intl.formatMessage({ id: 'product.informations.collection' })}
            </div>
            <div>{product.collection}</div>
          </div>
        )}
        {product.designers.length > 0 && (
          <div className={styles.information}>
            <div className={styles.bold}>
              {intl.formatMessage({ id: 'product.informations.designedBy' })}
            </div>
            <div>{product.designers.map(d => d.name).join(', ')}</div>
          </div>
        )}
        {product.designedDate && (
          <div className={styles.information}>
            <div className={styles.bold}>
              {intl.formatMessage({ id: 'product.informations.designedDate' })}
            </div>
            <div>{new Date(product.designedDate).getFullYear()}</div>
          </div>
        )}
        {!!dimensions &&
          (dimensions.width || dimensions.depth || dimensions.height || dimensions.weight) && (
            <div className={styles.information}>
              <div className={styles.bold}>
                {intl.formatMessage({ id: 'product.informations.dimensions' })}
              </div>
              {dimensions.width && (
                <div>
                  {intl.formatMessage({ id: 'product.informations.width' })} :{' '}
                  {`${dimensions.width} cm`}
                </div>
              )}
              {dimensions.depth && (
                <div>
                  {intl.formatMessage({ id: 'product.informations.depth' })} :{' '}
                  {`${dimensions.depth} cm`}
                </div>
              )}
              {dimensions.height && (
                <div>
                  {intl.formatMessage({ id: 'product.informations.height' })} :{' '}
                  {`${dimensions.height} cm`}
                </div>
              )}
              {dimensions.seatHeight && (
                <div>
                  {intl.formatMessage({ id: 'product.informations.seatheight' })} :{' '}
                  {`${dimensions.seatHeight} cm`}
                </div>
              )}
              {dimensions.diameter && (
                <div>
                  {intl.formatMessage({ id: 'product.informations.diameter' })} :{' '}
                  {`${dimensions.diameter} cm`}
                </div>
              )}
              {dimensions.weight && (
                <div>
                  {intl.formatMessage({ id: 'product.informations.weight' })} :{' '}
                  {`${dimensions.weight} kg`}
                </div>
              )}
            </div>
          )}
        {product.description && (
          <Collapsible title={intl.formatMessage({ id: 'product.informations.description' })}>
            <div className={styles.desc}>{product.description}</div>
          </Collapsible>
        )}
      </div>
    </div>
  )
}

const mapStateToProps = (state: IRootState) => ({
  brand: state.brandState.value,
  brandLoading: state.brandState.loading,
  product: state.productState.value,
  productLoading: state.productState.loading,
  variationIndex: state.productViewerState.variationIndex
})

const mapDispatchToProps = (dispatch: ThunkDispatch<IRootState, {}, AnyAction>) => ({
  fetchProduct: (productId: string) => dispatch(productActions.thunks.fetchValue(productId)),
  fetchBrand: (brandId: string) => dispatch(brandActions.thunks.fetchValue(brandId)),
  setVariationIndex: (variationIndex: number) =>
    dispatch(productViewerActions.setVariationIndex(variationIndex)),
  setProductSettingsVariationIds: (productId: string, variationIds: string[]) =>
    dispatch(packshotActions.setProductSettingsVariationIds(productId, variationIds)),
  fecthCameraTemplates: (request?: IPaginationRequest) =>
    dispatch(cameraTemplateActions.thunks.fetchValues(request))
})

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ProductInformation))
