import React from 'react'
import styles from './PlayerSubBar.module.scss'

interface IProps {
  isOpen: boolean
  className?: string
}

const PlayerSubBar: React.FC<IProps> = ({ isOpen, children, className = '' }) => (
  <div className={`${styles.container} ${className} ${isOpen ? styles.open : ''}`}>
    <div className={styles.barContainer}>{children}</div>
  </div>
)

export default PlayerSubBar
