import Tooltip from 'antd/lib/tooltip'
import React, { FC, ReactElement } from 'react'
import styles from './IconButton.module.scss'

interface IProps {
  title: string
  children: ReactElement<SVGSVGElement>
  onClick: () => void
  displayTooltip?: boolean
  className?: string
}

const IconButton: FC<IProps> = ({ children, title, onClick, displayTooltip, className = '' }) => (
  <button
    className={`${styles.container} ${className}`}
    title={title}
    aria-label={title}
    onClick={onClick}
  >
    {displayTooltip ? <Tooltip title={title}>{children}</Tooltip> : children}
  </button>
)

export default IconButton
