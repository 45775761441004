import { Button, InputField, Switch } from '@addsome/ui-kit'
import Modal from 'antd/lib/modal'
import React, { ChangeEvent, FC, ReactElement, useState } from 'react'
import { ApiMethod } from '../../enums'
import styles from './PlayerPopupMeasure.module.scss'

const inchesToMeters = 0.0254
const squareFeetsToSquareMeters = 0.092903
const centimetersToMeters = 0.01

interface IProps {
  onValidate: (method: ApiMethod, value?: string) => void
  method: ApiMethod
  text: string
  icon: ReactElement<SVGSVGElement>
  isSquareUnits?: boolean
  visible: boolean
  closable?: boolean
  maskClosable?: boolean
  onCancel?: () => void
}

const PlayerPopupMeasure: FC<IProps> = ({
  onValidate,
  method,
  text,
  icon,
  visible,
  isSquareUnits,
  closable,
  maskClosable,
  onCancel
}) => {
  const [value, setValue] = useState('')
  const [isValid, setIsValid] = useState(false)
  const [isEnglishSystem, setIsEnglishSystem] = useState(false)

  const handleValidate = () => {
    const numberValue = parseInt(value, 10)
    const convertedValue = isSquareUnits
      ? numberValue * (isEnglishSystem ? squareFeetsToSquareMeters : 1)
      : numberValue * (isEnglishSystem ? inchesToMeters : centimetersToMeters)
    onValidate(method, convertedValue.toString())
    setValue('')
    setIsValid(false)
  }

  return (
    <Modal
      visible={visible}
      mask={false}
      footer={false}
      width={360}
      centered
      closable={closable || false}
      className={styles.container}
      maskClosable={maskClosable}
      onCancel={() => {
        if (onCancel) {
          setValue('')
          setIsValid(false)
          onCancel()
        }
      }}
    >
      {icon}
      <div>
        <span className={styles.title}>{text}</span>
      </div>
      <InputField
        name="test"
        type="text"
        pattern="[0-9]*"
        value={value}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          setValue(e.target.value)
          setIsValid(e.target.validity.valid && e.target.value !== '')
        }}
        onPressEnter={() => isValid && handleValidate()}
      />

      <div className={styles.selectUnit}>
        <span className={styles.unit}>{isSquareUnits ? 'M2' : 'CM'}</span>
        <Switch checked={isEnglishSystem} onChange={setIsEnglishSystem} />
        <span className={styles.unit}>{isSquareUnits ? 'F2' : 'IN'}</span>
      </div>
      <Button type="primary" disabled={!isValid} onClick={handleValidate} block>
        Valider
      </Button>
    </Modal>
  )
}

export default PlayerPopupMeasure
