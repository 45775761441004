import { InputField, TextArea } from '@addsome/ui-kit'
import { Form } from 'antd'
import { Formik, FormikErrors } from 'formik'
import React, { FC } from 'react'
import { WrappedComponentProps, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { IRootState } from '../../../redux'
import BlueButton from '../../Common/Elements/BlueButton'
import styles from './NewAmbianceForm.module.scss'

interface IInitialValues {
  ambianceName: string
  description: string
}

const initialValues: IInitialValues = {
  ambianceName: '',
  description: ''
}

type IProps = ReturnType<typeof mapStateToProps> &
  WrappedComponentProps & {
    projectId: string
    onClickValidate: (ambianceName: string, description: string) => void
  }

const NewAmbianceForm: FC<IProps> = ({ intl, loading, projectId, onClickValidate }) => {
  return (
    <div className={styles.container}>
      <Formik
        initialValues={initialValues}
        validate={values => {
          const errors: FormikErrors<typeof values> = {}
          if (!values.ambianceName) {
            errors.ambianceName = `Nom de l'ambiance requis`
          }
          return errors
        }}
        onSubmit={() => {}}
      >
        {formik => (
          <Form onSubmit={formik.handleSubmit} className={styles.form}>
            <Form.Item label={intl.formatMessage({ id: 'newAmbiance.ambianceName' })}>
              <InputField
                name="ambianceName"
                defaultValue={initialValues.ambianceName}
                error={formik.touched.ambianceName ? formik.errors.ambianceName : undefined}
                required
                onChange={formik.handleChange}
                type="text"
                allowClear={true}
                onBlur={formik.handleBlur}
                suppressContentEditableWarning
              />
            </Form.Item>
            <Form.Item label={intl.formatMessage({ id: 'newAmbiance.description' })}>
              <InputField
                error={formik.touched.description ? formik.errors.description : undefined}
              >
                <TextArea
                  name="description"
                  defaultValue={initialValues.description}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </InputField>
            </Form.Item>
            <BlueButton
              labelIntlId={'misc.continue'}
              loading={loading}
              disabled={!formik.isValid}
              onClick={() => onClickValidate(formik.values.ambianceName, formik.values.description)}
            />
            <div className={styles.cancelContainer}>
              <a className={styles.cancel} href={`/projects/${projectId}/products`}>
                {intl.formatMessage({ id: 'misc.cancel' })}
              </a>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}

const mapStateToProps = (state: IRootState) => ({
  loading: state.sceneState.loading
})

export default connect(mapStateToProps)(injectIntl(NewAmbianceForm))
