import { MediaDTO } from '@addsome/dtos'
import React from 'react'
import img1 from '../../../assets/images/icons/placeholder/img1.png'
import { PRODUCT_VIEWER_CONTAINER_ID } from '../../Player/ProductViewer/ProductViewerContainer'
import styles from './Viewer.module.scss'

interface IProps {
  picture?: MediaDTO
}

const Viewer: React.FC<IProps> = ({ picture }) => {
  return (
    <div className={styles.container}>
      <div className={styles.preview}>
        <div
          id={PRODUCT_VIEWER_CONTAINER_ID}
          className={`${styles.productViewer} ${styles.visible}`}
        />
        {<img src={picture && picture.url ? picture.url : img1} />}
      </div>
    </div>
  )
}

export default Viewer
