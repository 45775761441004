import { Icon } from 'antd'
import React, { ReactNode, useState } from 'react'
import styles from './Collapsible.module.scss'

interface IProps {
  title?: string
  children: ReactNode
  className?: string
}

const Collapsible: React.FC<IProps> = ({ title = '', children, className = '' }) => {
  const [collapsed, setCollapsed] = useState<boolean>(true)

  return (
    <div className={`${styles.container} ${className}`}>
      <div className={styles.header}>
        <div className={styles.title}>{title}</div>
        <button onClick={() => setCollapsed(!collapsed)}>
          <Icon type={collapsed ? 'plus' : 'minus'} className={styles.icon} />
        </button>
      </div>
      {!collapsed ? <div className={styles.description}>{children}</div> : ''}
    </div>
  )
}

export default Collapsible
