import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import styles from './ForgottenPassword.module.scss'
import RetrievePasswordForm from './RetrievePasswordForm/RetrievePasswordForm'
import { useIntl } from 'react-intl'
import ConnectionLayout from '../../components/ConnectionLayout/ConnectionLayout'

const ForgottenPassword: React.FC = () => {
  const intl = useIntl()

  return (
    <ConnectionLayout
      title={intl.formatMessage({ id: 'forgottenpassword.title' })}
      subtitle={intl.formatMessage({ id: 'forgottenpassword.presentation' })}
    >
      <div className={styles.forgotten}>
        <RetrievePasswordForm />
        <Link to="/login">
          <FormattedMessage id="forgottenpassword.back" />
        </Link>
      </div>
    </ConnectionLayout>
  )
}

export default ForgottenPassword
