import {
  ProductLightExtendedDTO,
  ProjectAlbumType,
  ProjectPackshotRequestStatus,
  ProjectUpdateDTO,
  RenderStatus
} from '@addsome/dtos'
import {
  axiosInstance,
  cameraTemplate as cameraTemplateActions,
  IPaginationRequest,
  objectProductVariations as objectProductVariationsActions,
  packshot as packshotActions,
  product as productActions,
  project as projectActions,
  render as renderActions,
  scene as sceneActions
} from '@addsome/redux-store'
import { deleteRenderFromProjectScene } from '@addsome/redux-store/dist/store/render'
import {
  Button,
  Carousel,
  ContentContainer,
  ICardData,
  InfiniteGridWithSearch,
  InvisibleButton,
  MultipleSelect,
  Option,
  SelectedNumber,
  Size,
  Theme
} from '@addsome/ui-kit'
import { ICarouselRef } from '@addsome/ui-kit/dist/components/Carousel/Carousel'
import { Icon } from 'antd'
import { push } from 'connected-react-router'
import React, { createRef, FC, useCallback, useEffect, useMemo, useState } from 'react'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router-dom'
import ScrollLock from 'react-scrolllock'
import { AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import close from '../../../assets/images/icons/close.png'
import { RectPlusIcon, TrashIcon } from '../../../assets/images/icons/pack'
import Traitement from '../../../assets/images/traitement.png'
import Layout from '../../../components/Common/Layout'
import { IRootState } from '../../../redux'
import settings from '../../../settings'
import { getCloudImageUrl, getResizedPreview } from '../../../utils'
import { convertProductForSheet } from '../../../utils/convertProduct'
import styles from './Visual.module.scss'

interface IMatchParams {
  projectId: string
  visualId: string
  type: string
}

export const downloadZipURL = `${settings.api.baseUrl}/renders/zip`

interface IImages {
  url: string
  displayUrl: string
  thumbnailUrl: string
  title?: string
  subtitle?: string
  id?: string
  entityId?: string
}

type IProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  WrappedComponentProps &
  RouteComponentProps<IMatchParams>

const Visual: FC<IProps> = ({
  fetchPackshots,
  fetchProjectSceneRenders,
  packshots,
  renders,
  totalPackshots,
  fetchProject,
  project,
  fetchProduct,
  product,
  intl,
  fetchScene,
  scene,
  setSelectedProducts,
  pushRouter,
  updateProject,
  match: {
    params: { projectId, visualId, type }
  },
  deleteSceneRender,
  deletePackshot,
  fecthCameraTemplates,
  fetchObjectProductVariations,
  cameraTemplates,
  objectProductVariations
}) => {
  const [loaded, setLoaded] = useState(false)
  const [displayCarousel, setDisplayCarousel] = useState(false)
  const [carouselImages, setCarouselImages] = useState<IImages[]>([])

  const [selectedVisual, setSelectedVisual] = useState(0)

  const carousel = createRef<ICarouselRef>()

  // Use any cause ICardData don't extend anything, so render property is unknow
  const [selectedItems, setSelectedItems] = useState<any[]>([])
  const [selectedObjectProductVariationIds, setSelectedObjectProductVariationIds] = useState<
    string[]
  >([])
  const [selectedCameraNames, setSelectedCameraNames] = useState<string[]>([])

  useEffect(() => {
    fecthCameraTemplates()
    if (product && product.objectProduct && product.objectProduct.id) {
      fetchObjectProductVariations(product.objectProduct.id)
    }
  }, [fecthCameraTemplates, fetchObjectProductVariations, product])

  useEffect(() => {
    if (type === ProjectAlbumType.Product) {
      fetchPackshots(projectId, intl.formatMessage({id:'promotedBrands.errorRetrievingPackshots'}) ,{ filters: { productIds: [visualId] } }).then(() => setLoaded(true))
    } else {
      fetchProjectSceneRenders(visualId,{},intl.formatMessage({id: 'renders.errorRetrievingRenderings'})).then(() => setLoaded(true))
    }
  }, [fetchPackshots, fetchProjectSceneRenders, projectId, type, visualId])

  useEffect(() => {
    if (type === ProjectAlbumType.Product) {
      if (!product || product.id !== visualId) {
        fetchProduct(visualId)
      }
    } else {
      if (!scene || scene.id !== visualId) {
        fetchScene(visualId, intl.formatMessage({id: 'scenes.errorRetrievingTheScene'}))
      }
    }
  }, [fetchProduct, visualId, fetchScene, type, product, scene])

  useEffect(() => {
    if (!project || project.id !== projectId) {
      fetchProject(projectId)
    }
  })

  useEffect(() => {
    if (type === ProjectAlbumType.Product) {
      fetchPackshots(projectId, 
        intl.formatMessage({id:'promotedBrands.errorRetrievingPackshots'}),
        {
        filters: {
          productIds: [visualId],
          objectProductVariationIds: selectedObjectProductVariationIds,
          cameraNames: selectedCameraNames
        }
      }).then(() => setLoaded(true))
    } else {
      fetchProjectSceneRenders(visualId, {}, intl.formatMessage({id: 'renders.errorRetrievingRenderings'})).then(() => setLoaded(true))
    }
  }, [projectId, type, visualId, selectedObjectProductVariationIds, selectedCameraNames])

  useEffect(() => {
    if (type === ProjectAlbumType.Product) {
      const imgs = packshots
        .filter(packshot => packshot.request.status === ProjectPackshotRequestStatus.Done)
        .map(packshot => ({
          entityId: packshot.id,
          id:
            (packshot.request.render &&
              packshot.request.render.media &&
              packshot.request.render.media.id) ||
            undefined,
          url:
            packshot.request.render && packshot.request.render.media
              ? packshot.request.render.media.url
              : '',
          displayUrl:
            packshot.request.render &&
            packshot.request.render.media &&
            packshot.request.render.media.url
              ? getResizedPreview(
                  packshot.request.render.renderSettings.width,
                  packshot.request.render.renderSettings.height,
                  packshot.request.render.media.url
                )
              : '',
          thumbnailUrl:
            packshot.request.render &&
            packshot.request.render.media &&
            packshot.request.render.media.url
              ? getCloudImageUrl(packshot.request.render.media.url, 150)
              : '',
          title: packshot.product.name,
          subtitle: packshot.objectProductVariation.name
        })) as IImages[]
      setCarouselImages(imgs)
    } else {
      const imgs = renders
        .filter(render => render.render.status === RenderStatus.Done)
        .map(render => ({
          entityId: render.id,
          title: scene ? scene.name : undefined,
          id: (render.render.media && render.render.media.id) || undefined,
          url: render.render.media ? render.render.media.url : '',
          displayUrl:
            render.render.media && render.render.media.url
              ? getResizedPreview(
                  render.render.renderSettings.width,
                  render.render.renderSettings.height,
                  render.render.media.url
                )
              : '',
          thumbnailUrl:
            render.render.media && render.render.media.url
              ? getCloudImageUrl(render.render.media.url, 150)
              : ''
        })) as IImages[]
      setCarouselImages(imgs)
    }
  }, [packshots, renders, type])

  // Display clicked image when opening modal
  useEffect(() => {
    if (carousel && carousel.current && selectedVisual > -1) {
      carousel.current.goTo(selectedVisual)
    }
  }, [carousel, selectedVisual])

  const visualsForGrid: ICardData[] = useMemo(() => {
    const resizePicture = (url: string) => getCloudImageUrl(url, 400)

    if (type === ProjectAlbumType.Product) {
      return packshots.map(packshot => {
        const {
          product: { name },
          request: { render },
          objectProductVariation: { name: desc },
          ...rest
        } = packshot
        let image = Traitement

        if (render && render.media && render.media.url) {
          image = resizePicture(render.media.url)
        }

        return {
          ...rest,
          title: name,
          render,
          image,
          desc: `${desc} - ${packshot.cameraName}${
            render && render.renderSettings.width && render.renderSettings.height
              ? ` - ${render.renderSettings.width}x${render.renderSettings.height}px`
              : ''
          }`
        }
      })
    } else {
      return renders.map(sceneRender => {
        const { render } = sceneRender
        let image = Traitement

        if (render && render.media && render.media.url) {
          image = resizePicture(render.media.url)
        }

        return {
          id: sceneRender.id,
          title: scene ? scene.name : '',
          image,
          render,
          desc: `${
            render && render.renderSettings.width && render.renderSettings.height
              ? `${render.renderSettings.width}x${render.renderSettings.height}px`
              : ''
          }`
        }
      })
    }
  }, [packshots, renders, type])

  const fetchItems = useCallback(
    (
      filters: { [key: string]: string[] },
      renew: boolean,
      skip: number,
      order?: { [key: string]: 'ASC' | 'DESC' }
    ) => {
      if (type === ProjectAlbumType.Product) {
        return fetchPackshots(
          projectId,
          intl.formatMessage({id:'promotedBrands.errorRetrievingPackshots'}),
          {
            skip,
            filters: {
              ...filters,
              productIds: [visualId]
            },
            order
          },
          !renew
        )
      } else {
        return fetchProjectSceneRenders(visualId, { skip, filters, order }, intl.formatMessage({id: 'renders.errorRetrievingRenderings'}), !renew)
      }
    },
    [type, fetchPackshots, projectId, visualId, fetchProjectSceneRenders]
  )

  // Open carousel modal
  const goToCarousel = useCallback(
    (card: ICardData) => {
      if (carouselImages.length > 0) {
        setDisplayCarousel(true)
        setSelectedVisual(visualsForGrid.indexOf(card))
      }
    },
    [carouselImages.length, visualsForGrid]
  )

  const goToNewPackshot = useCallback(() => {
    if (!product) return null
    setSelectedProducts([convertProductForSheet(product)])
    pushRouter(`/projects/${projectId}/visuals/new/batch`)
  }, [product, setSelectedProducts, pushRouter, projectId])

  const goToNewAmbiance = useCallback(() => {
    pushRouter(`/projects/${projectId}/ambiance/new`)
  }, [pushRouter, projectId])

  const handleClickCover = useCallback(
    (image: IImages) => {
      updateProject(projectId, { pictureId: image.id })
    },
    [projectId]
  )

  const isUsedPicture = useCallback(
    (image: IImages) => {
      return project && project.picture ? project.picture.id === image.id : false
    },
    [project]
  )

  const renderCarouselIcons = useCallback(
    (image: IImages) => {
      return (
        <div className={styles.carouselIcons}>
          <button
            className={styles.iconButton}
            title="Supprimer le visuel"
            onClick={async () => {
              if (type === ProjectAlbumType.Product) {
                if (project && image && image.entityId) {
                  await deletePackshot(project.id, image.entityId, 
                    intl.formatMessage({id:'packshots.errorDeletingPackshots'}),
                    intl.formatMessage({id: 'promotedBrands.errorRetrievingPackshots'}),
                     {
                    filters: {
                      productIds: [visualId],
                      objectProductVariationIds: selectedObjectProductVariationIds,
                      cameraNames: selectedCameraNames
                    }
                  })
                }
              } else {
                if (image && image.entityId) {
                  await deleteSceneRender(visualId, image.entityId, intl.formatMessage({id: 'renders.renderRemoved'}),
                  intl.formatMessage({id: 'redners.errorDeletingRender'}), intl.formatMessage({id: 'renders.errorRetrievingRenderings'}))
                }
              }
            }}
          >
            <Icon className={styles.icon} component={TrashIcon} />
          </button>
          <button
            className={`${styles.iconButton} ${
              image && image.id && isUsedPicture(image) ? styles.active : ''
            }`}
            onClick={() => handleClickCover(image)}
            title="Définir en tant qu'image de projet"
          >
            <Icon className={styles.icon} component={RectPlusIcon} />
          </button>
          <a
            className={`${styles.link} ${styles.iconButton}`}
            href={image.url}
            download
            target="_blank"
          >
            <Icon className={styles.icon} type="download" />
          </a>
        </div>
      )
    },
    [handleClickCover, isUsedPicture]
  )

  const renderCardIcons = useCallback((cardData: ICardData) => {
    return (
      <div className={styles.cardIcons}>
        <a
          className={styles.link}
          href={cardData.image ? cardData.image : undefined}
          download
          target="_blank"
        >
          <Icon className={styles.icon} type="download" theme="filled" />
        </a>
      </div>
    )
  }, [])

  const onDownloadClick = useCallback(async () => {
    const url = `${downloadZipURL}?ids=${selectedItems
      .map(s => s && s.render && s.render.id)
      .join(',')}`

    const resp = await axiosInstance.get(url, { responseType: 'blob' })

    const element = document.createElement('a')
    element.setAttribute('href', window.URL.createObjectURL(new Blob([resp.data])))
    element.setAttribute('download', product ? `${product.name}.zip` : 'render.zip')
    element.style.display = 'none'
    document.body.appendChild(element)
    element.click()
    document.body.removeChild(element)
  }, [selectedItems])

  const onDeleteVisuals = useCallback(async () => {
    if (type === ProjectAlbumType.Product) {
      if (project) {
        await Promise.all(
          selectedItems.map(item =>
            deletePackshot(project.id, item.id,
              intl.formatMessage({id:'packshots.errorDeletingPackshots'}),
              intl.formatMessage({id: 'promotedBrands.errorRetrievingPackshots'}),
              {
              filters: {
                productIds: [visualId],
                objectProductVariationIds: selectedObjectProductVariationIds,
                cameraNames: selectedCameraNames
              }
            })
          )
        )
      }
    } else {
      await Promise.all(selectedItems.map(item => deleteSceneRender(visualId, item.id, intl.formatMessage({id: 'renders.renderRemoved'}),
      intl.formatMessage({id: 'redners.errorDeletingRender'}), intl.formatMessage({id: 'renders.errorRetrievingRenderings'}))))
    }
    setSelectedItems([])
  }, [selectedItems])

  const projectName = project ? project.name : ''
  const pageName =
    type === ProjectAlbumType.Product ? (product ? product.name : '') : scene ? scene.name : ''

  const variationsItems = useMemo(
    () => objectProductVariations.map(v => ({ id: v.id, name: v.name })),
    [objectProductVariations]
  )

  return (
    <Layout
      className={styles.container}
      title={pageName}
      breadcrumbRoutes={[
        {
          breadcrumbName: intl.formatMessage({ id: 'breadcrumb.home' }),
          path: '/'
        },
        {
          breadcrumbName: projectName,
          path: `/projects/${projectId}/products`
        },
        {
          breadcrumbName: intl.formatMessage({ id: 'project.visual' }),
          path: `/projects/${projectId}/visuals`
        },
        {
          breadcrumbName: pageName,
          path: ``
        }
      ]}
      addButtonTitle={intl.formatMessage({
        id: type === ProjectAlbumType.Product ? 'visual.newPackshot' : 'visual.newAmbiance'
      })}
      onAddButtonClick={type === ProjectAlbumType.Product ? goToNewPackshot : goToNewAmbiance}
    >
      <ContentContainer>
        <InfiniteGridWithSearch
          customSearchbar={
            type === ProjectAlbumType.Product ? (
              <div className={styles.packshotFilters}>
                <MultipleSelect
                  placeholder="Filtrer par variation"
                  showArrow={true}
                  onChange={value => setSelectedObjectProductVariationIds(value as string[])}
                >
                  {variationsItems.map(variation => (
                    <Option key={variation.id} value={variation.id}>
                      {variation.name}
                    </Option>
                  ))}
                </MultipleSelect>
                <MultipleSelect
                  placeholder="Filtrer par caméra"
                  showArrow={true}
                  onChange={value => setSelectedCameraNames(value as string[])}
                >
                  {cameraTemplates.map(template => (
                    <Option key={template.id} value={template.name}>
                      {template.name}
                    </Option>
                  ))}
                  <Option value="Custom">Custom</Option>
                </MultipleSelect>
              </div>
            ) : (
              undefined
            )
          }
          orderItems={[
            {
              id: 1,
              label: '+ Récents',
              orderKey: 'createdAt',
              order: 'DESC'
            },
            {
              id: 2,
              label: '- Récents',
              orderKey: 'createdAt',
              order: 'ASC'
            }
          ]}
          headerClassName={styles.gridHeader}
          title=""
          fetchItems={fetchItems}
          totalItems={totalPackshots}
          doInitialFetch={false}
          loading={!loaded}
          cardsData={visualsForGrid}
          onCardSelect={(selectedCards: any) => {
            setSelectedItems(selectedCards)
          }}
          hoverText={intl.formatMessage({ id: 'global.enlarge' })}
          inner
          selectable
          displaySelectedNumber={false}
          onCardClick={goToCarousel}
          renderTopIcons={renderCardIcons}
        />

        {carouselImages.length > 0 && (
          <div className={`${styles.carouselWrapper} ${displayCarousel ? styles.open : ''}`}>
            <div className={styles.carouselActions}>
              <InvisibleButton
                title="close"
                onClick={() => {
                  setDisplayCarousel(false)
                }}
              >
                <img src={close} className={styles.close} />
              </InvisibleButton>
            </div>
            <Carousel
              className={styles.carousel}
              imagesUrl={carouselImages}
              renderIcons={renderCarouselIcons}
              ref={carousel}
            />
          </div>
        )}
      </ContentContainer>
      {selectedItems.length > 0 && !displayCarousel && (
        <SelectedNumber
          selectedNumber={selectedItems.length}
          selectedNumberText={intl.formatMessage({ id: 'visual.selectedNumber' })}
          selectedNumberPluralText={intl.formatMessage({ id: 'visual.selectedNumberPlural' })}
          firstButton={
            <Button uppercase size={Size.LARGE} theme={Theme.DEFAULT} onClick={onDeleteVisuals}>
              {intl.formatMessage({ id: 'global.delete' })}
            </Button>
          }
          secondButton={
            <Button uppercase size={Size.LARGE} theme={Theme.BLACK} onClick={onDownloadClick}>
              {intl.formatMessage({ id: 'global.download' })}
            </Button>
          }
          fixedBottom
        />
      )}
      <ScrollLock isActive={displayCarousel} />
    </Layout>
  )
}

const mapStateToProps = ({
  packshotState: { packshots, total: totalPackshots },
  projectState: { value: project },
  productState: { value: product },
  renderState: { renders },
  sceneState: { scene },
  objectProductVariationsState: { objectProductVariations },
  cameraTemplateState: { values: cameraTemplates }
}: IRootState) => ({
  packshots,
  totalPackshots,
  project,
  product,
  renders,
  scene,
  objectProductVariations,
  cameraTemplates
})

const mapDispatchToProps = (dispatch: ThunkDispatch<IRootState, {}, AnyAction>) => ({
  fetchPackshots: (projectId: string, errorRetrievingPackshots?: string, request?: IPaginationRequest, concatenate?: boolean) =>
    dispatch(packshotActions.fetchPackshots(projectId, errorRetrievingPackshots, request, concatenate)),
  fetchProjectSceneRenders: (
    projectSceneId: string,
    request?: IPaginationRequest,
    errorRetrievingRenderings?: string,
    concatenate?: boolean
  ) => dispatch(renderActions.fetchProjectSceneRenders(projectSceneId, request, errorRetrievingRenderings, concatenate)),
  deleteProjectSceneRender: (projectId: string, renderId: string, renderRemoved?:string,errorDeletingRender?: string,
    errorRetrievingRenderings?: string, request?: IPaginationRequest) =>
    dispatch(renderActions.deleteRenderFromProject(projectId, renderId, renderRemoved, errorDeletingRender, errorRetrievingRenderings, request)),
  fetchProject: (id: string) => dispatch(projectActions.thunks.fetchValue(id)),
  fetchProduct: (productId: string) => dispatch(productActions.thunks.fetchValue(productId)),
  fetchScene: (sceneId: string, errorRetrievingTheScene?: string) => dispatch(sceneActions.fetchScene(sceneId, errorRetrievingTheScene)),
  setSelectedProducts: (selectedProducts: ProductLightExtendedDTO[]) =>
    dispatch(packshotActions.setSelectedProducts(selectedProducts)),

  updateProject: (id: string, payload: ProjectUpdateDTO) =>
    dispatch(projectActions.thunks.patchValue(id, payload)),
  pushRouter: (location: string) => dispatch(push(location)),
  deleteSceneRender: (sceneId: string, renderId: string,renderRemoved?:string, errorDeletingRender?: string, errorRetrievingRenderings?:string, request?: IPaginationRequest) =>
    dispatch(deleteRenderFromProjectScene(sceneId, renderId, renderRemoved, errorDeletingRender, errorRetrievingRenderings, request)),
  deletePackshot: (projectId: string, packshotId: string, errorDeletingPackshots?: string, errorRetrievingPackshots?: string, request?: IPaginationRequest) =>
    dispatch(packshotActions.deletePackshot(projectId, packshotId, errorDeletingPackshots, errorRetrievingPackshots, request)),
  fecthCameraTemplates: (request?: IPaginationRequest) =>
    dispatch(cameraTemplateActions.thunks.fetchValues(request)),
  fetchObjectProductVariations: (objectProductId: string) =>
    dispatch(objectProductVariationsActions.fetchObjectProductVariations(objectProductId))
})

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Visual))
